export const versionedLessonDictionary = {
    2: {
        en: {
            sk: {
                1: {
                    1: 'Pozdravy a zbohom',
                    2: 'Čísla',
                    3: 'Osoby a zámeno',
                    4: 'Dni v týždni',
                    5: 'Farby',
                    6: 'Jedlo',
                    7: 'Oblečenie',
                    8: 'Čas',
                    9: 'Časti tela',
                    10: 'Zvieratá',
                    11: 'Čísla',
                    12: 'Každodenný život',
                    13: 'Príslovky',
                    14: 'Slovesá',
                    15: 'Negatívne emócie a pocity',
                    16: 'Zelenina a ovocie',
                    17: 'Počasie a astronómia',
                    18: 'Negatívne vlastnosti',
                    19: 'Nápoje',
                    20: 'Technológia a veda',
                    21: 'Svet televízie',
                    22: 'Telefónne rozhovory',
                    23: 'Tvary, obrazce, veľkosti',
                    24: 'Svet internetu',
                    25: 'Mesto',
                    26: 'Smerovanie a umiestnenie',
                    27: 'Emócie a pocity',
                    28: 'V reštaurácii',
                    29: 'Podstatné mená',
                    30: 'Pozitívne vlastnosti',
                    31: 'Dom a nábytok',
                    32: 'Mesiace',
                    33: 'Doplnky do domácnosti',
                    34: 'Práca a povolania',
                    35: 'Domáce práce',
                    36: 'Pozitívne emócie a pocity',
                    37: 'Šport a voľný čas',
                    38: 'Športy',
                    39: 'Doprava',
                    40: 'Podnikanie',
                    41: 'Rozhovor s klientom',
                    42: 'Rodina',
                    43: 'Čísla',
                },
                2: {
                    1: 'Pozdravy a zbohom',
                    2: 'Čísla',
                    3: 'Osoby a zámeno',
                    4: 'Jedlo',
                    5: 'Odev',
                    6: 'Čas',
                    7: 'Časti tela',
                    8: 'Zvieratá',
                    9: 'Rodičia a dieťa',
                    10: 'Rodina',
                    11: 'Zákon a bezprávie',
                    12: 'Dovolenka',
                    13: 'Príroda',
                    14: 'Každodenný život',
                    15: 'Škola',
                    16: 'Vzdelanie',
                    17: 'Príslovky',
                    18: 'Geografia',
                    19: 'Dezerty',
                    20: 'Zdravie a medicína',
                    21: 'Slovesá',
                    22: 'Negatívne vlastnosti',
                    23: 'Technológia a veda',
                    24: 'Negatívne emócie a pocity',
                    25: 'Zelenina a ovocie',
                    26: 'Počasie a astronomia',
                    27: 'Tvary, figúry a veľkosti',
                    28: 'Telefonická konverzácia',
                    29: 'Svet televízie',
                    30: 'Smerovanie a umiestnenie',
                    31: 'Emócie a pocity',
                    32: 'Formálne a zdvorilé výrazy',
                    33: 'Dôležité vedieť',
                    34: 'Nástroje a stroje',
                    35: 'Politika',
                    36: 'Domáce práce',
                    37: 'Domáce príslušenstvo',
                    38: 'Jazyk sociálnych médií',
                    39: 'Pozitívne vlastnosti',
                    40: 'Podstatné mená',
                    41: 'Práca a zamestnanie',
                    42: 'Šport a rekreácia',
                    43: 'Športy',
                    44: 'Zamestnania',
                    45: 'Doprava',
                    46: 'Kultúra a umenie',
                    47: 'Obchod',
                    48: 'Konverzácia s zákazníkom',
                },
                3: {
                    1: 'Osoby a zámeno',
                    2: 'Jedlo',
                    3: 'Časti tela',
                    4: 'E-mail',
                    5: 'Rodina',
                    6: 'Zákon a nezákonnosť',
                    7: 'Vzdelávanie',
                    8: 'Príslovky',
                    9: 'Textový jazyk',
                    10: 'Geografia',
                    11: 'Negatívne vlastnosti',
                    12: 'Zdravie a medicína',
                    13: 'Slovesá',
                    14: 'Technológia a veda',
                    15: 'Svet internetu',
                    16: 'Tvary, figúry, veľkosti',
                    17: 'Smerovanie a poloha',
                    18: 'Zručnosti',
                    19: 'Slang',
                    20: 'Dôležité slová',
                    21: 'Pozitívne vlastnosti',
                    22: 'Dom a nábytok',
                    23: 'Formálne a zdvorilostné výrazy',
                    24: 'Príslušenstvo domácnosti',
                    25: 'Domáce práce',
                    26: 'Nástroje a stroje',
                    27: 'Politika',
                    28: 'Práca a povolania',
                    29: 'Podstatné mená',
                    30: 'Šport a voľný čas',
                    31: 'Športy',
                    32: 'Povolania',
                    33: 'Začína sa na "A"',
                    34: 'Kultúra a umenie',
                    35: 'Obchod',
                    36: 'Udalosti',
                },
            },
            cs: {
                1: {
                    1: 'Pozdravy a loučení',
                    2: 'Čísla',
                    3: 'Lidé a zájmena',
                    4: 'Dny v týdnu',
                    5: 'Barvy',
                    6: 'Jídlo',
                    7: 'Oblečení',
                    8: 'Čas',
                    9: 'Části těla',
                    10: 'Zvířata',
                    11: 'Čísla',
                    12: 'Každodenní život',
                    13: 'Příslovce',
                    14: 'Slovesa',
                    15: 'Negativní emoce a pocity',
                    16: 'Zelenina a ovoce',
                    17: 'Počasí a astronomie',
                    18: 'Negativní vlastnosti',
                    19: 'Nápoje',
                    20: 'Technologie a věda',
                    21: 'Svět televize',
                    22: 'Telefonní rozhovory',
                    23: 'Tvary, vzory, velikosti',
                    24: 'Svět internetu',
                    25: 'Město',
                    26: 'Směry a poloha',
                    27: 'Emoce a pocity',
                    28: 'V restauraci',
                    29: 'Podstatná jména',
                    30: 'Pozitivní vlastnosti',
                    31: 'Domov a nábytek',
                    32: 'Měsíce',
                    33: 'Předměty domácnosti',
                    34: 'Práce a profese',
                    35: 'Domácí práce',
                    36: 'Pozitivní emoce a pocity',
                    37: 'Sport a volný čas',
                    38: 'Sporty',
                    39: 'Doprava',
                    40: 'Obchod',
                    41: 'Rozhovor s klientem',
                    42: 'Rodina',
                    43: 'Čísla',
                },
                2: {
                    1: 'Pozdravy a loučení',
                    2: 'Čísla',
                    3: 'Lidé a zájmena',
                    4: 'Jídlo',
                    5: 'Oblečení',
                    6: 'Čas',
                    7: 'Části těla',
                    8: 'Zvířata',
                    9: 'Rodina',
                    10: 'Rodina',
                    11: 'Zákon a nezákonnost',
                    12: 'Dovolená',
                    13: 'Příroda',
                    14: 'Každodenní život',
                    15: 'Škola',
                    16: 'Vzdělání',
                    17: 'Příslovce',
                    18: 'Geografie',
                    19: 'Dezerty',
                    20: 'Zdraví a medicína',
                    21: 'Slovesa',
                    22: 'Negativní vlastnosti',
                    23: 'Technologie a věda',
                    24: 'Negativní emoce a pocity',
                    25: 'Zelenina a ovoce',
                    26: 'Počasí a astronomie',
                    27: 'Tvary, vzory a velikosti',
                    28: 'Telefonní konverzace',
                    29: 'Svět televize',
                    30: 'Směrování a umístění',
                    31: 'Emoce a pocity',
                    32: 'Formální a zdvořilé výrazy',
                    33: 'Důležité vědět',
                    34: 'Nástroje a stroje',
                    35: 'Politika',
                    36: 'Domácí práce',
                    37: 'Domácí příslušenství',
                    38: 'Jazyk sociálních médií',
                    39: 'Pozitivní vlastnosti',
                    40: 'Podstatná jména',
                    41: 'Práce a zaměstnání',
                    42: 'Sport a rekreace',
                    43: 'Sporty',
                    44: 'Zaměstnání',
                    45: 'Doprava',
                    46: 'Kultura a umění',
                    47: 'Obchod',
                    48: 'Konverzace se zákazníkem',
                },
                3: {
                    1: 'Osoby a zájmeno',
                    2: 'Jídlo',
                    3: 'Části těla',
                    4: 'E-mail',
                    5: 'Rodina',
                    6: 'Zákon a nezákonnost',
                    7: 'Vzdělávání',
                    8: 'Příslovce',
                    9: 'Textový jazyk',
                    10: 'Geografie',
                    11: 'Negativní vlastnosti',
                    12: 'Zdraví a medicína',
                    13: 'Slovesa',
                    14: 'Technologie a věda',
                    15: 'Svět internetu',
                    16: 'Tvary, figury, velikosti',
                    17: 'Směrování a poloha',
                    18: 'Dovednosti',
                    19: 'Slang',
                    20: 'Důležitá slova',
                    21: 'Pozitivní vlastnosti',
                    22: 'Dům a nábytek',
                    23: 'Formální a zdvořilostní výrazy',
                    24: 'Příslušenství domácnosti',
                    25: 'Domácí práce',
                    26: 'Nástroje a stroje',
                    27: 'Politika',
                    28: 'Práce a povolání',
                    29: 'Podstatná jména',
                    30: 'Sport a volný čas',
                    31: 'Sporty',
                    32: 'Povolání',
                    33: 'Začíná na "A"',
                    34: 'Kultura a umění',
                    35: 'Obchod',
                    36: 'Události',
                },
            },
            ro: {
                1: {
                    1: 'Salutări și la revedere',
                    2: 'Numere',
                    3: 'Persoane și pronume',
                    4: 'Zilele săptămânii',
                    5: 'Culori',
                    6: 'Mâncare',
                    7: 'Îmbrăcăminte',
                    8: 'Timp',
                    9: 'Părți ale corpului',
                    10: 'Animale',
                    11: 'Numere',
                    12: 'Viața de zi cu zi',
                    13: 'Adverbe',
                    14: 'Verbe',
                    15: 'Emoții și sentimente negative',
                    16: 'Legume și fructe',
                    17: 'Vreme și astronomie',
                    18: 'Caracteristici negative',
                    19: 'Băuturi',
                    20: 'Tehnologie și știință',
                    21: 'Lumea televiziunii',
                    22: 'Convorbiri telefonice',
                    23: 'Forme, modele și dimensiuni',
                    24: 'Lumea internetului',
                    25: 'Oraș',
                    26: 'Direcție și locație',
                    27: 'Emoții și sentimente',
                    28: 'În restaurant',
                    29: 'Substantive',
                    30: 'Caracteristici pozitive',
                    31: 'Casa și mobilierul',
                    32: 'Lunile anului',
                    33: 'Accesorii de casă',
                    34: 'Muncă și profesii',
                    35: 'Munci casnice',
                    36: 'Emoții și sentimente pozitive',
                    37: 'Sport și timp liber',
                    38: 'Sporturi',
                    39: 'Transport',
                    40: 'Afaceri',
                    41: 'Discuții cu clienții',
                    42: 'Familie',
                    43: 'Numere',
                },
                2: {
                    1: 'Salutări și la revedere',
                    2: 'Numere',
                    3: 'Persoane și pronume',
                    4: 'Mâncare',
                    5: 'Îmbrăcăminte',
                    6: 'Timp',
                    7: 'Părți ale corpului',
                    8: 'Animale',
                    9: 'Părinți și copii',
                    10: 'Familie',
                    11: 'Lege și infracțiuni',
                    12: 'Vacanță',
                    13: 'Natură',
                    14: 'Viața de zi cu zi',
                    15: 'Școală',
                    16: 'Educație',
                    17: 'Adverbe',
                    18: 'Geografie',
                    19: 'Deserturi',
                    20: 'Sănătate și medicină',
                    21: 'Verbe',
                    22: 'Trăsături negative',
                    23: 'Tehnologie și știință',
                    24: 'Emoții și sentimente negative',
                    25: 'Legume și fructe',
                    26: 'Vreme și astronomie',
                    27: 'Forme, figuri și dimensiuni',
                    28: 'Convorbire telefonică',
                    29: 'Lumea televiziunii',
                    30: 'Orientare și amplasare',
                    31: 'Emoții și sentimente',
                    32: 'Expresii formale și de politețe',
                    33: 'Lucruri importante de știut',
                    34: 'Unelte și mașini',
                    35: 'Politica',
                    36: 'Munci casnice',
                    37: 'Accesorii casnice',
                    38: 'Limbajul rețelelor sociale',
                    39: 'Trăsături pozitive',
                    40: 'Substantive',
                    41: 'Muncă și angajare',
                    42: 'Sport și recreere',
                    43: 'Sporturi',
                    44: 'Ocupații',
                    45: 'Transport',
                    46: 'Cultură și artă',
                    47: 'Comerț',
                    48: 'Convorbire cu clienții',
                },
                3: {
                    1: 'Persoane și pronume',
                    2: 'Mâncare',
                    3: 'Părți ale corpului',
                    4: 'E-mail',
                    5: 'Familie',
                    6: 'Lege și ilegalitate',
                    7: 'Educație',
                    8: 'Adverbe',
                    9: 'Limba textului',
                    10: 'Geografie',
                    11: 'Calități negative',
                    12: 'Sănătate și medicină',
                    13: 'Verbe',
                    14: 'Tehnologie și știință',
                    15: 'Lumea internetului',
                    16: 'Forme, figuri și dimensiuni',
                    17: 'Direcție și poziție',
                    18: 'Abilități',
                    19: 'Argou',
                    20: 'Cuvinte importante',
                    21: 'Calități pozitive',
                    22: 'Casă și mobilier',
                    23: 'Expresii formale și polite',
                    24: 'Accesorii de casă',
                    25: 'Munci casnice',
                    26: 'Unelte și mașini',
                    27: 'Politica',
                    28: 'Muncă și profesii',
                    29: 'Substantive',
                    30: 'Sport și timp liber',
                    31: 'Sporturi',
                    32: 'Profesii',
                    33: 'Începe cu "A"',
                    34: 'Cultură și artă',
                    35: 'Comerț',
                    36: 'Evenimente',
                },
            },
            hu: {
                1: {
                    1: 'Köszöntések és Búcsúzások',
                    2: 'Számok',
                    3: 'Emberek és Névmások',
                    4: 'Hét Napjai',
                    5: 'Színek',
                    6: 'Ételek',
                    7: 'Ruházat',
                    8: 'Idő',
                    9: 'Testrészek',
                    10: 'Állatok',
                    11: 'Számok',
                    12: 'Mindennapi Élet',
                    13: 'Melléknevek',
                    14: 'Igek',
                    15: 'Negatív Érzelmek és Hangulatok',
                    16: 'Zöldségek és Gyümölcsök',
                    17: 'Időjárás és Csillagászat',
                    18: 'Negatív Jellemzők',
                    19: 'Italok',
                    20: 'Technológia és Tudomány',
                    21: 'TV Világa',
                    22: 'Telefonbeszélgetések',
                    23: 'Alakzatok, Formák és Méretek',
                    24: 'Az Internet Világa',
                    25: 'Város',
                    26: 'Irányok és Helyzetek',
                    27: 'Érzelmek és Hangulatok',
                    28: 'Étteremben',
                    29: 'Személynév',
                    30: 'Pozitív Jellemzők',
                    31: 'Otthon és Bútorok',
                    32: 'Hónapok',
                    33: 'Háztartási Kiegészítők',
                    34: 'Munka és Foglalkozások',
                    35: 'Háztartási Munkák',
                    36: 'Pozitív Érzelmek és Hangulatok',
                    37: 'Sport és Szabadidő',
                    38: 'Sportok',
                    39: 'Közlekedés',
                    40: 'Vállalkozás',
                    41: 'Ügyfélkapcsolat',
                    42: 'Család',
                    43: 'Számok',
                },
                2: {
                    1: 'Köszöntések és Búcsúzások',
                    2: 'Számok',
                    3: 'Emberek és Névmások',
                    4: 'Ételek',
                    5: 'Ruha',
                    6: 'Idő',
                    7: 'Testrészek',
                    8: 'Állatok',
                    9: 'Szülők és Gyermekek',
                    10: 'Család',
                    11: 'Jog és Jogtalanság',
                    12: 'Vakáció',
                    13: 'Természet',
                    14: 'Mindennapi Élet',
                    15: 'Iskola',
                    16: 'Oktatás',
                    17: 'Melléknevek',
                    18: 'Földrajz',
                    19: 'Desszertek',
                    20: 'Egészség és Orvostudomány',
                    21: 'Igek',
                    22: 'Negatív Jellemzők',
                    23: 'Technológia és Tudomány',
                    24: 'Negatív Érzelmek és Hangulatok',
                    25: 'Zöldségek és Gyümölcsök',
                    26: 'Időjárás és Csillagászat',
                    27: 'Alakzatok, Formák és Méretek',
                    28: 'Telefonbeszélgetés',
                    29: 'TV Világa',
                    30: 'Irányok és Helyzetek',
                    31: 'Érzelmek és Hangulatok',
                    32: 'Formális és Udvarias Kifejezések',
                    33: 'Fontos Tudnivalók',
                    34: 'Eszközök és Gépek',
                    35: 'Politika',
                    36: 'Háztartási Munkák',
                    37: 'Háztartási Kiegészítők',
                    38: 'A Szociális Média Nyelve',
                    39: 'Pozitív Jellemzők',
                    40: 'Személynév',
                    41: 'Munka és Foglalkozás',
                    42: 'Sport és Kikapcsolódás',
                    43: 'Sportok',
                    44: 'Foglalkozások',
                    45: 'Közlekedés',
                    46: 'Kultúra és Művészet',
                    47: 'Üzlet',
                    48: 'Ügyfélkapcsolat',
                },
                3: {
                    1: 'Emberek és Névmások',
                    2: 'Ételek',
                    3: 'Testrészek',
                    4: 'E-mail',
                    5: 'Család',
                    6: 'Jog és Jogtalanság',
                    7: 'Oktatás',
                    8: 'Melléknevek',
                    9: 'Szöveges Nyelv',
                    10: 'Földrajz',
                    11: 'Negatív Jellemzők',
                    12: 'Egészség és Orvostudomány',
                    13: 'Igek',
                    14: 'Technológia és Tudomány',
                    15: 'Az Internet Világa',
                    16: 'Alakzatok, Formák és Méretek',
                    17: 'Irányok és Helyzetek',
                    18: 'Készségek',
                    19: 'Szlengek',
                    20: 'Fontos Szavak',
                    21: 'Pozitív Jellemzők',
                    22: 'Otthon és Bútorok',
                    23: 'Formális és Udvarias Kifejezések',
                    24: 'Háztartási Kiegészítők',
                    25: 'Háztartási Munkák',
                    26: 'Eszközök és Gépek',
                    27: 'Politika',
                    28: 'Munka és Foglalkozások',
                    29: 'Személynév',
                    30: 'Sport és Szabadidő',
                    31: 'Sportok',
                    32: 'Foglalkozások',
                    33: 'Az "A"-val Kezdődik',
                    34: 'Kultúra és Művészet',
                    35: 'Üzlet',
                    36: 'Események',
                },
            },
        },
    },
};
