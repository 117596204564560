export const dictionary = {
  login: {
    pl: "Logowanie",
    cs: "Přihlášení",
    sk: "Prihlásenie",
    en: "Log in",
    hu: "BEJELENTKEZÉS",
    hr: "Prijava",
    lt: "Prisijungti",
    it: "Accedi",
    de: "Anmeldung",
    sl: "Prijava",
  },
  register: {
    pl: "Rejestracja",
    cs: "Registrace",
    sk: "Registrácia",
    en: "Register",
    hu: "REGISZTRÁCIÓ",
    hr: "Registracija",
    lt: "Registruotis",
    it: "Registrati",
    de: "Registrieren",
    sl: "Registracija",
  },
  username: {
    pl: "nazwa użytkownika",
    cs: "uživatelské jméno",
    sk: "uživatelské jméno",
    en: "user name",
    hu: "felhasználónév",
    hr: "korisničko ime",
    lt: "naudotojo vardas",
    it: "nome utente",
    de: "Benutzername",
    sl: "uporabniško ime",
  },
  orderCourse: {
    pl: "Zamów kurs",
    cs: "Order a course",
    sk: "Order a course",
    en: "Order a course",
    hu: "Rendeljen egy tanfolyamot",
    hr: "Naruči tečaj",
    lt: "Užsisakykite kursą",
    it: "Ordina un corso",
    de: "Kurs bestellen",
    sl: "Naročite tečaj",
  },
  aboutLexicos: {
    pl: "O Lexicos",
    cs: "About Lexicos",
    sk: "About Lexicos",
    en: "About Lexicos",
    hu: "About Lexicos",
    hr: "O Lexicos",
    lt: 'Apie "Lexicos',
    it: "Informazioni su Lexicos",
    de: "Über Lexicos",
    sl: "O Lexicos",
  },
  aboutUs: {
    pl: "O nas",
    cs: "About us",
    sk: "About us",
    en: "About us",
    hu: "About us",
    hr: "O nama",
    lt: "Apie mus",
    it: "Chi siamo",
    de: "Über uns",
    sl: "O nas",
  },
  check: {
    pl: "Sprawdź",
    cs: "Podívejte se",
    sk: "Skontrolujte",
    en: "Check",
    hu: "Jelölje be",
    hr: "Provjeri",
    lt: "Patikrinkite",
    it: "Verifica",
    de: "Überprüfen",
    sl: "Preveri",
  },
  homePageSloganFirstLine: {
    pl: "Nauka nowego języka?",
    cs: "Učíte se cizí jazyk?",
    sk: "Objavujete nový jazyk?",
    en: "Discovering a new language?",
    hu: "Új nyelvet fedez fel?",
    hr: "Učenje novog jezika?",
    lt: "Atrandate naują kalbą?",
    it: "Scoprendo una nuova lingua?",
    de: "Entdecken Sie eine neue Sprache?",
    sl: "Odkrijate nov jezik?",
  },
  homePageSloganSecondLine: {
    pl: "To tylko 2 kroki!",
    cs: "Stačí jen 2 kroky!",
    sk: "Stačia na to len 2 kroky!",
    en: "It only takes 2 steps!",
    hu: "Mindössze 2 lépés!",
    hr: "Samo su 2 koraka!",
    lt: "Tereikia tik 2 žingsnių!",
    it: "Bastano solo 2 passi!",
    de: "Es dauert nur 2 Schritte!",
    sl: "Samo 2 koraka!",
  },
  loginBoxDescription: {
    pl: "Masz już konto na platformie i chcesz rozpocząć naukę? Twoja przygoda z językiem zaczyna się właśnie tutaj!",
    cs: "Již máte na platformě účet a chcete se začít učit?  Vaše jazyková dobrodružství právě začínají!",
    sk: "Už máte konto na platforme a chcete sa začať učiť?  Vaše jazykové dobrodružstvo sa začína práve tu!",
    en: "Already have an account on the platform and want to begin learning?  Your language adventure starts right here!",
    hu: "Már van fiókod a platformon, és szeretnéd elkezdeni a tanulást? Nyelvi kalandod itt és most kezdődik!",
    hr: "Imate račun na platformi i želite započeti učenje? Vaša avantura s jezikom počinje ovdje!",
    lt: "Jau turite paskyrą platformoje ir norite pradėti mokytis?  Jūsų kalbų nuotykis prasideda čia!",
    it: "Hai già un account sulla piattaforma e vuoi iniziare a imparare? La tua avventura linguistica inizia proprio qui!",
    de: "Haben Sie bereits ein Konto auf der Plattform und möchten mit dem Lernen beginnen? Ihr Sprachabenteuer beginnt genau hier!",
    sl: "Imate račun na platformi in želite začeti učiti? Vaša jezikovna pustolovščina se začne tukaj!",
  },
  registerBoxDescription: {
    pl: "Masz już kod do rejestracji? Świetnie! W tym miejscu możesz założyć konto!",
    cs: "Máte již registrační kód? Skvělé! Nyní si zde můžete vytvořit účet!",
    sk: "Máte už registračný kód? Skvelé! Tu si môžete vytvoriť účet!",
    en: "Do you already have a registration code? Great! Here you can create an account!",
    hu: "Van már regisztrációs kódod? Szuper! Itt tudsz fiókot létrehozni!",
    hr: "Imate kod za registraciju? Odlično! Ovdje možete stvoriti svoj račun!",
    lt: "Ar jau turite registracijos kodą? Puiku! Čia galite susikurti paskyrą!",
    it: "Hai già un codice di registrazione? Ottimo! Qui puoi creare un account!",
    de: "Haben Sie bereits einen Registrierungscode? Großartig! Hier können Sie ein Konto erstellen!",
    sl: "Imate registracijsko kodo? Super! Tukaj lahko ustvarite račun!",
  },
  learnMoreHeader: {
    pl: "Chcesz dowiedzieć się więcej?",
    cs: "Chtěli byste se naučit více?",
    sk: "Chcete sa naučiť viac?",
    en: "Would you like to learn more?",
    hu: "Szeretne többet megtudni?",
    hr: "Želite saznati više?",
    lt: "Norite išmokti daugiau?",
    it: "Vuoi saperne di più?",
    de: "Möchten Sie mehr erfahren?",
    sl: "Želite izvedeti več?",
  },
  learnMoreDescription: {
    pl: "Czym jest Lexicos? Co myślą o nim kursanci? Dlaczego warto?",
    cs: "Co je Lexicos? Jaké jsou názory studentů? Proč to stojí za to?",
    sk: "Čo je to Lexicos? Čo si o ňom myslia používatelia kurzov? Prečo sa oplatí?",
    en: "What is Lexicos? What do course users think about it? Why is it worth it?",
    hu: "Mi az Lexicos? Mit gondolnak róla a kurzus felhasználói? Miért éri meg?",
    hr: "Što je Lexicos? Što misle o njemu polaznici? Zašto vrijedi?",
    lt: "Kas yra Lexicos? Ką apie jį mano kursų naudotojai? Kodėl verta jį išbandyti?",
    it: "Cos'è Lexicos? Cosa ne pensano gli utenti del corso? Perché ne vale la pena?",
    de: "Was ist Lexicos? Was denken Kursteilnehmer darüber? Warum lohnt es sich?",
    sl: "Kaj je Lexicos? Kaj si mislijo uporabniki tečajev? Zakaj se splača?",
  },
  learnMoreButton: {
    pl: "więcej",
    cs: "více",
    sk: "viac",
    en: "more",
    hu: "több",
    hr: "više",
    lt: "daugiau",
    it: "di più",
    de: "Mehr",
    sl: "več",
  },
  mainPageWelcomeBoxHeaderOne: {
    pl: "Cześć",
    cs: "Ahoj",
    sk: "Ahoj",
    en: "Hi",
    hu: "Szia",
    hr: "Bok",
    lt: "Sveiki",
    it: "Ciao",
    de: "Hallo",
    sl: "Živjo",
  },
  mainPageWelcomeBoxHeaderTwo1: {
    pl: "Świetnie Ci idzie!",
    cs: "Vedeš si skvěle!",
    sk: "Darí sa ti skvele",
    en: "You're doing great",
    hu: "Jól csinálod",
    hr: "Odlično vam ide!",
    lt: "Jums puikiai sekasi",
    it: "Stai andando alla grande",
    de: "Du machst das großartig!",
    sl: "Super ti gre!",
  },
  mainPageWelcomeBoxHeaderTwo2: {
    pl: "Niezła robota!",
    cs: "Dobrá práce",
    sk: "Dobrá práca",
    en: "Good job",
    hu: "Jó munka",
    hr: "Dobar posao!",
    lt: "Geras darbas",
    it: "Bel lavoro",
    de: "Gute Arbeit!",
    sl: "Dobro opravljeno!",
  },
  mainPageWelcomeBoxHeaderTwo3: {
    pl: "Rozwijasz się!",
    cs: "Zlepšuješ se",
    sk: "Zlepšujete sa",
    en: "You're improving",
    hu: "Fejlődsz",
    hr: "Napredujete!",
    lt: "Jūs tobulėjate",
    it: "Stai migliorando",
    de: "Du verbesserst dich!",
    sl: "Izboljšuješ se!",
  },
  mainPageWelcomeBoxTextFirstLine1: {
    pl: "Znajomość języków obcych jest nie tylko przydatna, ale wręcz niezbędna do osiągnięcia sukcesu w życiu.",
    cs: "Znalost cizích jazyků je nejen užitečná, ale také nezbytná k dosažení životního úspěchu.",
    sk: "Znalosť cudzích jazykov je nielen užitočná, ale aj nevyhnutná pre úspech v živote.",
    en: "Knowledge of foreign languages is not only useful, but actually necessary for success in life.",
    hu: "A külföldi nyelvek ismerete nemcsak hasznos, hanem szükséges is az életben való sikerhez.",
    hr: "Znanje stranih jezika nije samo korisno, već je neophodno za postizanje uspjeha u životu.",
    lt: "Užsienio kalbų mokėjimas yra ne tik naudingas, bet ir būtinas norint sėkmingai gyventi.",
    it: "La conoscenza delle lingue straniere non è solo utile, ma addirittura necessaria per avere successo nella vita.",
    de: "Fremdsprachenkenntnisse sind nicht nur nützlich, sondern tatsächlich notwendig für den Erfolg im Leben.",
    sl: "Znanje tujih jezikov ni samo koristno, temveč tudi nujno potrebno za uspeh v življenju.",
  },
  mainPageWelcomeBoxTextFirstLine2: {
    pl: "Osoby, które uczą się języków obcych łatwiej zapamiętują i koncentrują się na zadaniach, a także są lepszymi słuchaczami.",
    cs: "Lidé, kteří mluví více než jedním jazykem, mají lepší paměť, lepší soustředění, schopnost multitaskingu a lepší schopnost naslouchat.",
    sk: "Ľudia, ktorí ovládajú viac ako jeden jazyk, majú lepšiu pamäť, lepšiu koncentráciu, schopnosť vykonávať viac úloh naraz a lepšie počúvajú.",
    en: "People who speak more than one language have improved memory, enhanced concentration, ability to multitask, and better listening skills.",
    hu: "Azok az emberek, akik több nyelvet beszélnek, javítják a memóriájukat, javítják a koncentrációjukat, képesek több feladatot egyszerre elvégezni, és jobb hallási képességeik vannak.",
    hr: "Ljudi koji uče strane jezike lakše pamte i koncentriraju se na zadatke, a također su bolji slušatelji.",
    lt: "Daugiau nei viena kalba kalbantys žmonės turi geresnę atmintį, geresnę koncentraciją, geba atlikti daug užduočių ir geriau klausosi.",
    it: "Le persone che parlano più di una lingua hanno una memoria migliorata, una concentrazione maggiore, capacità di multitasking e migliori capacità di ascolto.",
    de: "Menschen, die mehr als eine Sprache sprechen, haben ein besseres Gedächtnis, eine verbesserte Konzentration, Multitasking-Fähigkeiten und bessere Hörfähigkeiten.",
    sl: "Ljudje, ki govorijo več kot en jezik, imajo boljši spomin, izboljšano koncentracijo, sposobnost večopravilnosti in boljše slušne spretnosti.",
  },
  mainPageWelcomeBoxTextFirstLine3: {
    pl: "Nauka nowego języka to niezwykłe doświadczenie. Poczuj radość, rozmawiając z kimś w jego ojczystym języku.",
    cs: "Naučit se cizí jazyk si vyžaduje pevnou vůli. Výhodou je úžasný pocit úspěchu, který pocítíte při konverzaci s někým v jeho rodném jazyce.",
    sk: "Učiť sa cudzí jazyk znamená vystúpiť zo svojej komfortnej zóny. Pozitívom je úžasný pocit úspechu, ktorý pocítite, keď sa s niekým rozprávate v jeho rodnom jazyku.",
    en: "Learning a language means moving out of your comfort zone. The upside is the amazing sense of accomplishment you’ll feel when conversing with someone in their native language.",
    hu: "A nyelvtanulás azt jelenti, hogy kilépsz a komfortzónádból. Az előny az, hogy elképesztő érzés lesz, amikor valakivel anyanyelvén beszélgetsz.",
    hr: "Učenje novog jezika je nevjerojatno iskustvo. Osjetite radost razgovarajući s nekim na njegovom materinjem jeziku.",
    lt: "Mokytis kalbos reiškia išeiti iš savo komforto zonos. Geroji pusė - nuostabus pasiekimo jausmas, kurį pajusite bendraudami su žmogumi jo gimtąja kalba.",
    it: "Imparare una lingua significa uscire dalla propria zona di comfort. Il vantaggio è il meraviglioso senso di realizzazione che proverai conversando con qualcuno nella sua lingua madre.",
    de: "Eine neue Sprache zu lernen bedeutet, aus der Komfortzone herauszutreten. Der Vorteil ist das erstaunliche Erfolgserlebnis, das Sie beim Sprechen mit jemandem in seiner Muttersprache verspüren.",
    sl: "Učenje novega jezika pomeni izstopiti iz cone udobja. Prednost je neverjeten občutek dosežka, ko se pogovarjate z nekom v njegovem maternem jeziku.",
  },
  mainPageWelcomeBoxTextSecondLine: {
    pl: "W następnej lekcji nauczysz się:",
    cs: "V příští lekci se naučíte:",
    sk: "V nasledujúcej lekcii sa naučíte:",
    en: "In the next lesson you will learn:",
    hu: "A következő leckében megtanulod:",
    hr: "U sljedećoj lekciji naučit ćete:",
    lt: "Kitoje pamokoje sužinosite:",
    it: "Nella prossima lezione imparerai:",
    de: "In der nächsten Lektion lernen Sie:",
    sl: "V naslednji lekciji se boste naučili:",
  },
  yourActivityBoxHeader: {
    pl: "Twoja aktywność",
    cs: "Tvoje aktivita",
    sk: "Vaša aktivita",
    en: "Your activity",
    hu: "Az Ön tevékenysége",
    hr: "Tvoja aktivnost",
    lt: "Jūsų veikla",
    it: "La tua attività",
    de: "Ihre Aktivität",
    sl: "Vaša dejavnost",
  },
  yourActivityBoxDaysOfLearning: {
    pl: "dni nauki z rzędu",
    cs: "Dny učení v řadě",
    sk: "Dni učenia v rade",
    en: "days of learning in a row",
    hu: "napos tanulás",
    hr: "dani učenja zaredom",
    lt: "mokymosi dienos iš eilės",
    it: "giorni di apprendimento di fila",
    de: "Lerntage in Folge",
    sl: "dnevi učenja zaporedoma",
  },
  yourActivityBoxLearnedWords: {
    pl: "poznanych dziś słów",
    cs: "Nová slovíčka za dnešek",
    sk: "slová, ktoré ste sa dnes naučili",
    en: "the words you learned today",
    hu: "a mai tanult szavak",
    hr: "danas naučene riječi",
    lt: "šiandien išmoktus žodžius",
    it: "le parole che hai imparato oggi",
    de: "heute gelernte Wörter",
    sl: "besede, ki ste se jih danes naučili",
  },
  level: {
    pl: "Poziom",
    cs: "Úroveň",
    sk: "Úroveň",
    en: "Level",
    hu: "Szint",
    hr: "Razina",
    lt: "Lygis",
    it: "Livello",
    de: "Stufe",
    sl: "Raven",
  },
  mainPageProgressBarDescription: {
    pl: "Twój postęp na poziomie",
    cs: "Váš pokrok na úrovni",
    sk: "Váš pokrok na úrovni",
    en: "Your progress on level",
    hu: "A fejlődésed szintenként",
    hr: "Tvoj napredak na razini",
    lt: "Jūsų pažanga, padaryta siekiant lygio",
    it: "Il tuo progresso a livello",
    de: "Ihr Fortschritt auf Stufe",
    sl: "Vaš napredek na ravni",
  },
  mainPageContinueLearningButtonText: {
    pl: "Kontynuuj naukę",
    cs: "Pokračovat v učení",
    sk: "Pokračujte v učení",
    en: "Continue learning",
    hu: "Tovább a tanulással",
    hr: "Nastavi učiti",
    lt: "Tęsti mokymąsi",
    it: "Continua a imparare",
    de: "Weiterlernen",
    sl: "Nadaljujte z učenjem",
  },
  mainPageAllLessonsgButtontext: {
    pl: "Wszystkie lekcje",
    cs: "Všechny lekce",
    sk: "Všetky lekcie",
    en: "All lessons",
    hu: "Az összes lecke",
    hr: "Sve lekcije",
    lt: "Visos pamokos",
    it: "Tutte le lezioni",
    de: "Alle Lektionen",
    sl: "Vse lekcije",
  },
  mainPageActiveCourseHeader: {
    pl: "Twój aktualny kurs",
    cs: "Tvůj aktuální kurz",
    sk: "Tvoj aktuálny kurz",
    en: "Active course",
    hu: "Aktuális kurzusod",
    hr: "Tvoj trenutni tečaj",
    lt: "Aktyvus kursas",
    it: "Corso attivo",
    de: "Aktiver Kurs",
    sl: "Aktivni tečaj",
  },
  mainPageOtherCoursesButtonTextPre: {
    pl: "Chcesz nauczyć się więcej?",
    cs: "Chceš se naučit více?",
    sk: "Chceš sa naučiť viac?",
    en: "Want to learn more?",
    hu: "Szeretnél többet megtanulni?",
    hr: "Želiš naučiti više?",
    lt: "Norite išmokti daugiau?",
    it: "Vuoi imparare di più?",
    de: "Möchten Sie mehr lernen?",
    sl: "Želite izvedeti več?",
  },
  mainPageOtherCoursesButtonText: {
    pl: "Aktywuj kolejny kurs >>",
    cs: "Aktivuj další kurz >>",
    sk: "Aktivuj ďalší kurz >>",
    en: "Activate another course >>",
    hu: "Aktiváld a következő kurzust >>",
    hr: "Aktiviraj sljedeći tečaj >>",
    lt: "Aktyvinti kitą kursą >>",
    it: "Attivare il corso successivo >>",
    de: "Aktivieren Sie den nächsten Kurs >>",
    sl: "Aktiviraj naslednji tečaj >>",
  },
  mainPageFunFactsHeader: {
    pl: "Ciekawostki",
    cs: "Zajímavá fakta",
    sk: "Zaujímavosti",
    en: "Interesting facts",
    hu: "Interesting facts",
    hr: "Zanimljivosti",
    lt: "Įdomūs faktai",
    it: "Fatti interessanti",
    de: "Interessante Fakten",
    sl: "Zanimivosti",
  },
  showPassword: {
    pl: "Pokaż hasło",
    cs: "Zobrazit heslo",
    sk: "Ukázať heslo",
    en: "Show password",
    hu: "Mutasd a jelszót",
    hr: "Prikaži lozinku",
    lt: "Rodyti slaptažodį",
    it: "Mostra password",
    de: "Passwort anzeigen",
    sl: "Pokaži geslo",
  },
  hidePassword: {
    pl: "Ukryj hasło",
    cs: "Skrýt heslo",
    sk: "Skryť heslo",
    en: "Hide password",
    hu: "Jelszó elrejtése",
    hr: "Sakrij lozinku",
    lt: "Paslėpti slaptažodį",
    it: "Nascondi password",
    de: "Passwort verbergen",
    sl: "Skrij geslo",
  },
  passwordsNotMatchAlert: {
    pl: "Hasła nie są takie same",
    cs: "Hesla nejsou stejná",
    sk: "Heslá sa nezhodujú",
    en: "Passwords do not match",
    hu: "A jelszavak nem egyeznek",
    hr: "Lozinke se ne podudaraju",
    lt: "Slaptažodžiai nesutampa",
    it: "Le password non corrispondono",
    de: "Passwörter stimmen nicht überein",
    sl: "Gesli se ne ujemata",
  },
  progressModalMondayShort: {
    pl: "pn.",
    cs: "Po.",
    sk: "Po,",
    en: "Mon.",
    hu: "Hé",
    hr: "pon.",
    lt: "Pn.",
    it: "Lun.",
    de: "Mo.",
    sl: "pon.",
  },
  progressModalTuesdayShort: {
    pl: "wt.",
    cs: "Út.",
    sk: "Ut.",
    en: "Tue.",
    hu: "Ke",
    hr: "uto.",
    lt: "Ant.",
    it: "Mar.",
    de: "Di.",
    sl: "tor.",
  },
  progressModalWednesdayShort: {
    pl: "śr.",
    cs: "St.",
    sk: "St.",
    en: "Wed.",
    hu: "Sze",
    hr: "sri.",
    lt: "Treč.",
    it: "Mer.",
    de: "Mi.",
    sl: "sre.",
  },
  progressModalThursdayShort: {
    pl: "czw.",
    cs: "Čt.",
    sk: "Stv.",
    en: "Thu.",
    hu: "Cs",
    hr: "čet.",
    lt: "Ketv.",
    it: "Gio.",
    de: "Do.",
    sl: "čet.",
  },
  progressModalFridayShort: {
    pl: "pt.",
    cs: "Pá.",
    sk: "Pi.",
    en: "Fri.",
    hu: "Pé",
    hr: "pet.",
    lt: "Penkt.",
    it: "Ven.",
    de: "Fr.",
    sl: "pet.",
  },
  progressModalSaturdayShort: {
    pl: "sob.",
    cs: "So.",
    sk: "So.",
    en: "Sat.",
    hu: "Szo",
    hr: "sub.",
    lt: "Šv.",
    it: "Sab.",
    de: "Sa.",
    sl: "sob.",
  },
  progressModalSundayShort: {
    pl: "ndz.",
    cs: "Ne.",
    sk: "Ne.",
    en: "Sun",
    hu: "Vas",
    hr: "ned.",
    lt: "Sekmadienis",
    it: "Dom.",
    de: "So.",
    sl: "ned.",
  },
  progressModalChartTitle: {
    pl: "Wykres Twoich postępów",
    cs: "Graf vašeho pokroku",
    sk: "Graf vášho pokroku",
    en: "Charting your progress",
    hu: "A fejlődés feltérképezése",
    hr: "Grafikon tvog napretka",
    lt: "Pažangos grafikas",
    it: "Tracciamento dei tuoi progressi",
    de: "Diagramm Ihres Fortschritts",
    sl: "Grafikon vašega napredka",
  },
  back: {
    pl: "Wstecz",
    cs: "Zpět",
    sk: "Späť",
    en: "Back",
    hu: "Vissza",
    hr: "Natrag",
    lt: "Atgal",
    it: "Indietro",
    de: "Zurück",
    sl: "Nazaj",
  },
  emailLabel: {
    pl: "adres e-mail",
    cs: "e-mailová adresa",
    sk: "emailová adresa",
    en: "email address",
    hu: "email cím",
    hr: "e-mail adresa",
    lt: "el. pašto adresas",
    it: "indirizzo email",
    de: "E-Mail-Adresse",
    sl: "e-poštni naslov",
  },
  passwordLabel: {
    pl: "hasło",
    cs: "heslo",
    sk: "heslo",
    en: "password",
    hu: "jelszó",
    hr: "lozinka",
    lt: "slaptažodis",
    it: "password",
    de: "Passwort",
    sl: "geslo",
  },
  activationCodeLabel: {
    pl: "kod",
    cs: "kód",
    sk: "kód",
    en: "code",
    hu: "kód",
    hr: "kod",
    lt: "kodas",
    it: "codice",
    de: "Code",
    sl: "koda",
  },
  repeatPasswordLabel: {
    pl: "powtórz hasło",
    cs: "zopakovat heslo",
    sk: "zopakujte heslo",
    en: "repeat password",
    hu: "jelszó újra",
    hr: "ponovi lozinku",
    lt: "pakartoti slaptažodį",
    it: "ripeti password",
    de: "Passwort wiederholen",
    sl: "ponovi geslo",
  },
  oldPasswordLabel: {
    pl: "stare hasło",
    cs: "staré heslo",
    sk: "staré heslo",
    en: "old password",
    hu: "régi jelszó",
    hr: "stara lozinka",
    lt: "senasis slaptažodis",
    it: "vecchia password",
    de: "Altes Passwort",
    sl: "staro geslo",
  },
  registerEmailDesc: {
    pl: "Twój adres e-mail, którego będziesz używać do zalogowania się",
    cs: "Vaše e-mailová adresa, kterou budete používat pro přihlášení",
    sk: "Vaša e-mailová adresa, ktorú budete používať na prihlásenie",
    en: "Your e-mail address, which you will use to log in",
    hu: "az Ön e-mail címe, amelyet a bejelentkezéshez fog használni",
    hr: "Tvoja e-mail adresa koju ćeš koristiti za prijavu",
    lt: "Jūsų el. pašto adresas, kurį naudosite prisijungimui",
    it: "Il tuo indirizzo e-mail, che userai per accedere",
    de: "Ihre E-Mail-Adresse, die Sie zum Einloggen verwenden werden",
    sl: "Vaš e-poštni naslov, ki ga boste uporabili za prijavo",
  },
  loginEmailDesc: {
    pl: "Twój adres e-mail, który podawałeś/aś przy założeniu konta",
    cs: "Vaše e-mailová adresa, kterou jste zadali při vytváření účtu",
    sk: "Vaša e-mailová adresa, ktorú ste uviedli pri vytváraní účtu",
    en: "Your email address you entered when you created account",
    hu: "A fiók létrehozásakor megadott e-mail címed",
    hr: "Tvoja e-mail adresa koju si dao/la prilikom stvaranja računa",
    lt: "Jūsų el. pašto adresas, kurį įvedėte kurdami paskyrą",
    it: "L'indirizzo email che hai inserito quando hai creato l'account",
    de: "Ihre E-Mail-Adresse, die Sie bei der Kontoerstellung angegeben haben",
    sl: "Vaš e-poštni naslov, ki ste ga vnesli ob ustvarjanju računa",
  },
  activationCodeDesc: {
    pl: "otrzymałeś/aś go w liście",
    cs: "obdrželi jste ho v dopise",
    sk: "dostali ste to v liste",
    en: "you have received it in the letter",
    hu: "evélben kaptad",
    hr: "primio/la si ga u pismu",
    lt: "jį gavote laiške",
    it: "lo hai ricevuto nella lettera",
    de: "Sie haben es im Brief erhalten",
    sl: "prejeli ste ga v pismu",
  },
  registerPasswordDesc: {
    pl: "Stwórz unikalne hasło, które łatwo zapamiętasz.",
    cs: "vytvořte si vlastní jedinečné heslo, které si snadno zapamatujete",
    sk: "vytvorte si svoje jedinečné heslo, ktoré si ľahko zapamätáte",
    en: "create your own unique password that you can easily remember",
    hu: "hozzon létre saját egyedi jelszót, amelyet könnyen megjegyezhet",
    hr: "Stvori jedinstvenu lozinku koju ćeš lako zapamtiti.",
    lt: "sukurkite savo unikalų slaptažodį, kurį galėtumėte lengvai įsiminti",
    it: "crea la tua password unica che puoi ricordare facilmente",
    de: "Erstellen Sie Ihr eigenes einzigartiges Passwort, das Sie sich leicht merken können",
    sl: "ustvarite svoje unikatno geslo, ki si ga boste zlahka zapomnili",
  },
  repeatPasswordDesc: {
    pl: "aby upewnić się, że pamiętasz hasło, wpisz je jeszcze raz",
    cs: "abyste se ujistili, že si heslo pamatujete, zadejte ho znovu",
    sk: "aby ste sa uistili, že si zapamätáte svoje heslo, zadajte ho znova",
    en: "to make sure you remember your password, type it again",
    hu: "hogy biztosan emlékezzen a jelszavára, írja be újra",
    hr: "kako bi se uvjerio/la da pamtiš lozinku, unesi je ponovno",
    lt: "norėdami įsitikinti, kad atsimenate slaptažodį, įveskite jį dar kartą",
    it: "per essere sicuro di ricordare la tua password, digita di nuovo",
    de: "Um sicherzustellen, dass Sie sich Ihr Passwort merken, geben Sie es erneut ein",
    sl: "da se prepričate, da si geslo zapomnite, ga vnesite še enkrat",
  },
  loginPasswordDesc: {
    pl: "Twoje unikalne hasło, stworzone podczas zakładania konta",
    cs: "Vaše jedinečné heslo, které jste si vytvořili při vytváření účtu",
    sk: "Vaše jedinečné heslo, ktoré ste si vytvorili pri vytváraní účtu",
    en: "Your unique password that you created when you created account",
    hu: "Az Ön egyedi jelszava, amelyet a fiók létrehozásakor hozott létre",
    hr: "Tvoja jedinstvena lozinka, stvorena prilikom stvaranja računa",
    lt: "Savo unikalų slaptažodį, kurį sukūrėte kurdami paskyrą",
    it: "La tua password unica che hai creato quando hai creato l'account",
    de: "Ihr einzigartiges Passwort, das Sie bei der Kontoerstellung erstellt haben",
    sl: "Vaše unikatno geslo, ki ste ga ustvarili ob registraciji računa",
  },
  loginButtonText: {
    pl: "Zaloguj się",
    cs: "Přihlásit se",
    sk: "Prihláste sa",
    en: "Log in",
    hu: "Belépés",
    hr: "Prijavi se",
    lt: "Prisijungti",
    it: "Accedi",
    de: "Anmelden",
    sl: "Prijavi se",
  },
  logoutButtonText: {
    pl: "Wyloguj się",
    cs: "Odhlásit se",
    sk: "Odhlásiť sa",
    en: "Log out",
    hu: "Kijelentkezés",
    hr: "Odjavi se",
    lt: "Atsijungti",
    it: "Esci",
    de: "Abmelden",
    sl: "Odjava",
  },
  logoutMessage: {
    pl: "Czy na pewno chcesz się wylogować?",
    cs: "Opravdu se chcete odhlásit?",
    sk: "Naozaj sa chcete odhlásiť?",
    en: "Are you sure you want to log out?",
    hu: "Biztosan ki akar jelentkezni?",
    hr: "Jeste li sigurni da se želite odjaviti?",
    lt: "Ar tikrai norite atsijungti?",
    it: "Sei sicuro di voler uscire?",
    de: "Sind Sie sicher, dass Sie sich abmelden möchten?",
    sl: "Ste prepričani, da se želite odjaviti?",
  },
  registerSlogan: {
    pl: "Aby utworzyć konto i mieć dostęp do nauki wykupionego kursu językowego, wypełnij poniższe pola:",
    cs: "Pro vytvoření účtu a přístup k zakoupenému jazykovému kurzu vyplňte níže uvedená pole:",
    sk: "Ak chcete vytvoriť účet a získať prístup k štúdiu jazykového kurzu, ktorý ste si zakúpili, vyplňte polia nižšie:",
    en: "Fill in the fields below to create an account and have access to your language course:",
    hu: "Töltse ki az alábbi mezőket a fiók létrehozásához és a nyelvtanfolyamhoz való hozzáféréshez:",
    hr: "Da biste stvorili račun i pristupili učenju kupljenog jezičnog tečaja, ispunite sljedeća polja:",
    lt: "Užpildykite toliau esančius laukus, kad susikurtumėte paskyrą ir turėtumėte prieigą prie savo kalbos kurso:",
    it: "Compila i campi sottostanti per creare un account e avere accesso al tuo corso di lingua:",
    de: "Um ein Konto zu erstellen und Zugang zu Ihrem gekauften Sprachkurs zu erhalten, füllen Sie die folgenden Felder aus:",
    sl: "Izpolnite spodnja polja za ustvarjanje računa in dostop do vašega jezikovnega tečaja:",
  },
  loginSlogan: {
    pl: "Jeśli posiadasz już konto, wypełnij poniższe pola:",
    cs: "Pokud již máte účet, vyplňte níže uvedená pole:",
    sk: "Ak už máte účet, vyplňte polia nižšie:",
    en: "If you already have an account, please fill out the fields below:",
    hu: "Ha már rendelkezik fiókkal, kérjük, töltse ki az alábbi mezőket:",
    hr: "Ako već imate račun, ispunite sljedeća polja:",
    lt: "Jei jau turite paskyrą, užpildykite toliau esančius laukus:",
    it: "Se hai già un account, compila i campi sottostanti:",
    de: "Wenn Sie bereits ein Konto haben, füllen Sie bitte die folgenden Felder aus:",
    sl: "Če že imate račun, izpolnite spodnja polja:",
  },
  registerButtonText: {
    pl: "Załóż konto",
    cs: "Vytvořit účet",
    sk: "Vytvoriť účet",
    en: "Create an account",
    hu: "Fiók létrehozása",
    hr: "Kreiraj račun",
    lt: "Sukurti paskyrą",
    it: "Crea un account",
    de: "Konto erstellen",
    sl: "Ustvari račun",
  },
  levelOneText: {
    pl: "podstawowy",
    cs: "základní",
    sk: "ZÁKLADNÉ",
    en: "Basic",
    hu: "Alapvető",
    hr: "osnovni",
    lt: "Pagrindinis",
    it: "Base",
    de: "Grundlegend",
    sl: "Osnovno",
  },
  levelTwoText: {
    pl: "średni",
    cs: "střední",
    sk: "Stredne",
    en: "Medium",
    hu: "Közepes",
    hr: "srednji",
    lt: "Vidutinis",
    it: "Medio",
    de: "Mittel",
    sl: "Srednje",
  },
  levelThreeText: {
    pl: "zaawansowany",
    cs: "pokročilý",
    sk: "POKROČILÉ",
    en: "Advanced",
    hu: "Fejlett",
    hr: "napredni",
    lt: "Išplėstinė",
    it: "Avanzato",
    de: "Fortgeschritten",
    sl: "Napredno",
  },
  pageTitle: {
    pl: "Lexicos",
    cs: "Lexicos",
    sk: "Lexicos",
    en: "Lexicos",
    hu: "Lexicos",
    hr: "Lexicos",
    lt: "Lexicos",
    it: "Lexicos",
    de: "Lexicos",
    sl: "Lexicos",
  },
  activityExpressionLearning: {
    pl: "Poznaj słowo",
    cs: "Zkoumej slovo",
    sk: "Objavte slovo",
    en: "Discover the word",
    hu: "Fedezze fel a szót",
    hr: "Upoznaj riječ",
    lt: "Atraskite žodį",
    it: "Scopri la parola",
    de: "Wort entdecken",
    sl: "Odkrijte besedo",
  },
  activityExpressionListening: {
    pl: "Co słyszysz?",
    cs: "Vyber, co slyšíš",
    sk: "Vyberte, čo počujete",
    en: "Mark what you hear",
    hu: "Jelölje meg, mit hall",
    hr: "Što čuješ?",
    lt: "Pažymėkite tai, ką girdite",
    it: "Segna ciò che senti",
    de: "Markieren, was Sie hören",
    sl: "Označite, kar slišite",
  },
  activityExpressionTranslation: {
    pl: "Ułóż zdanie",
    cs: "Použijte slova a správně přeložte",
    sk: "Použite slová na vytvorenie správneho prekladu",
    en: "Make the correct translation from the words",
    hu: "Készítsd el a helyes fordítást a szavakból!",
    hr: "Sastavi rečenicu",
    lt: "Teisingai išverskite žodžius",
    it: "Fai la traduzione corretta dalle parole",
    de: "Machen Sie die richtige Übersetzung aus den Wörtern",
    sl: "Naredite pravilen prevod iz besed",
  },
  activityUnscrambleExpressionLetters: {
    pl: "Ułóż słowo",
    cs: "Použijte písmena a správně přeložte",
    sk: "Použite písmená na vytvorenie správneho prekladu",
    en: "Use letters to make the correct translation",
    hu: "Használd a betűket a helyes fordítás elkészítéséhez",
    hr: "Složi riječ",
    lt: "Naudokite raides teisingam vertimui atlikti",
    it: "Usa le lettere per fare la traduzione corretta",
    de: "Verwenden Sie Buchstaben, um die richtige Übersetzung zu machen",
    sl: "Uporabite črke za pravilen prevod",
  },
  activityLinkExpressions: {
    pl: "Ułóż słowo",
    cs: "Použijte písmena a správně přeložte",
    sk: "Použite písmená na vytvorenie správneho prekladu",
    en: "Use letters to make the correct translation",
    hu: "Használd a betűket a helyes fordítás elkészítéséhez",
    hr: "Složi riječ",
    lt: "Naudokite raides teisingam vertimui atlikti",
    it: "Usa le lettere per fare la traduzione corretta",
    de: "Verwenden Sie Buchstaben, um die richtige Übersetzung zu machen",
    sl: "Uporabite črke za pravilen prevod",
  },
  exampleSentence: {
    pl: "Przykładowe zdanie",
    cs: "Příklad věty",
    sk: "Príklad vety",
    en: "Example sentence",
    hu: "Példamondat",
    hr: "Primjerni rečenica",
    lt: "Sakinio pavyzdys",
    it: "Frase di esempio",
    de: "Beispielsatz",
    sl: "Primer stavka",
  },
  yourActivity: {
    pl: "Twoja aktywność",
    cs: "Tvoje aktivita",
    sk: "Vaša aktivita",
    en: "Your activity",
    hu: "Az Ön tevékenysége",
    hr: "Tvoja aktivnost",
    lt: "Jūsų veikla",
    it: "La tua attività",
    de: "Ihre Aktivität",
    sl: "Vaša dejavnost",
  },
  tutorial: {
    pl: "Samouczek",
    cs: "Výukový kurz",
    sk: "Učebnica",
    en: "Tutorial",
    hu: "Oktatóanyag",
    hr: "Upute",
    lt: "Mokomoji priemonė",
    it: "Tutorial",
    de: "Anleitung",
    sl: "Vodič",
  },
  changePassword: {
    pl: "Zmień hasło",
    cs: "Změna hesla",
    sk: "Zmena hesla",
    en: "Change Password",
    hu: "Jelszó megváltoztatása",
    hr: "Promijeni lozinku",
    lt: "Keisti slaptažodį",
    it: "Cambia Password",
    de: "Passwort ändern",
    sl: "Spremeni geslo",
  },
  logOut: {
    pl: "Wyloguj się",
    cs: "Odhlásit se",
    sk: "Odhlásiť sa",
    en: "Log out",
    hu: "Kijelentkezés",
    hr: "Odjavi se",
    lt: "Atsijungti",
    it: "Esci",
    de: "Abmelden",
    sl: "Odjava",
  },
  help: {
    pl: "Pomoc",
    cs: "Nápověda",
    sk: "Pomoc",
    en: "Help",
    hu: "Segítség",
    hr: "Pomoć",
    lt: "Pagalba",
    it: "Aiuto",
    de: "Hilfe",
    sl: "Pomoč",
  },
  goodAnswer: {
    pl: "Dobrze",
    cs: "Dobře",
    sk: "Dobrý",
    en: "Good",
    hu: "Jó",
    hr: "Dobro",
    lt: "Gera",
    it: "Bene",
    de: "Gut",
    sl: "Dobro",
  },
  badAnswer: {
    pl: "Źle",
    cs: "Špatně",
    sk: "Zlé",
    en: "Bad",
    hu: "Rossz",
    hr: "Loše",
    lt: "Blogai",
    it: "Male",
    de: "Schlecht",
    sl: "Slabo",
  },
  continueTasks: {
    pl: "Dalej",
    cs: "Další",
    sk: "Pokračovať",
    en: "Continue",
    hu: "Folytatás",
    hr: "Nastavi",
    lt: "Tęsti",
    it: "Continua",
    de: "Fortfahren",
    sl: "Nadaljuj",
  },
  lessonCompleted: {
    pl: "ukończone",
    cs: "Dokončeno",
    sk: "Dokončené",
    en: "Completed",
    hu: "Befejezett",
    hr: "završeno",
    lt: "Užbaigta",
    it: "Completato",
    de: "Abgeschlossen",
    sl: "Dokončano",
  },
  lessonInProgress: {
    pl: "w trakcie",
    cs: "Probíhá",
    sk: "prebieha",
    en: "in progress",
    hu: "folyamatban",
    hr: "u tijeku",
    lt: "Vykdoma",
    it: "in corso",
    de: "In Bearbeitung",
    sl: "v teku",
  },
  lessonNotActive: {
    pl: "niekatywne",
    cs: "Není aktivní",
    sk: "nie je aktívny",
    en: "not active",
    hu: "nem aktív",
    hr: "neaktivno",
    lt: "neaktyvus",
    it: "non attivo",
    de: "Nicht aktiv",
    sl: "ni aktivno",
  },
  minutes: {
    pl: "minut",
    cs: "minut",
    sk: "minút",
    en: "minutes",
    hu: "perc",
    hr: "minuta",
    lt: "minučių",
    it: "minuti",
    de: "Minuten",
    sl: "minute",
  },
  languageChangeYourCourses: {
    pl: "Twoje kursy",
    cs: "Vaše kurzy",
    sk: "Vaše kurzy",
    en: "Your courses",
    hu: "A tanfolyamaid",
    hr: "Tvoji tečajevi",
    lt: "Jūsų kursai",
    it: "I tuoi corsi",
    de: "Ihre Kurse",
    sl: "Vaši tečaji",
  },
  languageChangeAvailable: {
    pl: "Rozszerz swoje umiejętności językowe:",
    cs: "Rozšiř své jazykové dovednosti:",
    sk: "Rozšír svoje jazykové schopnosti:",
    en: "Also available:",
    hu: "Fejleszd nyelvi készségeidet:",
    hr: "Proširi svoje jezične vještine:",
    lt: "Taip pat galite rasti:",
    it: "Espandi le tue competenze linguistiche:",
    de: "Auch verfügbar:",
    sl: "Na voljo tudi:",
  },
  languageActivationInputLabel: {
    pl: "Wprowadź kod, aby aktywować nowy język:",
    cs: "Zadejte kód pro aktivaci nového jazyka:",
    sk: "Zadaním kódu aktivujete nový jazyk:",
    en: "Enter the code to activate the new language:",
    hu: "Írd be a kódot az új nyelv aktiválásához:",
    hr: "Unesi kod za aktivaciju novog jezika:",
    lt: "Įveskite kodą, kad aktyvuotumėte naują kalbą:",
    it: "Inserisci il codice per attivare la nuova lingua:",
    de: "Geben Sie den Code ein, um die neue Sprache zu aktivieren:",
    sl: "Vnesite kodo za aktivacijo novega jezika:",
  },
  languageActivationButtonText: {
    pl: "aktywuj",
    cs: "aktivace",
    sk: "aktivácia",
    en: "activation",
    hu: "aktiválás",
    hr: "aktiviraj",
    lt: "Aktyvavimas",
    it: "attiva",
    de: "Aktivierung",
    sl: "aktivacija",
  },
  languageActivationInfoFirst: {
    pl: "Nie masz kodu?",
    cs: "Nemáte kód?",
    sk: "Nemáte kód?",
    en: "Don't have a code?",
    hu: "Nincs kódod?",
    hr: "Nemaš kod?",
    lt: "Neturite kodo?",
    it: "Non hai un codice?",
    de: "Haben Sie keinen Code?",
    sl: "Nimate kode?",
  },
  languageActivationInfoSecond: {
    pl: "Napisz do nas na maila: kontakt@lexicos-edu.com",
    cs: "Napište nám na adresu: kzs@lexicos-edu.com",
    sk: "Napíšte nám na: ksz@lexicos-edu.com",
    en: "Write to us at:",
    hu: "Írj nekünk: kapcsolat@lexicos-edu.com",
    it: "Scrivici a:",
    de: "Schreiben Sie uns unter:",
    sl: "Pišite nam na:",
  },
  contactText: {
    pl: "W razie problemów prosimy o kontakt:",
    cs: "V případě jakýchkoli problémů se prosím obraťte na:",
    sk: "V prípade akýchkoľvek problémov nás prosím kontaktujte:",
    en: "If you have any problems please contact us:",
    hu: "Ha bármilyen problémája van, forduljon hozzánk:",
    hr: "U slučaju problema, kontaktirajte nas:",
    lt: "Jei kyla problemų, susisiekite su mumis:",
    it: "In caso di problemi, contattaci:",
    de: "Bei Problemen kontaktieren Sie uns bitte unter:",
    sl: "Če imate kakšne težave, nas kontaktirajte:",
  },
  contactEmail: {
    pl: "bok@lexicos-edu.com",
    cs: "kzs@lexicos-edu.com",
    sk: "ksz@lexicos-edu.com",
    en: "csc@lexicos-edu.com",
    hu: "kapcsolat@lexicos-edu.com",
    it: "bok@lexicos-edu.com",
    de: "kundenservice@lexicos-edu.com",
    sl: "podpora@lexicos-edu.eu"
  },
  certButtonText: {
    pl: "Certyfikat",
    cs: "Certifikát",
    sk: "Certifikát",
    en: "Certificate",
    hu: "Certificate",
    hr: "Certifikat",
    lt: "Sertifikatas",
    it: "Certificato",
    de: "Zertifikat",
    sl: "Certifikat",
  },
  certText: {
    pl: "Ukończyłeś cały kurs i chcesz uzyskać certyfikat? Napisz do nas:",
    cs: "Absolvovali jste celý kurz a chcete získat certifikát? Napište nám na adresu:",
    sk: "Absolvovali ste celý kurz a chcete získať certifikát? Napíšte nám na:",
    en: "Have you completed the entire course and want to get a certificate? Write to us at:",
    hu: "Végigcsináltad a teljes tanfolyamot és szeretnél egy tanúsítványt? Írj nekünk:",
    hr: "Završio/la si cijeli tečaj i želiš certifikat? Piši nam:",
    lt: "Baigėte visą kursą ir norite gauti sertifikatą? Rašykite mums adresu:",
    it: "Hai completato l'intero corso e vuoi ottenere un certificato? Scrivici a:",
    de: "Haben Sie den gesamten Kurs abgeschlossen und möchten ein Zertifikat erhalten? Schreiben Sie uns:",
    sl: "Ste zaključili celoten tečaj in želite pridobiti certifikat? Pišite nam na:",
  },
  certEmail: {
    pl: "bok@lexicos-edu.com",
    cs: "kzs@lexicos-edu.com",
    sk: "ksz@lexicos-edu.com",
    en: "csc@lexicos-edu.com",
    hu: "kapcsolat@lexicos-edu.com",
    it: "bok@lexicos-edu.com",
    de: "kundenservice@lexicos-edu.com",
    sl: "podpora@lexicos-edu.eu"
  },
  properAnswer: {
    pl: "Poprawna odpowiedź:",
    cs: "Správná odpověď:",
    sk: "Správna odpoveď:",
    en: "Correct answer:",
    hu: "Helyes válasz:",
    hr: "Točan odgovor:",
    lt: "Teisingas atsakymas:",
    it: "Risposta corretta:",
    de: "Richtige Antwort:",
    sl: "Pravilen odgovor:",
  },
  lessonModalHeader: {
    pl: "Świetnie!",
    cs: "Skvělé!",
    sk: "Skvelé!",
    en: "Great!",
    hu: "Nagyszerű!",
    hr: "Odlično!",
    lt: "Puikiai!",
    it: "Fantastico!",
    de: "Großartig!",
    sl: "Odlično!",
  },
  lessonModalText: {
    pl: "Koniec lekcji",
    cs: "Lekci jste dokončili",
    sk: "Ukončili ste lekciu",
    en: "You have completed the lesson",
    hu: "Befejezted a leckét",
    hr: "Kraj lekcije",
    lt: "Baigėte pamoką",
    it: "Hai completato la lezione",
    de: "Sie haben die Lektion abgeschlossen",
    sl: "Zaključili ste lekcijo",
  },
  lessonModalNextLessonText: {
    pl: "Dalej",
    cs: "Další",
    sk: "Ďalej",
    en: "Next",
    hu: "Következő",
    hr: "Dalje",
    lt: "Kitas",
    it: "Avanti",
    de: "Weiter",
    sl: "Naprej",
  },
  lessonModalToAllLessonText: {
    pl: "Wszystkie lekcje",
    cs: "Všechny lekce",
    sk: "Všetky lekcie",
    en: "All lessons",
    hu: "Összes lecke",
    hr: "Sve lekcije",
    lt: "Visos pamokos",
    it: "Tutte le lezioni",
    de: "Alle Lektionen",
    sl: "Vse lekcije",
  },
  tutorialFirstPointText: {
    pl: 'Ucz się, klikając w duży przycisk "Kontynuuj naukę". To takie proste!',
    cs: 'Učení vždy začněte kliknutím na velké tlačítko "Pokracovat v uceni". Je to tak jednoduché!',
    sk: 'Učenie vždy začnite kliknutím na veľké tlačidlo "Pokračovať v učení". Je to tak jednoduché!',
    en: 'Always start learning by clicking on the big "Pokracovat v uceni" button. It\'s that simple!',
    hu: "A tanulást mindig a „Pokracovat v uceni” nagy gombra kattintva kezd el. Ennyire egyszerű!",
    hr: 'Uči klikom na veliki gumb "Nastavi učiti". To je tako jednostavno!',
    lt: 'Visada pradėkite mokytis paspaudę didelį mygtuką "Pokracovat v uceni". Tai taip paprasta!',
    it: 'Inizia sempre a studiare facendo clic sul grande pulsante "Pokracovat v uceni". È così semplice!',
    de: 'Beginnen Sie immer mit dem Lernen, indem Sie auf die große Schaltfläche "Weiter lernen" klicken. Es ist so einfach!',
    sl: 'Vedno začnite z učenjem s klikom na velik gumb "Pokracovat v uceni". Tako preprosto je!',
  },
  tutorialSecondPointText: {
    pl: 'Chcesz zapoznać się z innymi lekcjami? Po klinknięciu w przycisk "Wszystkie lekcje" zapoznasz się ze szczegółowym planem.',
    cs: 'Chcete se seznámit s dalšími lekcemi? Po kliknutí na "Vsechny lekce" si můžete prohlédnout podrobný jízdní řád.',
    sk: 'Chcete sa naučiť ďalšie lekcie? Kliknutím na "Všetky lekcie" zobrazíte podrobný rozvrh.',
    en: 'Do you want to get to know other lessons? After clicking on "Vsechny lekce" you can see the detailed schedule.',
    hu: "Szeretnél megismerkedni más leckékkel? A „Vsechny lekce” gombra kattintva megtekintheted a részletes menetrendet.",
    hr: 'Želiš se upoznati s drugim lekcijama? Klikni na gumb "Sve lekcije" za detaljni plan.',
    lt: 'Ar norite susipažinti su kitomis pamokomis? Paspaudę "Vsechny lekce" galite pamatyti išsamų tvarkaraštį.',
    it: 'Vuoi conoscere altre lezioni? Dopo aver cliccato su "Vsechny lekce" puoi vedere il programma dettagliato.',
    de: 'Möchten Sie andere Lektionen kennenlernen? Nach dem Klicken auf "Alle Lektionen" können Sie den detaillierten Zeitplan sehen.',
    sl: 'Želite spoznati druge lekcije? Po kliku na "Vsechny lekce" si lahko ogledate podroben urnik.',
  },
  tutorialThirdPointText: {
    pl: "W tym miejscu poznasz dokładny plan lekcji. Jeśli chcesz, możesz tu zmienić poziom nauki, a także zapoznać się z dodatkowymi materiałami.",
    cs: "Zde najdete přesný jízdní řád. Pokud si přejete, můžete zde změnit úroveň lekcí a také si prohlédnout další materiály.",
    sk: "Tu nájdete presný rozvrh. Ak chcete, môžete si tu zmeniť úroveň lekcií a prezrieť si ďalšie materiály.",
    en: "Here you will find the exact schedule of lessons. If you wish, you can change the level of lessons here, and also view additional materials.",
    hu: "Itt találod a leckék pontos menetrendjét. Ha szeretnéd, itt megváltoztathatod a leckék szintjét, és megtekintheted a további anyagokat.",
    hr: "Ovdje ćeš saznati točan plan lekcija. Ako želiš, ovdje možeš promijeniti razinu učenja, kao i upoznati se s dodatnim materijalima.",
    lt: "Čia rasite tikslų pamokų tvarkaraštį. Jei norite, čia galite keisti pamokų lygį, taip pat peržiūrėti papildomą medžiagą.",
    it: "Qui troverai l'esatto programma delle lezioni. Se desideri, puoi cambiare il livello delle lezioni qui e visualizzare materiali aggiuntivi.",
    de: "Hier finden Sie den genauen Zeitplan der Lektionen. Wenn Sie möchten, können Sie hier das Niveau der Lektionen ändern und zusätzliche Materialien ansehen.",
    sl: "Tukaj boste našli natančen urnik lekcij. Če želite, lahko tukaj spremenite raven lekcij in si ogledate dodatno gradivo.",
  },
  tutorialFourthPointText: {
    pl: "Chcesz zmienić poziom nauki? Zadania są dla Ciebie zbyt proste, albo zbyt trudne? Wystarczy, że klikniesz w wybrany przez siebie poziom w tym miejscu.",
    cs: "Chcete změnit úroveň učení? Jsou pro vás úkoly příliš snadné nebo příliš obtížné? Stačí kliknout na vybranou úroveň.",
    sk: "Chcete zmeniť úroveň vzdelávania? Sú pre vás úlohy príliš ľahké alebo príliš ťažké? Stačí kliknúť na vybranú úroveň.",
    en: "Do you want to change the level of learning? Are the tasks too easy or too difficult for you? Just click on the desired level here.",
    hu: "Szeretnéd megváltoztatni a leckék szintjét? A feladatok túl könnyűek vagy túl nehézek számodra? Csak kattints a kívánt szintre.",
    hr: "Želiš promijeniti razinu učenja? Zadaci su ti prelagani ili preteški? Samo klikni na razinu koju odabereš ovdje.",
    lt: "Ar norite pakeisti mokymosi lygį? Ar užduotys jums per lengvos, ar per sunkios? Tiesiog spustelėkite norimą lygį čia.",
    it: "Vuoi cambiare il livello di apprendimento? I compiti sono troppo facili o troppo difficili per te? Clicca semplicemente sul livello desiderato qui.",
    de: "Möchten Sie das Lernniveau ändern? Sind die Aufgaben zu einfach oder zu schwierig für Sie? Klicken Sie einfach hier auf das gewünschte Niveau.",
    sl: "Želite spremeniti raven učenja? So naloge prelahke ali pretežke za vas? Preprosto kliknite tukaj na želeno raven.",
  },
  tutorialFifthPointText: {
    pl: "Okno Twojej aktywności pokazuje, jak przebiega Twój proces nauki. Systematyczna nauka sprawi że osiągniesz wymarzone rezultaty jeszcze szybciej.",
    cs: "Okno s aktivitami vám ukazuje, jak probíhá vaše učení. Pokud budete studovat systematicky, dosáhnete vysněných výsledků ještě rychleji.",
    sk: "V okne aktivít sa zobrazuje priebeh učenia. Ak budete študovať systematicky, dosiahnete svoje vysnívané výsledky ešte rýchlejšie.",
    en: "Your activity window shows how your learning process is going. Systematic learning will make you achieve your dream results even faster.",
    hu: "Az aktivitásablak mutatja, hogyan halad a tanulási folyamatod. A rendszeres tanulásnak köszönhetően még gyorsabban elérheted a kívánt eredményeket.",
    hr: "Prozor tvoje aktivnosti pokazuje kako napreduje tvoj proces učenja. Sustavno učenje će ti pomoći da postigneš željene rezultate još brže.",
    lt: "Jūsų veiklos lange rodoma, kaip vyksta mokymosi procesas. Sistemingai mokydamiesi galėsite dar greičiau pasiekti svajonių rezultatų.",
    it: "La finestra delle tue attività mostra come sta andando il tuo processo di apprendimento. Lo studio sistematico ti permetterà di raggiungere i tuoi risultati desiderati ancora più velocemente.",
    de: "Ihr Aktivitätsfenster zeigt, wie Ihr Lernprozess verläuft. Systematisches Lernen wird Ihnen helfen, Ihre Traumergebnisse noch schneller zu erreichen.",
    sl: "Vaše okno dejavnosti prikazuje, kako poteka vaš učni proces. Sistematično učenje vam bo pomagalo še hitreje doseči vaše sanjske rezultate.",
  },
  tutorialSixthPointText: {
    pl: "W tym miejscu widzisz, jakiego języka aktualnie się uczysz. Jeśli posiadasz dostęp do innych kursów, po kliknięciu w “Aktywuj kolejny kurs >>” możesz wybrać inny język do nauki.",
    cs: 'Zde se můžete podívat, který jazyk se právě učíte. Pokud máte přístup k dalším kurzům, můžete si vybrat další jazyk, který se chcete naučit, kliknutím na "další kurzy >>".',
    sk: 'Tu môžete vidieť, ktorý jazyk sa práve učíte. Ak máte prístup k iným kurzom, môžete si vybrať ďalší jazyk, ktorý sa chcete naučiť, kliknutím na "ďalšie kurzy >>".',
    en: 'Here you can see what language you are currently learning. If you have access to other courses, you can choose another language to learn by clicking on "other courses >>".',
    hu: "Itt láthatod, milyen nyelvet tanulsz éppen. Ha hozzáférésed van más kurzusokhoz, akkor a „további kurzusok >>” gombra kattintva választhatsz másik nyelvet, amit szeretnél megtanulni.",
    hr: "Ovdje vidiš koji jezik trenutno učiš. Ako imaš pristup drugim tečajevima, klikom na “Aktiviraj sljedeći tečaj >>” možeš odabrati drugi jezik za učenje.",
    lt: 'Čia galite matyti, kokios kalbos šiuo metu mokotės. Jei turite prieigą prie kitų kursų, galite pasirinkti kitą kalbą, kurios norite mokytis, spustelėję "kiti kursai >>".',
    it: `Qui puoi vedere quale lingua stai attualmente imparando. Se hai accesso ad altri corsi, puoi scegliere un'altra lingua da imparare facendo clic su "altri corsi >>".`,
    de: "Hier sehen Sie, welche Sprache Sie gerade lernen. Wenn Sie Zugang zu anderen Kursen haben, können Sie hier nach dem Klicken auf 'Weitere Kurse' eine andere Sprache auswählen.",
    sl: 'Tukaj lahko vidite, kateri jezik se trenutno učite. Če imate dostop do drugih tečajev, lahko izberete drug jezik za učenje s klikom na "drugi tečaji >>".',
  },
  tutorialSeventhPointText: {
    pl: "Chcesz nauczyć się kolejnego języka? Wpisz tutaj kod aktywacyjny nowego języka.",
    cs: "Chcete se naučit další jazyk? Zde zadejte aktivační kód pro nový jazyk.",
    sk: "Chcete sa naučiť ďalší jazyk? Tu zadajte aktivačný kód pre nový jazyk.",
    en: "Do you want to learn another language? Enter the activation code for the new language here.",
    hu: "Szeretnél megtanulni egy újabb nyelvet? Írd be ide az új nyelv aktiválókódját.",
    hr: "Želiš naučiti još jedan jezik? Ovdje unesi aktivacijski kod novog jezika.",
    lt: "Ar norite išmokti kitą kalbą? Čia įveskite naujos kalbos aktyvavimo kodą.",
    it: "Vuoi imparare un'altra lingua? Inserisci qui il codice di attivazione per la nuova lingua.",
    de: "Möchten Sie eine weitere Sprache lernen? Geben Sie hier den Aktivierungscode für die neue Sprache ein.",
    sl: "Želite se naučiti drugega jezika? Tukaj vnesite aktivacijsko kodo za nov jezik.",
  },
  tutorialEighthPointText: {
    pl: "Każdego dnia, dowiedz się czegoś nowego! Aby nauka nie stała się nudną rutyną, zebraliśmy dla Ciebie ciekawostki o krajach, których kursy są dostępne na Lexicos.",
    cs: "Každý den se naučte něco nového! Aby se učení nestalo nudnou rutinou, shromáždili jsme pro vás zajímavosti o zemích, jejichž kurzy jsou na Lexicosu k dispozici.",
    sk: "Každý deň sa naučte niečo nové! Aby sa učenie nestalo nudnou rutinou, zozbierali sme zaujímavé fakty o krajinách, ktorých kurzy sú dostupné na portáli Lexicos.",
    en: "Learn something new every day! To make learning not become a boring routine, we have collected interesting facts about the countries whose courses are available on Lexicos.",
    hu: "Minden nap tanulj meg valami újat! Hogy a tanulás ne váljon unalmas rutinná, összegyűjtöttünk neked néhány érdekességet azokról az országokról, amelyek nyelvtanfolyamai elérhetőek az Lexicos.",
    hr: "Svaki dan nauči nešto novo! Da učenje ne postane dosadna rutina, prikupili smo za tebe zanimljivosti o zemljama čiji su tečajevi dostupni na Lexicos.",
    lt: 'Kasdien išmokite ką nors naujo! Kad mokymasis netaptų nuobodžia rutina, surinkome įdomių faktų apie šalis, kurių kursai prieinami "Lexicos".',
    it: "Impara qualcosa di nuovo ogni giorno! Per evitare che lo studio diventi una routine noiosa, abbiamo raccolto curiosità sui paesi i cui corsi sono disponibili su Lexicos.",
    de: "Lernen Sie jeden Tag etwas Neues! Damit das Lernen nicht zur langweiligen Routine wird, haben wir interessante Fakten über die Länder gesammelt, deren Kurse auf Lexicos verfügbar sind.",
    sl: "Vsak dan se naučite nekaj novega! Da učenje ne bi postalo dolgočasna rutina, smo zbrali zanimiva dejstva o državah, katerih tečaji so na voljo na Lexicosu.",
  },
  tutorialNinthPointText: {
    pl: "Po kliknięciu na symbol 3 poziomych kresek w prawym górnym rogu ekranu, rozwinie się panel użytkownika. Jest to miejsce, w którym uzyskasz dostęp do najważniejszych funkcji, takich jak zmiana hasła, czy wylogowanie. Możesz tu także sprawdzić swoją aktywność, albo uzyskać informacje o kontakcie lub certyfikacie.",
    cs: "Pokud kliknete na symbol 3 vodorovných čárek v pravém horním rohu obrazovky, rozbalí se uživatelský panel. Zde máte přístup k nejdůležitějším funkcím, jako je změna hesla nebo odhlášení. Zde můžete také zkontrolovat svou aktivitu nebo získat kontaktní informace či certifikát.",
    sk: "Ak kliknete na symbol 3 vodorovných čiar v pravom hornom rohu obrazovky, používateľský panel sa rozbalí. Tu máte prístup k najdôležitejším funkciám, ako je zmena hesla alebo odhlásenie. Tu si tiež môžete skontrolovať svoju aktivitu alebo získať svoje kontaktné informácie či certifikát.",
    en: "After clicking on the button on the symbol of 3 horizontal lines in the upper right corner of the screen, the navigation panel will open. Here you will get access to the most important functions such as changing the password or logging out. You can also check your activity, or get contact information or a certificate.",
    hu: "Ha rákattintasz a képernyő jobb felső sarkában található 3 vízszintes vonalra, akkor megnyílik a felhasználói panel. Itt éred el a legfontosabb funkciókat, mint például a jelszó megváltoztatása vagy a kijelentkezés. Itt ellenőrizheted a tevékenységedet, vagy megnézheted a kapcsolatfelvételi információkat vagy a tanúsítványt.",
    hr: "Klikom na simbol 3 vodoravne crte u gornjem desnom kutu ekrana, otvorit će se korisnički panel. To je mjesto gdje možeš pristupiti najvažnijim funkcijama, kao što su promjena lozinke ili odjava. Ovdje možeš također provjeriti svoju aktivnost ili dobiti informacije o kontaktu ili certifikatu.",
    lt: "Jei spustelėsite 3 horizontalių brūkšnelių simbolį viršutiniame dešiniajame ekrano kampe, išsiplės naudotojo skydelis. Čia galėsite pasiekti svarbiausias funkcijas, pavyzdžiui, pakeisti slaptažodį arba atsijungti. Čia taip pat galite patikrinti savo veiklą arba gauti kontaktinę ar sertifikato informaciją.",
    it: "Dopo aver cliccato sul simbolo delle 3 linee orizzontali nell'angolo in alto a destra dello schermo, si aprirà il pannello di navigazione. Qui avrai accesso alle funzioni più importanti come cambiare la password o disconnetterti. Puoi anche controllare la tua attività o ottenere informazioni di contatto o un certificato.",
    de: "Nach dem Klicken auf das Symbol der 3 horizontalen Linien in der oberen rechten Ecke des Bildschirms öffnet sich das Navigationsmenü. Hier erhalten Sie Zugang zu den wichtigsten Funktionen wie Passwort ändern oder Abmelden. Sie können auch Ihre Aktivität überprüfen oder Kontaktinformationen oder ein Zertifikat erhalten.",
    sl: "Ko kliknete na simbol treh vodoravnih črt v zgornjem desnem kotu zaslona, ​​se odpre uporabniška plošča. Tukaj imate dostop do najpomembnejših funkcij, kot so sprememba gesla ali odjava. Tukaj lahko preverite svojo dejavnost ali dobite kontaktne informacije in certifikat.",
  },
  tutorialPointTitleLesson: {
    pl: "Lekcja",
    cs: "Lekce",
    sk: "Lekcia",
    en: "Lesson",
    hu: "Lecke",
    hr: "Lekcija",
    lt: "Pamokos",
    it: "Lezione",
    de: "Lektion",
    sl: "Lekcija",
  },
  tutorialLessonFirstPointText: {
    pl: "Na samej górze widzisz poziom nauki, tytuł lekcji oraz jej numer",
    cs: "Úplně nahoře vidíte úroveň učení, název lekce a číslo lekce.",
    sk: "Úplne hore vidíte úroveň učenia, názov lekcie a číslo lekcie.",
    en: "At the very top you can see the learning level, lesson title and lesson number",
    hu: "Legfelül láthatja a tanulási szintet, a lecke címét és az óra számát",
    hr: "Na vrhu vidiš razinu učenja, naslov lekcije i njezin broj",
    lt: "Pačiame viršuje matote mokymosi lygį, pamokos pavadinimą ir pamokos numerį.",
    it: "In alto puoi vedere il livello di apprendimento, il titolo della lezione e il numero della lezione",
    de: "Ganz oben sehen Sie das Lernniveau, den Lektionstitel und die Lektion Nummer.",
    sl: "Na vrhu vidite raven učenja, naslov lekcije in njeno številko.",
  },
  tutorialLessonSecondPointText: {
    pl: "Każde z zadań zawiera krótkie polecenie, dzięki któremu wiesz, co robić.",
    cs: "Každý úkol obsahuje krátký příkaz, abyste věděli, co máte udělat.",
    sk: "Každá úloha obsahuje krátky príkaz, aby ste vedeli, čo máte urobiť.",
    en: "Each task has a short command so you know what to do.",
    hu: "Minden feladathoz tartozik egy rövid parancs, így tudja, mit kell tennie.",
    hr: "Svaki zadatak sadrži kratke upute, tako da znaš što trebaš raditi.",
    lt: "Pačiame viršuje matote mokymosi lygį, pamokos pavadinimą ir pamokos numerį.",
    it: "Ogni compito ha un breve comando così sai cosa fare.",
    de: "Jede Aufgabe enthält einen kurzen Befehl, damit Sie wissen, was zu tun ist.",
    sl: "Vsaka naloga vsebuje kratka navodila, da veste, kaj morate storiti.",
  },
  tutorialLessonThirdPointText: {
    pl: "Na samym dole znajduje się Twój pasek postępu. Zauważ, że każdy z jego kolorów oznacza coś innego:",
    cs: "Úplně dole se nachází ukazatel průběhu. Všimněte si, že každá z jeho barev znamená něco jiného:",
    sk: "Úplne dole sa nachádza ukazovateľ priebehu. Všimnite si, že každá z jeho farieb znamená niečo iné:",
    en: "At the very bottom is your progress bar. Notice that each of its colors means something else:",
    hu: "Lent láthatja a haladási sávját. Vegye észre, hogy mindegyik színe mást jelent:",
    hr: "Na dnu se nalazi tvoja traka napretka. Primijeti da svaka njena boja označava nešto drugo:",
    lt: "Pačioje apačioje yra jūsų pažangos juosta. Atkreipkite dėmesį, kad kiekviena jos spalva reiškia skirtingas spalvas:",
    it: "In fondo alla pagina si trova la tua barra di progresso. Nota che ciascun colore rappresenta qualcosa di diverso:",
    de: "Ganz unten befindet sich Ihre Fortschrittsleiste. Beachten Sie, dass jede ihrer Farben etwas anderes bedeutet:",
    sl: "Na dnu je vaš trak za napredek. Opazite, da vsaka barva pomeni nekaj drugega:",
  },
  tutorialLessonNestedListFirstPointText: {
    pl: "zielony - dobrze rozwiązane zadanie",
    cs: "zelený - dobře vyřešený úkol",
    sk: "zelený - dobre vyriešená úloha",
    en: "green - well solved task",
    hu: "zöld - jól megoldott feladat",
    hr: "zeleno - zadatak riješen ispravno",
    lt: "žalia - gerai išspręstas uždavinys",
    it: "verde - compito risolto correttamente",
    de: "grün - gut gelöste Aufgabe",
    sl: "zeleno - dobro rešena naloga",
  },
  tutorialLessonNestedListSecondPointText: {
    pl: "czerwony - źle rozwiązane zadanie (wrócisz do niego pod koniec lekcji)",
    cs: "červený - špatně vyřešená úloha (vrátíte se k ní na konci lekce).",
    sk: "červený - zle vyriešená úloha (vrátite sa k nej na konci hodiny)",
    en: "red - incorrectly solved task (you will return to it at the end of the lesson)",
    hu: "piros - rosszul megoldott feladat (visszatér hozzá a lecke végén)",
    hr: "crveno - zadatak riješen neispravno (vratit ćeš se na njega na kraju lekcije)",
    lt: "raudona - neteisingai išspręsta užduotis (prie jos grįšite pamokos pabaigoje)",
    it: "rosso - compito risolto incorrettamente (ci tornerai alla fine della lezione)",
    de: "rot - falsch gelöste Aufgabe (Sie kehren am Ende der Lektion darauf zurück)",
    sl: "rdeča - napačno rešena naloga (na konec lekcije se boste vrnili k njej)",
  },
  tutorialLessonNestedListThirdPointText: {
    pl: "żółty - miejsce, w którym aktualnie się znajdujesz",
    cs: "žlutý - kde se právě nacházíte",
    sk: "žltý - kde sa práve nachádzate",
    en: "yellow - where you are now",
    hu: "sárga - hol tart most",
    hr: "žuto - mjesto na kojem se trenutno nalaziš",
    lt: "raudona - neteisingai išspręsta užduotis (prie jos grįšite pamokos pabaigoje)",
    it: "giallo - il punto in cui ti trovi attualmente",
    de: "gelb - wo Sie sich gerade befinden",
    sl: "rumena - kje se trenutno nahajate",
  },
  tutorialTenthPointText: {
    pl: "Na końcu każdego poziomu czeka na Ciebie test, który sprawdzi Twoją wiedzę z zakresu danego poziomu. Aby do niego przystąpić musisz mieć zaliczone wszystkie lekcje z tego poziomu.",
    cs: "Na konci každé úrovně vás čeká test, který prověří vaše znalosti dané úrovně. Abyste to mohli začít, musíte absolvovat všechny lekce z této úrovně.",
    sk: "Na konci každej úrovne vás čaká test, ktorý preverí vaše znalosti danej úrovne. Aby ste to mohli začať, musíte absolvovať všetky lekcie z tejto úrovne.e",
    en: "At the end of each level, a test awaits you to test your knowledge of that level. To start it, you must have completed all the lessons from this level.",
    hu: "Minden szint végén egy teszt vár Önre, amely ellenőrzi a tudását az adott szintről. Hogy hozzájuthasson, teljesítenie kell az összes leckét ezen a szinten.",
    hr: "Na kraju svake razine čeka te test koji će provjeriti tvoje znanje iz te razine. Da bi pristupio testu, moraš završiti sve lekcije te razine.",
    lt: "Kiekvieno lygio pabaigoje jūsų laukia testas, kuriame galėsite pasitikrinti savo žinias apie tą lygį. Norėdami jį pradėti, turite būti baigę visas šio lygio pamokas.",
    it: "Alla fine di ogni livello ti aspetta un test che verificherà la tua conoscenza di quel livello. Per iniziare, devi aver completato tutte le lezioni di quel livello.",
    de: "Am Ende jeder Stufe erwartet Sie ein Test, der Ihr Wissen über diese Stufe überprüft. Um den Test zu starten, müssen Sie alle Lektionen dieser Stufe abgeschlossen haben.",
    sl: "Na koncu vsake ravni vas čaka preizkus, da preverite svoje znanje o tej ravni. Za začetek morate opraviti vse lekcije te ravni.",
  },
  testLessonScreenText: {
    pl: "Sprawdź swoją wiedzę z poziomu",
    cs: "Otestujte si své znalosti od úrovně",
    sk: "Otestujte si svoje znalosti od úrovne",
    en: "Test your knowledge from level",
    hu: "Tesztelje tudását a szintről",
    hr: "Provjeri svoje znanje iz razine",
    lt: "Pasitikrinkite savo žinias apie",
    it: "Verifica la tua conoscenza dal livello",
    de: "Überprüfen Sie Ihr Wissen auf Stufe",
    sl: "Preverite svoje znanje iz ravni",
  },
  testLessonScreenButtonText: {
    pl: "wypełnij test",
    cs: "vyplňte test",
    sk: "vyplňte test",
    en: "fill out the test",
    hu: "töltse ki a tesztet",
    hr: "ispuni test",
    lt: "atlikti testą",
    it: "compila il test",
    de: "Test ausfüllen",
    sl: "izpolni test",
  },
  testMainHeader: {
    pl: "Test wiedzy - poziom",
    cs: "Test znalostí - úroveň",
    sk: "Vedomostný test - úroveň",
    en: "Knowledge test - level",
    hu: "Tudásteszt - szint",
    hr: "Test znanja - razina",
    lt: "Žinių testas - lygis",
    it: "Test di conoscenza - livello",
    de: "Wissenstest - Stufe",
    sl: "Test znanja - raven",
  },
  testRulesHeader: {
    pl: "Zasady testu wiedzy",
    cs: "Pravidla znalostního testu",
    sk: "Pravidlá znalostného testu",
    en: "Knowledge test rules",
    hu: "A tudásteszt szabályai",
    hr: "Pravila testa znanja",
    lt: "Žinių testo taisyklės",
    it: "Regole del test di conoscenza",
    de: "Regeln des Wissenstests",
    sl: "Pravila testa znanja",
  },
  testWelcome: {
    pl: "Cześć",
    cs: "Ahoj",
    sk: "Ahoj",
    en: "Hi",
    hu: "Szia",
    hr: "Bok",
    lt: "Sveiki",
    it: "Ciao",
    de: "Hallo",
    sl: "Živjo",
  },
  testDesc: {
    pl: "W tym miejscu możesz rozwiązać test wiedzy ze wszystkich poznanych słówek na poziomie {{level}}. Do każdego z zadań masz możliwość podejść tylko jeden raz. Pod koniec testu zostanie wyświetlony Twój wynik. Do testu możesz podchodzić wielokrotnie, by poprawić swój wynik.",
    cs: "Zde můžete vyřešit znalostní test všech slov, která jste se naučili na úrovni {{level}}. Každý úkol můžete udělat pouze jednou. Na konci testu se zobrazí váš výsledek. Test můžete absolvovat vícekrát, abyste zlepšili své skóre.",
    sk: "Tu môžete vyriešiť znalostný test všetkých slov, ktoré ste sa naučili na úrovni {{level}}. Každú úlohu môžete urobiť iba raz. Na konci testu sa zobrazí váš výsledok. Test môžete absolvovať viackrát, aby ste zlepšili svoje skóre.",
    en: "Here you can solve the knowledge test of all the words you have learned at level {{level}}. You can do each task only once. At the end of the test, your result will be displayed. You can take the test multiple times to improve your score.",
    hu: "Itt megoldhatja a tudástesztet az összes szóra, amelyet a {{level}} szinten tanult. Minden feladatot csak egyszer végezhet el. A teszt végén megjelenik az eredménye. A tesztet többször is elvégezheti, hogy javítsa a pontszámát.",
    hr: "Ovdje možeš riješiti test znanja iz svih naučenih riječi razine {{level}}. Za svaki zadatak imaš priliku pristupiti samo jednom. Na kraju testa bit će prikazan tvoj rezultat. Testu možeš pristupiti više puta kako bi poboljšao/la svoj rezultat.",
    lt: "Čia galite atlikti žinių testą, kuriame pasitikrinsite visą {{lygis}} išmoktą žodyną. Kiekvieną užduotį galite atlikti tik vieną kartą. Testo pabaigoje bus rodomas jūsų rezultatas. Norėdami pagerinti savo rezultatą, testą galite kartoti kelis kartus.",
    it: "Qui puoi risolvere il test di conoscenza di tutte le parole che hai imparato al livello {{level}}. Puoi fare ogni compito solo una volta. Alla fine del test verrà visualizzato il tuo risultato. Puoi ripetere il test più volte per migliorare il tuo punteggio.",
    de: "Hier können Sie den Wissenstest zu allen Wörtern machen, die Sie auf der Stufe {{level}} gelernt haben. Jede Aufgabe können Sie nur einmal machen. Am Ende des Tests wird Ihr Ergebnis angezeigt. Sie können den Test mehrmals wiederholen, um Ihre Punktzahl zu verbessern.",
    sl: "Tukaj lahko rešite test znanja vseh besed, ki ste se jih naučili na ravni {{level}}. Vsako nalogo lahko opravite samo enkrat. Na koncu testa bo prikazan vaš rezultat. Test lahko opravite večkrat, da izboljšate svojo oceno.",
  },
  testGoodLuck: {
    pl: "Powodzenia!",
    cs: "Hodně štěstí!",
    sk: "Veľa šťastia!",
    en: "Good luck!",
    hu: "Sok szerencsét!",
    hr: "Sretno!",
    lt: "Sėkmės!",
    it: "Buona fortuna!",
    de: "Viel Glück!",
    sl: "Srečno!",
  },
  testStartBtn: {
    pl: "Wypełnij test",
    cs: "Dokončit test",
    sk: "Dokončite test",
    en: "Complete the test",
    hu: "Teszt kitöltése",
    hr: "Ispuni test",
    lt: "Atlikite testą",
    it: "Completa il test",
    de: "Test ausfüllen",
    sl: "Izpolni test",
  },
  testResultHeader: {
    pl: "Wynik testu",
    cs: "Výsledek testu",
    sk: "Výsledok testu",
    en: "Test result",
    hu: "Teszteredmény",
    hr: "Rezultat testa",
    lt: "Testo rezultatai",
    it: "Risultato del test",
    de: "Testergebnis",
    sl: "Rezultat testa",
  },
  testActivitiesCompleted: {
    pl: "Ilość dobrze zrobionych zadań:",
    cs: "Počet dobře provedených úkolů:",
    sk: "Počet dobre vykonaných úloh:",
    en: "Number of tasks done well:",
    hu: "A feladatok száma:",
    hr: "Broj ispravno riješenih zadataka:",
    lt: "Gerai atliktų užduočių skaičius:",
    it: "Numero di compiti ben fatti:",
    de: "Anzahl der gut gemachten Aufgaben:",
    sl: "Število pravilno opravljenih nalog:",
  },
  testRepeatDesc: {
    pl: "Jeśli chcesz możesz podejść do testu jeszcze raz i poprawić swój wynik.",
    cs: "Chcete-li, můžete test absolvovat znovu a zlepšit svůj výsledek.",
    sk: "Ak chcete, môžete test absolvovať znova a zlepšiť svoj výsledok.",
    en: "If you want, you can take the test again and improve your score.",
    hu: "Ha szeretné, megismételheti a tesztet, és javíthatja az eredményét.",
    hr: "Ako želiš, možeš pristupiti testu ponovno i poboljšati svoj rezultat.",
    lt: "Jei norite, galite dar kartą atlikti testą ir pagerinti savo rezultatą.",
    it: "Se vuoi, puoi rifare il test per migliorare il tuo punteggio.",
    de: "Wenn Sie möchten, können Sie den Test erneut machen und Ihre Punktzahl verbessern.",
    sl: "Če želite, lahko test ponovno opravite in izboljšate svojo oceno.",
  },
  testBestScore: {
    pl: "Twój najlepszy wynik:",
    cs: "Váš nejlepší výsledek:",
    sk: "Váš najlepší výsledok:",
    en: "Your best score:",
    hu: "Legjobb eredménye:",
    hr: "Tvoj najbolji rezultat:",
    lt: "Jūsų geriausias rezultatas:",
    it: "Il tuo miglior punteggio:",
    de: "Ihre beste Punktzahl:",
    sl: "Vaš najboljši rezultat:",
  },
  testBackBtn: {
    pl: "Wszystkie lekcje",
    cs: "Všechny lekce",
    sk: "Všetky lekcie",
    en: "All lessons",
    hu: "Összes lecke",
    hr: "Sve lekcije",
    lt: "Visos pamokos",
    it: "Tutte le lezioni",
    de: "Alle Lektionen",
    sl: "Vse lekcije",
  },
  testRepeaBtn: {
    pl: "Wypełnij test ponownie",
    cs: "Udělat test znovu",
    sk: "Urobte si test znova",
    en: "Complete the test again",
    hu: "Teszt újbóli kitöltése",
    hr: "Ispuni test ponovno",
    lt: "Dar kartą atlikite testą",
    it: "Compila nuovamente il test",
    de: "Test erneut ausfüllen",
    sl: "Izpolni test ponovno",
  },
  askExpert: {
    pl: "Zapytaj eksperta",
    cs: "Zeptejte se odborníka",
    sk: "Spýtajte sa odborníka",
    en: "Ask the expert",
    hu: "Kérdezze a szakértőt",
    hr: "Pitaj stručnjaka",
    lt: "Paklauskite eksperto",
    it: "Chiedi all'esperto",
    de: "Experten fragen",
    sl: "Vprašaj strokovnjaka",
  },
  askExpertDescription: {
    pl: "Masz trudności z rozwiązaniem zadania? Zadaj pytanie naszemu ekspertowi. Odpowiedź pojawi się w skrzynce odbiorczej.",
    cs: "Máte potíže s řešením úkolu? Položte otázku našemu odborníkovi. Odpověď se objeví ve vaší schránce.",
    sk: "Máte ťažkosti s riešením úlohy? Položte otázku nášmu odborníkovi. Odpoveď sa objaví vo vašej schránke.",
    en: "Having difficulty solving an assignment? Ask your question to our expert. The answer will appear in your inbox.",
    hu: "Nehézséget okoz egy feladat megoldása? Tegye fel kérdését szakértőnknek. A válasz a postaládájába fog érkezni.",
    hr: "Imaš poteškoća s rješavanjem zadatka? Postavi pitanje našem stručnjaku. Odgovor će se pojaviti u sandučiću.",
    lt: "Sunkiai sprendžiate užduotį? Užduokite klausimą mūsų ekspertui. Atsakymą gausite į savo pašto dėžutę.",
    it: "Hai difficoltà a risolvere un compito? Fai una domanda al nostro esperto. La risposta apparirà nella tua casella di posta.",
    de: "Haben Sie Schwierigkeiten bei der Lösung einer Aufgabe? Stellen Sie Ihre Frage unserem Experten. Die Antwort erscheint in Ihrem Posteingang.",
    sl: "Imate težave z reševanjem naloge? Zastavite vprašanje našemu strokovnjaku. Odgovor se bo pojavil v vašem nabiralniku.",
  },
  askExpertSend: {
    pl: "Wyślij",
    cs: "Odeslat",
    sk: "Odoslať",
    en: "Send",
    hu: "Küldje el",
    hr: "Pošalji",
    lt: "Siųsti",
    it: "Invia",
    de: "Senden",
    sl: "Pošlji",
  },
  askExpertInboxHeader: {
    pl: "Pytanie do lekcji",
    cs: "Otázka k lekci",
    sk: "Otázka k lekcii",
    en: "Question for the lesson",
    hu: "Kérdés a leckéhez",
    hr: "Pitanje za lekciju",
    lt: "Klausimas pamokai",
    it: "Domanda per la lezione",
    de: "Frage zur Lektion",
    sl: "Vprašanje za lekcijo",
  },
  askExpertAnswer: {
    pl: "Odpowiedź eksperta",
    cs: "Odpověď odborníka",
    sk: "Odpoveď odborníka",
    en: "Answer from the expert",
    hu: "A szakértő válasza",
    hr: "Odgovor stručnjaka",
    lt: "Eksperto atsakymas",
    it: "Risposta dell'esperto",
    de: "Antwort des Experten",
    sl: "Odgovor strokovnjaka",
  },
  inbox: {
    pl: "Skrzynka odbiorcza",
    cs: "Doručená pošta",
    sk: "Doručená pošta",
    en: "Inbox",
    hu: "Bejövő postaláda",
    hr: "Sandučić",
    lt: "Pašto dėžutė",
    it: "Posta in arrivo",
    de: "Posteingang",
    sl: "Prejeto",
  },
  makeRateText: {
    pl: "Oceń kartę:",
    cs: "Hodnotící karta:",
    sk: "Hodnotiaca karta:",
    en: "Rate card:",
    hu: "Díjtáblázat:",
    hr: "Ocijeni kartu:",
    lt: "Įvertinkite kortelę:",
    it: "Valuta la carta:",
    de: "Karte bewerten:",
    sl: "Oceni kartico:",
  },
  pollHeading: {
    pl: "Kolejna lekcja za Tobą!",
    cs: "Další lekce je za tebou!",
    sk: "Ďalšia lekcia je za tebou!",
    en: "Another lesson has been completed!",
    hu: "Már túl vagy egy újabb leckén!",
    hr: "Još jedna lekcija iza tebe!",
    lt: "Baigta dar viena pamoka!",
    it: "Hai completato un'altra lezione!",
    de: "Eine weitere Lektion ist abgeschlossen!",
    sl: "Zaključena je še ena lekcija!",
  },
  pollFirstParagraph: {
    pl: "Chcemy poznać Twoją opinię, abyśmy mogli dalej rozwijać kurs i dopasowywać go do Twoich potrzeb. Prosimy o zaznaczenie odpowiedzi.",
    cs: "Chceme znát tvůj názor, abychom mohli kurz dále rozvíjet a přizpůsobovat ho tvým potřebám. Prosíme o označeníodpovědi.",
    sk: "Chceme poznať tvoj názor, aby sme mohli ďalejrozvíjať kurz a prispôsobovať ho tvojim potrebám. Prosíme o označenie odpovede.",
    en: "We are eager to hear your feedback, which enables us to continue refining and tailoring our course to meet your needs. Please, indicate your responses.",
    hu: "Szeretnénk megismerni a véleményed, hogy tovább fejleszthessük a tanfolyamot és igazíthassuk a te igényeidhez. Kérjük, jelöld be a válaszod.",
    hr: "Želimo saznati vaše mišljenje kako bismo mogli dalje razvijati tečaj i prilagoditi ga vašim potrebama. Molimo vas da označite odgovore.",
    lt: "Nekantraujame išgirsti jūsų atsiliepimus, kurie leidžia mums toliau tobulinti ir pritaikyti mūsų kursą jūsų poreikiams. Prašome nurodyti savo atsakymus.",
    it: "Siamo ansiosi di conoscere il tuo feedback, che ci consente di continuare a migliorare e adattare il nostro corso per soddisfare le tue esigenze. Per favore, indica le tue risposte.",
    de: "Wir möchten Ihre Meinung hören, damit wir unseren Kurs weiter verfeinern und auf Ihre Bedürfnisse zuschneiden können. Bitte geben Sie Ihre Antworten an.",
    sl: "Radi bi slišali vaše mnenje, da bomo lahko nadaljevali z izpopolnjevanjem in prilagajanjem tečaja vašim potrebam. Prosimo, označite svoje odgovore.",
  },
  pollSecondParagraph: {
    pl: "W skali od 1 do 5, gdzie 1 to całkowicie niezadowolony, a 5 to bardzo zadowolony, jak oceniasz nasząaplikację pod kątem:",
    cs: "Na stupnici od 1 do 5, kde 1 je zcela nespokojený a 5 je velmi spokojený, jak hodnotíš naši aplikaci z hlediska:",
    sk: "Na stupnici od 1 do 5, kde 1 je úplne nespokojný a 5 je veľmi spokojný, ako hodnotíš našu aplikáciu z hľadiska:",
    en: "On a scale from 1 to 5, where 1 represents complete dissatisfaction and 5 signifies great satisfaction, how would you rate our application in terms of:",
    hu: "1-től 5-ig terjedő skálán, ahol 1 teljesen elégedetlen, 5 pedig nagyon elégedett, hogyan értékelnéd az alkalmazásunkat a következő szempontok alapján:",
    hr: "Na skali od 1 do 5, gdje je 1 potpuno nezadovoljan, a 5 vrlo zadovoljan, kako ocjenjujete našu aplikaciju s obzirom na:",
    lt: "Kaip, skalėje nuo 1 iki 5, kur 1 reiškia visišką nepasitenkinimą, o 5 - didelį pasitenkinimą, įvertintumėte mūsų paraišką:",
    it: "Su una scala da 1 a 5, dove 1 rappresenta l'insoddisfazione completa e 5 rappresenta una grande soddisfazione, come valuteresti la nostra applicazione in termini di:",
    de: "Auf einer Skala von 1 bis 5, wobei 1 völlige Unzufriedenheit und 5 große Zufriedenheit bedeutet, wie würden Sie unsere Anwendung in Bezug auf bewerten:",
    sl: "Na lestvici od 1 do 5, kjer 1 pomeni popolno nezadovoljstvo in 5 veliko zadovoljstvo, kako bi ocenili našo aplikacijo glede na:",
  },
  pollFirstQuestion: {
    pl: "jakości treści edukacyjnych",
    cs: "Kvality vzdělávacíchobsahů",
    sk: "Kvality vzdelávacích obsahov",
    en: "The quality of educational content",
    hu: "Az oktatási tartalom minősége",
    hr: "kvalitetu edukativnih sadržaja",
    lt: "mokomojo turinio kokybę",
    it: "qualità dei contenuti educativi",
    de: "Qualität des Bildung Inhalts",
    sl: "kakovost izobraževalnih vsebin",
  },
  pollSecondQuestion: {
    pl: "jakości audio i zdjęć",
    cs: "Kvality audia a fotografií",
    sk: "Kvality audia a fotografií",
    en: "The quality of audio and visuals",
    hu: "Az audio és a képek minősége",
    hr: "kvalitetu audia i fotografija",
    lt: "garso ir vaizdo kokybę",
    it: "qualità dell'audio e delle immagini",
    de: "Qualität von Audio und Bildern",
    sl: "kakovost zvoka in slik",
  },
  pollThirdQuestion: {
    pl: "łatwości korzystania z aplikacji",
    cs: "Jednoduchosti používání aplikace",
    sk: "Lahkosti používania aplikácie",
    en: "The user-friendliness of the application",
    hu: "Az alkalmazás könnyű használata",
    hr: "lakoću korištenja aplikacije",
    lt: "Programos patogumą vartotojui",
    it: "facilità d'uso dell'applicazione",
    de: "Benutzerfreundlichkeit der Anwendung",
    sl: "uporabniku prijaznost aplikacije",
  },
  pollSecondHeading: {
    pl: "Ostatnie pytanie:",
    cs: "Poslední otázka",
    sk: "Posledná otázka",
    en: "Last question",
    hu: "Utolsó kérdés",
    hr: "Posljednje pitanje:",
    lt: "Paskutinis klausimas",
    it: "Ultima domanda:",
    de: "Letzte Frage:",
    sl: "Zadnje vprašanje:",
  },
  pollFourthQuestion: {
    pl: "Jakie zmiany w naszej platformie chcesz zobaczyć w przyszłości?",
    cs: "Jaké změny na naší platformě chceš vidět v budoucnosti?",
    sk: "Aké zmeny na našej platforme chceš vidieť v budúcnosti?",
    en: "What improvements would you like to see in our platform in the future?",
    hu: "Milyen változásokat szeretnél látni a platformunkon a jövőben?",
    hr: "Koje promjene na našoj platformi želite vidjeti u budućnosti?",
    lt: "Kokių patobulinimų mūsų platformoje norėtumėte ateityje?",
    it: "Quali miglioramenti vorresti vedere nella nostra piattaforma in futuro?",
    de: "Welche Verbesserungen möchten Sie in Zukunft auf unserer Plattform sehen?",
    sl: "Katere izboljšave bi želeli videti na naši platformi v prihodnosti?",
  },
  pollTextareaPlaceholder: {
    pl: "Kliknij w okienko, aby rozpocząć pisanie",
    cs: "Klepnutím na pole začněte psát",
    sk: "Kliknutím na políčko začnite písať",
    en: "Click on the box to start typing",
    hu: "Kattintson a mezőre a gépelés megkezdéséhez",
    hr: "Klikni u okvir da započneš pisati",
    lt: "Spustelėkite langelį ir pradėkite rašyti",
    it: "Clicca nella casella per iniziare a scrivere",
    de: "Klicken Sie in das Feld, um mit dem Schreiben zu beginnen",
    sl: "Kliknite v polje, da začnete tipkati",
  },
  pollThanksParagraph: {
    pl: "Dziękujemy za wypełnienie ankiety!",
    cs: "Děkujeme za vyplnění dotazníku!",
    sk: "Ďakujeme za vyplnenie dotazníka!",
    en: "Thank you for taking the time to complete our survey!",
    hu: "Köszönjük, hogy kitöltötted a felmérést!",
    hr: "Hvala vam što ste ispunili anketu!",
    lt: "Dėkojame, kad skyrėte laiko mūsų apklausai užpildyti!",
    it: "Grazie per aver completato il nostro sondaggio!",
    de: "Vielen Dank, dass Sie sich die Zeit genommen haben, unsere Umfrage auszufüllen!",
    sl: "Hvala, da ste si vzeli čas in izpolnili našo anketo!",
  },
  pollThanksSecondParagraph: {
    pl: "Twoja opinia jest dla nas bardzo cenna. Pomoże w dalszym rozwoju kursu.",
    cs: "Tvůj názor je pro nás velmi cenný pro další rozvoj kurzu.",
    sk: "Tvoj názor je pre nás veľmi cenný pri ďalšom rozvoji kurzu.",
    en: "Your feedback is incredibly valuable to us as we continue to enhance our course.",
    hu: "A véleményed nagyon értékes számunkra a tanfolyam továbbfejlesztésében.",
    hr: "Vaše mišljenje je za nas vrlo vrijedno. Pomoći će u daljnjem razvoju tečaja.",
    lt: "Jūsų atsiliepimai mums nepaprastai vertingi, nes toliau tobuliname savo kursą.",
    it: "Il tuo feedback è estremamente prezioso per noi mentre continuiamo a migliorare il nostro corso.",
    de: "Ihr Feedback ist für uns von unschätzbarem Wert, da wir unseren Kurs weiter verbessern.",
    sl: "Vaše mnenje nam je izjemno dragoceno, saj nadaljujemo z izboljševanjem našega tečaja.",
  },
  pollCancelButton: {
    pl: "Zapytaj później",
    cs: "Zeptejte se později",
    sk: "Opýtajte sa neskôr",
    en: "Ask later",
    hu: "Kérdezz később",
    hr: "Pitaj kasnije",
    lt: "Klauskite vėliau",
    it: "Chiedi più tardi",
    de: "Später fragen",
    sl: "Vprašaj kasneje",
  },
  pollNextButton: {
    pl: "Dalej",
    cs: "Další",
    sk: "Ďalšie",
    en: "Next",
    hu: "Következő",
    hr: "Dalje",
    lt: "Kitas",
    it: "Avanti",
    de: "Weiter",
    sl: "Naprej",
  },
  pollSendButton: {
    pl: "Wyślij",
    cs: "Poslat",
    sk: "Odoslať",
    en: "Send",
    hu: "Küld",
    hr: "Pošalji",
    lt: "Siųsti",
    it: "Invia",
    de: "Senden",
    sl: "Pošlji",
  },
  passwordRecoveryLoginText: {
    pl: "Nie pamiętam hasła?",
    cs: "Nepamatuji si heslo?",
    sk: "Nepamätám si heslo?",
    en: "I don't remember the password?",
    hu: "Nem emlékszem a jelszóra?",
    hr: "Ne sjećam se lozinke?",
    lt: "Neprisimenu slaptažodžio?",
    it: "Non ricordo la password?",
    de: "Passwort vergessen?",
    sl: "Ne spomnim se gesla?",
  },
  passwordRecoveryLoginLink: {
    pl: "Kliknij tutaj",
    cs: "Klikněte zde",
    sk: "Kliknite sem",
    en: "Click here",
    hu: "Kattintson ide",
    hr: "Klikni ovdje",
    lt: "Spustelėkite čia",
    it: "Clicca qui",
    de: "Hier klicken",
    sl: "Klikni tukaj",
  },
  passwordRecoveryHeader: {
    pl: "Nie pamiętam hasła",
    cs: "Nepamatuji si heslo",
    sk: "Nepamätám si heslo",
    en: "I don't remember the password",
    hu: "Nem emlékszem a jelszóra",
    hr: "Ne sjećam se lozinke",
    lt: "Neprisimenu slaptažodžio",
    it: "Non ricordo la password",
    de: "Passwort vergessen",
    sl: "Ne spomnim se gesla",
  },
  passwordRecoveryDescription: {
    pl: "Aby otrzymać link do zresetowania hasła, wprowadź adres e-mail powiązany z Twoim kontem.",
    cs: "Abyste obdrželi odkaz na resetování hesla, zadejte níže e-mailovou adresu spojenou s vaším účtem.",
    sk: "Ak chcete dostať odkaz na obnovenie hesla, zadajte nižšie e-mailovú adresu priradenú k vášmu kontu.",
    en: "To receive a link to reset your password, enter the email address associated with your account below.",
    hu: "Ha szeretne egy linket kapni jelszava visszaállításához, adja meg a fiókjához tartozó e-mail címet az alábbiakban.",
    hr: "Da biste primili link za resetiranje lozinke, unesite e-mail adresu povezanu s vašim računom.",
    lt: "Norėdami gauti slaptažodžio atstatymo nuorodą, toliau įveskite su savo paskyra susietą el. pašto adresą.",
    it: "Per ricevere un link per reimpostare la password, inserisci l'indirizzo email associato al tuo account qui sotto.",
    de: "Um einen Link zum Zurücksetzen Ihres Passworts zu erhalten, geben Sie die mit Ihrem Konto verknüpfte E-Mail-Adresse ein.",
    sl: "Če želite prejeti povezavo za ponastavitev gesla, vnesite e-poštni naslov, povezan z vašim računom.",
  },
  passwordRecoveryEmail: {
    pl: "Twój adres e-mail",
    cs: "Váš e-mail",
    sk: "Váš e-mail",
    en: "Your e-mail",
    hu: "Az Ön e-mail címe",
    hr: "Vaša e-mail adresa",
    lt: "Jūsų el. paštas",
    it: "Il tuo indirizzo e-mail",
    de: "Ihre E-Mail-Adresse",
    sl: "Vaš e-naslov",
  },
  passwordRecoveryButton: {
    pl: "Resetuj hasło",
    cs: "Resetovat heslo",
    sk: "Obnovenie hesla",
    en: "Reset password",
    hu: "Jelszó visszaállítása",
    hr: "Resetiraj lozinku",
    lt: "Atkurti slaptažodį",
    it: "Reimposta la password",
    de: "Passwort zurücksetzen",
    sl: "Ponastavi geslo",
  },
  passwordRecoveryButtonAfterSent: {
    pl: "Wiadomość e-mail została wysłana",
    cs: "E-mail byl odeslán",
    sk: "E-mail bol odoslaný",
    en: "The email has been sent",
    hu: "Az e-mail elküldésre került",
    hr: "E-mail poruka je poslana",
    lt: "El. laiškas buvo išsiųstas",
    it: "L'e-mail è stata inviata",
    de: "Die E-Mail wurde gesendet",
    sl: "E-pošta je bila poslana",
  },
  changePasswordHeader: {
    pl: "Wprowadź nowe hasło",
    cs: "Zadejte nové heslo",
    sk: "Zadajte nové heslo",
    en: "Enter a new password",
    hu: "Új jelszó megadása",
    hr: "Unesite novu lozinku",
    lt: "Įveskite naują slaptažodį",
    it: "Inserisci una nuova password",
    de: "Neues Passwort eingeben",
    sl: "Vnesite novo geslo",
  },
  changePasswordDescription: {
    pl: "Wprowadź nowe hasło tutaj",
    cs: "Zde zadejte své nové heslo",
    sk: "Zadajte svoje nové heslo tu",
    en: "Enter your new password here",
    hu: "Adja meg új jelszavát itt",
    hr: "Ovdje unesite novu lozinku",
    lt: "Įveskite naują slaptažodį čia",
    it: "Inserisci qui la tua nuova password",
    de: "Geben Sie hier Ihr neues Passwort ein",
    sl: "Vnesite svoje novo geslo tukaj",
  },
  changePasswordPasswordLabel: {
    pl: "Nowe hasło",
    cs: "Nové heslo",
    sk: "Nové heslo",
    en: "New password",
    hu: "Új jelszó",
    hr: "Nova lozinka",
    lt: "Naujas slaptažodis",
    it: "Nuova password",
    de: "Neues Passwort",
    sl: "Novo geslo",
  },
  changePasswordRepeatPasswordLabel: {
    pl: "Potwierdź hasło",
    cs: "Potvrďte heslo",
    sk: "Potvrdenie hesla",
    en: "Confirm password",
    hu: "Jelszó megerősítése",
    hr: "Potvrdi lozinku",
    lt: "Patvirtinti slaptažodį",
    it: "Conferma la password",
    de: "Passwort bestätigen",
    sl: "Potrdi geslo",
  },
  changePasswordButton: {
    pl: "Resetowanie hasła",
    cs: "Resetovat heslo",
    sk: "Obnovenie hesla",
    en: "Reset password",
    hu: "Jelszó visszaállítása",
    hr: "Resetiranje lozinke",
    lt: "Iš naujo nustatyti slaptažodį",
    it: "Reimposta la password",
    de: "Passwort zurücksetzen",
    sl: "Ponastavi geslo",
  },
  certificateHeader: {
    pl: "CERTYFIKAT",
    cs: "CERTIFIKÁT",
    sk: "CERTIFIKÁT",
    en: "CERTIFICATE",
    hu: "TANÚSÍTVÁNY",
    hr: "CERTIFIKAT",
    lt: "SERTIFIKATAS",
    it: "CERTIFICATO",
    de: "ZERTIFIKAT",
    sl: "CERTIFIKAT",
  },
  certificateNotAllowedHeader: {
    pl: "UKOŃCZ WSZYSTKIE LEKCJE, ABY OTRZYMAĆ CERTYFIKAT",
    cs: "DOKONČI VŠECHNY LEKCE, ABYS OBDRŽEL CERTIFIKÁT",
    sk: "DOKONČI VŠETKY LEKCIE, ABY SI ZÍSKAL CERTIFIKÁT",
    en: "COMPLETE ALL LESSONS TO GET A CERTIFICATE",
    hu: "TÖLTSD KI AZ ÖSSZES LECKÉT, HOGY MEGKAPD A TANÚSÍTVÁNYT",
    hr: "ZAVRŠI SVE LEKCIJE DA BI DOBIO/A CERTIFIKAT",
    lt: "ATLIKITE VISAS PAMOKAS, KAD GAUTUMĖTE SERTIFIKATĄ",
    it: "COMPLETA TUTTE LE LEZIONI PER OTTENERE UN CERTIFICATO",
    de: "SCHLIESSEN SIE ALLE LEKTIONEN AB, UM EIN ZERTIFIKAT ZU ERHALTEN",
    sl: "DOKONČAJ VSE LEKCIJE, DA PREJMEŠ CERTIFIKAT",
  },
  certificateDescription: {
    pl: "Aby otrzymać certyfikat, wpisz swoje imię i nazwisko",
    cs: "Chceš-li získat certifikát, zadej své jméno a příjmení",
    sk: "Ak chceš získať certifikát, zadaj svoje meno a priezvisko",
    en: "To receive a certificate, enter your name and surname",
    hu: "Hogy megkapd a tanúsítványt, add meg a neved és a vezetékneved",
    hr: "Da biste primili certifikat, unesite svoje ime i prezime",
    lt: "Norėdami gauti pažymėjimą, įveskite savo vardą ir pavardę",
    it: "Per ricevere un certificato, inserisci il tuo nome e cognome",
    de: "Um ein Zertifikat zu erhalten, geben Sie Ihren Namen und Nachnamen ein",
    sl: "Za pridobitev certifikata vnesite svoje ime in priimek",
  },
  certificateName: {
    pl: "Imię i nazwisko",
    cs: "Jméno a příjmení",
    sk: "Meno a priezvisko",
    en: "Name and surname",
    hu: "Név és vezetéknév",
    hr: "Ime i prezime",
    lt: "Vardas ir pavardė",
    it: "Nome e cognome",
    de: "Name und Nachname",
    sl: "Ime in priimek",
  },
  certificateSend: {
    pl: "Wyślij",
    cs: "Odeslat",
    sk: "Odoslať",
    en: "Send",
    hu: "Küldés",
    hr: "Pošalji",
    lt: "Siųsti",
    it: "Invia",
    de: "Senden",
    sl: "Pošlji",
  },
  certificateWarning: {
    pl: "Upewnij się, że wpisujesz prawidłowe dane!",
    cs: "Ujisti se, že zadáváš správné údaje. Není možnost pozdějšízměny.",
    sk: "Uistite sa, že zadávate správne údaje. Neskôr nie je možné ich zmeniť.",
    en: "Make sure you enter the correct data. There is no possibility of later change.",
    hu: "Győződj meg róla, hogy helyes adatokat adtál meg. Később nem lehetséges módosítani.",
    hr: "Provjerite jesu li podaci točni!",
    lt: "Įsitikinkite, kad įvedėte teisingus duomenis. Vėliau jų pakeisti nėra galimybės.",
    it: "Assicurati di inserire i dati corretti. Non è possibile modificarli in seguito.",
    de: "Stellen Sie sicher, dass Sie die richtigen Daten eingeben!",
    sl: "Prepričajte se, da ste vnesli pravilne podatke!",
  },
  certificateSendInfo: {
    pl: "Certyfikat zostanie wysłany do 3 dni roboczych na twój adres mailowy. Pobierzesz go też w tym miejscu.",
    cs: "Certifikát bude odeslán do 3 pracovních dnů na tvou emailovou adresu. Můžeš si ho také stáhnout na tomto místě.",
    sk: "Certifikát bude odoslaný do 3 pracovných dní na tvoju emailovú adresu. Môžeš ho stiahnuť aj na tomto mieste.",
    en: "The certificate will be sent within 3 working days to your email address. You can also download it here.",
    hu: "A tanúsítványt 3 munkanapon belül elküldjük az e-mail címedre. Letöltheted innen is.",
    hr: "Certifikat će biti poslan unutar 3 radna dana na vašu e-mail adresu. Također ga možete preuzeti ovdje.",
    lt: "Sertifikatas bus išsiųstas per 3 darbo dienas jūsų el. pašto adresu. Jį taip pat galite atsisiųsti čia.",
    it: "Il certificato verrà inviato entro 3 giorni lavorativi al tuo indirizzo e-mail. Puoi anche scaricarlo da qui.",
    de: "Das Zertifikat wird innerhalb von 3 Werktagen an Ihre E-Mail-Adresse gesendet. Sie können es auch hier herunterladen.",
    sl: "Certifikat bo poslan v treh delovnih dneh na vaš e-poštni naslov. Prav tako ga lahko prenesete tukaj.",
  },
  certificateDownload: {
    pl: "Pobierz",
    cs: "Stáhnout",
    sk: "Stiahnuť",
    en: "Download",
    hu: "Letöltés",
    hr: "Preuzmi",
    lt: "Atsisiųsti",
    it: "Scarica",
    de: "Herunterladen",
    sl: "Prenesi",
  },
  task: {
    pl: "Zadanie",
    cs: "Úkol",
    sk: "Úloha",
    en: "Task",
    hu: "Feladat",
    hr: "Zadatak",
    lt: "Užduotis",
    it: "Compito",
    de: "Aufgabe",
    sl: "Naloga",
  },
  taskActivityTitle: {
    pl: "Sprawdźmy Twoją wiedzę z ostatnich lekcji.",
    cs: "Zkontrolujme si tvé znalosti z posledních lekcí.",
    sk: "Skontrolujme si tvoje znalosti z posledných lekcií.",
    en: "Let's check your knowledge from the last lessons.",
    hu: "Nézzük át a legutóbbi leckékben tanultakat.",
    hr: "Provjerimo tvoje znanje iz posljednjih lekcija.",
    lt: "Pasitikrinkime savo žinias iš paskutinių pamokų.",
    it: "Verifichiamo la tua conoscenza dalle ultime lezioni.",
    de: "Lassen Sie uns Ihr Wissen aus den letzten Lektionen überprüfen.",
    sl: "Preverimo tvoje znanje iz zadnjih lekcij.",
  },
  taskActivityDescription: {
    pl: "Nasz ekspert językowy sprawdzi twoją znajomość języka i wskaże, co możesz jeszcze poprawić.",
    cs: "Náš jazykový expert zkontroluje tvé znalosti a ukáže ti, co můžeš ještě zlepšit.",
    sk: "Náš jazykový expert skontroluje tvoje znalosti a ukáže ti, čo môžeš ešte zlepšiť.",
    en: "Our language expert will check your knowledge and point out what you can improve.",
    hu: "Nyelvi szakértőnk átnézi a tudásod és megmutatja, mit tudsz még fejleszteni.",
    hr: "Naš jezični stručnjak provjerit će tvoje znanje jezika i ukazati na što još možeš poboljšati.",
    lt: "Mūsų kalbos ekspertas patikrins jūsų žinias ir nurodys, ką galite patobulinti.",
    it: "Il nostro esperto linguistico controllerà la tua conoscenza e ti indicherà cosa puoi ancora migliorare.",
    de: "Unser Sprachexperte wird Ihr Wissen überprüfen und Ihnen mitteilen, was Sie noch verbessern können.",
    sl: "Naš jezikovni strokovnjak bo preveril tvoje znanje in opozoril, kaj lahko še izboljšaš.",
  },
  taskActivitySecondDescription: {
    pl: "Wiadomość zwrotna pojawi się w skrzynce odbiorczej.",
    cs: "Zpětná vazba se objeví v tvé schránce.",
    sk: "Spätná väzba sa objaví v tvojej schránke.",
    en: "The feedback will appear in your inbox.",
    hu: "A visszajelzés megjelenik a beérkezett üzeneteid között.",
    hr: "Povratna poruka pojavit će se u sandučiću.",
    lt: "Grįžtamasis ryšys pasirodys jūsų pašto dėžutėje.",
    it: "Il feedback apparirà nella tua casella di posta.",
    de: "Das Feedback wird in Ihrem Posteingang erscheinen.",
    sl: "Povratna informacija se bo pojavila v tvojem nabiralniku.",
  },
  taskActivityName: {
    pl: "Zadanie:",
    cs: "Úkol:",
    sk: "Úloha:",
    en: "Task:",
    hu: "Feladat:",
    hr: "Zadatak:",
    lt: "Užduotis:",
    it: "Compito:",
    de: "Aufgabe:",
    sl: "Naloga:",
  },
  taskActivityDone: {
    pl: "Zrobione",
    cs: "Hotovo",
    sk: "Hotovo",
    en: "Done",
    hu: "Kész",
    hr: "Gotovo",
    lt: "Atlikta",
    it: "Fatto",
    de: "Erledigt",
    sl: "Opravljeno",
  },
  taskActivityExample: {
    pl: "Przykładowa odpowiedź",
    cs: "Příklad odpovědi",
    sk: "Príklad odpovede",
    en: "Example answer",
    hu: "Példaválasz",
    hr: "Primjer odgovora",
    lt: "Atsakymo pavyzdys",
    it: "Risposta di esempio",
    de: "Beispielantwort",
    sl: "Primer odgovora",
  },
  taskActivitySkip: {
    pl: "Pomiń",
    cs: "Přeskočit",
    sk: "Preskočiť",
    en: "Skip",
    hu: "Kihagy",
    hr: "Preskoči",
    lt: "Praleisti",
    it: "Salta",
    de: "Überspringen",
    sl: "Preskoči",
  },
  taskActivitySend: {
    pl: "Wyślij",
    cs: "Odeslat",
    sk: "Odoslať",
    en: "Send",
    hu: "Küldés",
    hr: "Pošalji",
    lt: "Siųsti",
    it: "Invia",
    de: "Senden",
    sl: "Pošlji",
  },
  taskActivityMessageSent: {
    pl: "Wiadomość pomyślnie wysłano",
    cs: "Zpráva byla úspěšně odeslána",
    sk: "Správa bola úspešne odoslaná",
    en: "Message sent successfully",
    hu: "Az üzenet sikeresen elküldve",
    hr: "Poruka uspješno poslana",
    lt: "Pranešimas sėkmingai išsiųstas",
    it: "Messaggio inviato con successo",
    de: "Nachricht erfolgreich gesendet",
    sl: "Sporočilo uspešno poslano",
  },
  taskActivityMessageSentDescription: {
    pl: "Odpowiedź pojawi się w skrzynce odbiorczej.",
    cs: "Odpověď se objeví v tvé schránce.",
    sk: "Odpoveď sa objaví v tvojej schránke.",
    en: "The answer will appear in your inbox.",
    hu: "A válasz megjelenik a beérkezett üzeneteid között.",
    hr: "Odgovor će se pojaviti u sandučiću.",
    lt: "Atsakymas pasirodys jūsų pašto dėžutėje.",
    it: "La risposta apparirà nella tua casella di posta.",
    de: "Die Antwort wird in Ihrem Posteingang erscheinen.",
    sl: "Odgovor se bo pojavil v tvojem nabiralniku.",
  },
  expert: {
    pl: "Ekspert",
    cs: "Expert",
    sk: "Expert",
    en: "Expert",
    hu: "Szakértő",
    hr: "Stručnjak",
    lt: "Ekspertas",
    it: "Esperto",
    de: "Experte",
    sl: "Strokovnjak",
  },
  descriptiveTask: {
    pl: "Zadanie opisowe",
    cs: "Popisný úkol",
    sk: "Popisná úloha",
    en: "Descriptive task",
    hu: "Leíró feladat",
    hr: "Opisni zadatak",
    lt: "Aprašomoji užduotis",
    it: "Compito descrittivo",
    de: "Beschreibende Aufgabe",
    sl: "Opisna naloga",
  },
  inboxHeader: {
    pl: "Skrzynka odbiorcza",
    cs: "Doručená pošta",
    sk: "Doručená pošta",
    en: "Inbox",
    hu: "Beérkezett üzenetek",
    hr: "Sandučić",
    lt: "Gauto pašto dėžutė",
    it: "Posta in arrivo",
    de: "Posteingang",
    sl: "Prejeto",
  },
  inboxSender: {
    pl: "Nadawca",
    cs: "Odesílatel",
    sk: "Odosielateľ",
    en: "Sender",
    hu: "Küldő",
    hr: "Pošiljatelj",
    lt: "Siuntėjas",
    it: "Mittente",
    de: "Absender",
    sl: "Pošiljatelj",
  },
  inboxSubject: {
    pl: "Temat",
    cs: "Předmět",
    sk: "Predmet",
    en: "Subject",
    hu: "Tárgy",
    hr: "Predmet",
    lt: "Tema",
    it: "Oggetto",
    de: "Betreff",
    sl: "Zadeva",
  },
  inboxDate: {
    pl: "Data",
    cs: "Datum",
    sk: "Dátum",
    en: "Date",
    hu: "Dátum",
    hr: "Datum",
    lt: "Data",
    it: "Data",
    de: "Datum",
    sl: "Datum",
  },
  inboxResponse: {
    pl: "Odpowiedź",
    cs: "Odpověď",
    sk: "Odpoveď",
    en: "Response",
    hu: "Válasz",
    hr: "Odgovor",
    lt: "Atsakymas",
    it: "Risposta",
    de: "Antwort",
    sl: "Odgovor",
  },
  inboxTypeMessage: {
    pl: "wpisz wiadomość...",
    cs: "napiš zprávu...",
    sk: "napíš správu...",
    en: "type message...",
    hu: "írd be az üzenetet...",
    hr: "upiši poruku…",
    lt: "tipo pranešimas...",
    it: "digita il messaggio...",
    de: "Nachricht eingeben...",
    sl: "vnesi sporočilo...",
  },
  inboxSend: {
    pl: "Wyślij",
    cs: "Odeslat",
    sk: "Odoslať",
    en: "Send",
    hu: "Küldés",
    hr: "Pošalji",
    lt: "Siųsti",
    it: "Invia",
    de: "Senden",
    sl: "Pošlji",
  },
  inboxAskExpert: {
    pl: "Zapyaj eksperta",
    cs: "Zeptej se experta",
    sk: "Opýtaj sa experta",
    en: "Ask expert",
    hu: "Kérdezd meg a szakértőt",
    hr: "Pitaj stručnjaka",
    lt: "Paklauskite eksperto",
    it: "Chiedi all'esperto",
    de: "Experten fragen",
    sl: "Vprašaj strokovnjaka",
  },
  inboxTask: {
    pl: "Zadanie",
    cs: "Úkol",
    sk: "Úloha",
    en: "Task",
    hu: "Feladat",
    hr: "Zadatak",
    lt: "Užduotis",
    it: "Compito",
    de: "Aufgabe",
    sl: "Naloga",
  },
  transcriptionTrigger: {
    pl: "Kliknij, aby przeczytać słowo.",
    cs: "Klikněte pro přečtení slova.",
    sk: "Kliknutím si prečítajte slovo.",
    en: "Click to read the word.",
    hu: "Kattints a szó elolvasásához.",
    ro: "Click pentru a citi cuvântul.",
    lt: "Spustelėkite norėdami perskaityti žodį.",
    it: "Clicca per leggere la parola.",
    de: "Klicken Sie, um das Wort zu lesen.",
    sl: "Kliknite za branje besede.",
  },
  lesson: {
    pl: "Lekcja",
    cs: "Lekce",
    sk: "Lekcia",
    en: "Lesson",
    hu: "Lecke",
    hr: "Lekcija",
    lt: "Pamokos",
    ro: "Lecția",
    bg: "Урок",
    lv: "Nodarbība",
    sl: "Lekcija",
  },
  downloadMessage: {
    pl: "Rozpoczęło się pobieranie certyfikatu",
    en: "The certificate download has started",
    cs: "Stahování certifikátu bylo zahájeno",
    sk: "Začalo sa sťahovanie certifikátu",
    hu: "A tanúsítvány letöltése megkezdődött",
    hr: "Preuzimanje certifikata je započelo",
    lv: "Sertifikāta lejupielāde ir sākusies",
    it: "Il download del certificato è iniziato",
    de: "Der Zertifikat-Download hat begonnen",
    lt: "Pradėtas sertifikato atsisiuntimas",  // Lithuanian
    sl: "Prenos certifikata se je začel",
  },
  downloadHelp: {
    pl: "Jeśli plik nie pobiera się automatycznie, kliknij tutaj.",
    en: "If the file does not download automatically, click here.",
    cs: "Pokud se soubor nestahuje automaticky, klikněte sem.",
    sk: "Ak sa súbor nesťahuje automaticky, kliknite sem.",
    hu: "Ha a fájl nem töltődik le automatikusan, kattintson ide.",
    hr: "Ako se datoteka ne preuzme automatski, kliknite ovdje.",
    lv: "Ja fails automātiski neielādējas, noklikšķiniet šeit.",
    it: "Se il file non si scarica automaticamente, clicca qui.",
    de: "Wenn die Datei nicht automatisch heruntergeladen wird, klicken Sie hier.",
    lt: "Jei failas neatsisiunčia automatiškai, spustelėkite čia.",  // Lithuanian
    sl: "Če se datoteka ne prenese samodejno, kliknite tukaj.",
  },
  certificateBenefitsHeader: {
    pl: "Zalety posiadania certyfikatów językowych",
    en: "Benefits of Having Language Certificates",
    cs: "Výhody vlastnictví jazykových certifikátů",
    sk: "Výhody držania jazykových certifikátov",
    hu: "A nyelvi tanúsítványok előnyei",
    hr: "Prednosti posjedovanja jezičnih certifikata",
    lv: "Valodu sertifikātu priekšrocības",
    it: "Vantaggi del possesso di certificati linguistici",
    de: "Vorteile des Besitzes von Sprachzertifikaten",
    lt: "Kalbos sertifikatų turėjimo privalumai",  // Lithuanian
    sl: "Prednosti jezikovnih certifikatov"
  },
  benefitConfirmationOfSkillsPart1: {
    pl: "Potwierdzenie umiejętności:",
    en: "Confirmation of skills:",
    cs: "Potvrzení dovedností:",
    sk: "Potvrdenie zručností:",
    hu: "Képességek megerősítése:",
    hr: "Potvrda vještina:",
    lv: "Prasmju apstiprināšana:",
    it: "Conferma delle competenze:",
    de: "Bestätigung der Fähigkeiten:",
    lt: "Įgūdžių patvirtinimas:",  // Lithuanian
    sl: "Potrditev znanja:"
  },
  benefitConfirmationOfSkills: {
    pl: "Certyfikat jest formalnym dowodem na Twoją znajomość języka, co może być nieocenione podczas poszukiwania pracy lub aplikowania na studia.",
    en: "The certificate is a formal proof of your language proficiency, which can be invaluable when job hunting or applying for studies.",
    cs: "Certifikát je formálním důkazem vaší jazykové způsobilosti, což může být neocenitelné při hledání práce nebo při podávání přihlášek na studia.",
    sk: "Certifikát je formálnym dôkazom vašej jazykovej zdatnosti, čo môže byť neoceniteľné pri hľadaní práce alebo pri podávaní prihlášok na štúdium.",
    hu: "A tanúsítvány hivatalos bizonyítéka a nyelvi készségeidnek, ami felbecsülhetetlen értékű lehet az álláskeresésnél vagy tanulmányok jelentkezésénél.",
    hr: "Certifikat je formalni dokaz vaše jezične sposobnosti, što može biti neprocjenjivo pri traženju posla ili prijavljivanju na studij.",
    lv: "Sertifikāts ir formāls jūsu valodas prasmes pierādījums, kas var būt nenovērtējams, meklējot darbu vai piesakoties studijām.",
    it: "Il certificato è una prova formale della tua conoscenza linguistica, che può essere inestimabile quando si cerca lavoro o si fa domanda per gli studi.",
    de: "Das Zertifikat ist ein formaler Nachweis Ihrer Sprachkenntnisse, der bei der Jobsuche oder Bewerbung für ein Studium von unschätzbarem Wert sein kann.",
    lt: "Sertifikatas yra oficialus jūsų kalbos įgūdžių įrodymas, kuris gali būti neįkainojamas ieškant darbo ar stojant į studijas.",  // Lithuanian
    sl: "Certifikat je uraden dokaz o vašem znanju jezika, kar je lahko neprecenljivo pri iskanju zaposlitve ali prijavi na študij."
  },
  benefitIncreasedCompetitivenessPart1: {
    pl: "Zwiększenie konkurencyjności:",
    en: "Increased competitiveness:",
    cs: "Zvýšení konkurenceschopnosti:",
    sk: "Zvýšenie konkurencieschopnosti:",
    hu: "Növekvő versenyképesség:",
    hr: "Povećana konkurentnost:",
    lv: "Palielināta konkurētspēja:",
    it: "Maggiore competitività:",
    de: "Erhöhte Wettbewerbsfähigkeit:",
    lt: "Padidėjęs konkurencingumas:",  // Lithuanian
    sl: "Povečana konkurenčnost:"
  },
  benefitIncreasedCompetitiveness: {
    pl: "Posiadanie certyfikatu językowego może wyróżnić Cię spośród innych kandydatów na rynku pracy.",
    en: "Having a language certificate can set you apart from other candidates in the job market.",
    cs: "Držení jazykového certifikátu vás může odlišit od ostatních kandidátů na trhu práce.",
    sk: "Držanie jazykového certifikátu vás môže odlíšiť od ostatných kandidátov na trhu práce.",
    hu: "Egy nyelvi tanúsítvány birtoklása megkülönböztethet a munkaerőpiacon más jelöltektől.",
    hr: "Posjedovanje jezičnog certifikata može vas izdvojiti među ostalim kandidatima na tržištu rada.",
    lv: "Valodas sertifikāta esamība var jūs izcelt starp citiem kandidātiem darba tirgū.",
    it: "Essere in possesso di un certificato linguistico può distinguerti dagli altri candidati nel mercato del lavoro.",
    de: "Der Besitz eines Sprachzertifikats kann Sie auf dem Arbeitsmarkt von anderen Kandidaten abheben.",
    lt: "Kalbos sertifikatas gali išskirti jus iš kitų kandidatų darbo rinkoje.",  // Lithuanian
    sl: "Imeti jezikovni certifikat vas lahko loči od drugih kandidatov na trgu dela."
  },
  benefitPersonalDevelopmentPart1: {
    pl: "Rozwój osobisty:",
    en: "Personal development:",
    cs: "Osobní rozvoj:",
    sk: "Osobný rozvoj:",
    hu: "Személyes fejlődés:",
    hr: "Osobni razvoj:",
    lv: "Personīgā attīstība:",
    it: "Sviluppo personale:",
    de: "Persönliche Entwicklung:",
    lt: "Asmeninis tobulėjimas:",  // Lithuanian
    sl: "Osebni razvoj:"
  },
  benefitPersonalDevelopment: {
    pl: "Uczenie się języków i zdobywanie certyfikatów może być satysfakcjonującym wyzwaniem, które rozwija Twoje umiejętności i pewność siebie.",
    en: "Learning languages and obtaining certificates can be a rewarding challenge that enhances your skills and confidence.",
    cs: "Učení se jazykům a získávání certifikátů může být obohacující výzvou, která zlepšuje vaše dovednosti a sebevědomí.",
    sk: "Učenie sa jazykov a získavanie certifikátov môže byť uspokojujúcou výzvou, ktorá zlepšuje vaše zručnosti a sebadôveru.",
    hu: "A nyelvek tanulása és a bizonyítványok megszerzése jutalmazó kihívás lehet, amely javítja képességeidet és önbizalmadat.",
    hr: "Učenje jezika i stjecanje certifikata može biti nagrađujući izazov koji poboljšava vaše vještine i samopouzdanje.",
    lv: "Valodu apguve un sertifikātu iegūšana var būt atalgojošs izaicinājums, kas uzlabo jūsu prasmes un pārliecību.",
    it: "Imparare le lingue e ottenere certificati può essere una sfida gratificante che migliora le tue capacità e la tua sicurezza.",
    de: "Das Erlernen von Sprachen und der Erwerb von Zertifikaten kann eine lohnende Herausforderung sein, die Ihre Fähigkeiten und Ihr Selbstvertrauen stärkt.",
    lt: "Kalbų mokymasis ir sertifikatų gavimas gali būti naudingas iššūkis, ugdantis jūsų įgūdžius ir pasitikėjimą savimi.",
    sl: "Učenje jezikov in pridobivanje certifikatov je lahko koristna izkušnja, ki izboljša vaše sposobnosti in samozavest."
  },
  clickHere: {
    pl: "kliknij tutaj",
    en: "click here",
    cs: "klikněte zde",
    sk: "kliknite sem",
    hu: "kattintson ide",
    hr: "kliknite ovdje",
    lv: "klikšķiniet šeit",
    it: "clicca qui",
    lt: 'spustelėkite čia',
    sl: "klikni tukaj"
  },
};
