import { useState } from "react";
import Store from "../../../../Store";
import MainModal from "../MainModal/MainModal";
import "./AlphabetModal.css";
import skEn from "./data/skEn.json";

export const NEW_LANG_MAP = {
  "sk-en": skEn,
};

function AlphabetModal(props) {
  const languagePair = props.langPair.replace(/[0-9]/g, "");
  const [alphabetData] = useState(
    Store.getAlphabetData(
      languagePair.split("-")[0],
      languagePair.split("-")[1]
    )
  );


  if (NEW_LANG_MAP[languagePair]) {
    return (
      <MainModal
        isModalOpen={props.isModalOpen}
        setIsModalOpen={props.setIsModalOpen}
        className="alphabet-modal"
      >
        <div
          dangerouslySetInnerHTML={{
            __html: NEW_LANG_MAP[languagePair][0].page,
          }}
        ></div>
      </MainModal>
    );
  }


  return (
    <MainModal
      isModalOpen={props.isModalOpen}
      setIsModalOpen={props.setIsModalOpen}
      className="alphabet-modal"
    >
      <div className="alphabet-modal__content">
        <h1 className="alphabet-modal__title">{alphabetData.alphabetTitle}</h1>
        {alphabetData.alphabetDescFirstLine && (
          <p className="alphabet-modal__text">
            {alphabetData.alphabetDescFirstLine}
          </p>
        )}
        {alphabetData.alphabetDescSecondLine && (
          <p className="alphabet-modal__text">
            {alphabetData.alphabetDescSecondLine}
          </p>
        )}
        {alphabetData.alphabetDescThirdLine && (
          <p className="alphabet-modal__text">
            {alphabetData.alphabetDescThirdLine}
          </p>
        )}
        <table className="alphabet-modal__table">
          <thead className="alphabet-modal__table-head">
            <tr className="alphabet-modal__table-row">
              <th className="alphabet-modal__table-header">
                {alphabetData.tableHeaderLetter}
              </th>
              <th className="alphabet-modal__table-header">
                {alphabetData.tableHeaderPronuncation}
              </th>
              {alphabetData.tableHeaderExample ? (
                <th className="alphabet-modal__table-header">
                  {alphabetData.tableHeaderExample}
                </th>
              ) : (
                ""
              )}
            </tr>
          </thead>
          <tbody className="alphabet-modal__table-body">
            {Object.keys(alphabetData.letter).map((key) => (
              <tr className="alphabet-modal__table-row" key={key}>
                <td
                  className="alphabet-modal__table-data"
                  dangerouslySetInnerHTML={{ __html: alphabetData.letter[key] }}
                />
                <td
                  className="alphabet-modal__table-data"
                  dangerouslySetInnerHTML={{
                    __html: alphabetData.pronunciation[key],
                  }}
                />
                {alphabetData.example[key] ? (
                  <td
                    className="alphabet-modal__table-data"
                    dangerouslySetInnerHTML={{
                      __html: alphabetData.example[key],
                    }}
                  />
                ) : (
                  ""
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </MainModal>
  );
}

export default AlphabetModal;
