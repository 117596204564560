export const languageDictionary = {
  pl: {
    pl: "Polski",
    hu: "Lengyel",
    sk: "Poľština",
    cs: "Polština",
    en: "Polish",
    ro: "Poloneză",
    de: "Polnisch",
    it: "Polacco",
    sl: "Poljščina"
  },

  en: {
    pl: "Angielski",
    hu: "Angol",
    sk: "Angličtina",
    cs: "Angličtina",
    en: "English",
    ro: "Engleză",
    de: "Englisch",
    it: "Inglese",
    sl: "Angleščina"
  },

  de: {
    pl: "Niemiecki",
    hu: "Német",
    sk: "Nemčina",
    cs: "Němčina",
    en: "German",
    ro: "Germană",
    de: "Deutsch",
    it: "Tedesco",
    sl: "Nemščina"
  },

  es: {
    pl: "Hiszpański",
    hu: "Spanyol",
    sk: "Španielčina",
    cs: "Španělština",
    en: "Spanish",
    ro: "Spaniolă",
    de: "Spanisch",
    it: "Spagnolo",
    sl: "Španščina"
  },

  fr: {
    pl: "Francuski",
    hu: "Francia",
    sk: "Francúzsky",
    cs: "Francouzština",
    en: "French",
    ro: "Franceză",
    de: "Französisch",
    it: "Francese",
    sl: "Francoščina"
  },

  it: {
    pl: "Włoski",
    hu: "Olasz",
    sk: "Taliančina",
    cs: "Italština",
    en: "Italian",
    ro: "Italiană",
    de: "Italienisch",
    it: "Italiano",
    sl: "Italijanščina"
  },

  no: {
    pl: "Norweski",
    hu: "Norvég",
    sk: "Nórsky",
    cs: "Norský",
    en: "Norwegian",
    ro: "Norvegian",
    de: "Norwegisch",
    it: "Norvegese",
    sl: "Norveščina"
  },

  sv: {
    pl: "Szwedzki",
    hu: "Svéd",
    sk: "Švédsky",
    cs: "Švédský",
    en: "Swedish",
    ro: "Suedez",
    de: "Schwedisch",
    it: "Svedese",
    sl: "Švedščina"
  },

  nl: {
    pl: "Niderlandzki",
    hu: "Holland",
    sk: "Holandčina",
    cs: "Nizozemština",
    en: "Dutch",
    ro: "Olandeză",
    de: "Niederländisch",
    it: "Olandese",
    sl: "Nizozemščina"
  },

  ru: {
    pl: "Rosyjski",
    hu: "Orosz",
    sk: "Ruský",
    cs: "Ruština",
    en: "Russian",
    ro: "Rusă",
    de: "Russisch",
    it: "Russo",
    sl: "Ruščina"
  },

  cs: {
    pl: "Czeski",
    hu: "Cseh",
    sk: "Český",
    cs: "Čeština",
    en: "Czech",
    ro: "Ceha",
    de: "Tschechisch",
    it: "Ceco",
    sl: "Češčina"
  },

  sk: {
    pl: "Słowacki",
    sk: "Slovenský",
    cs: "Slovák",
    en: "Slovak",
    hu: "Szlovák",
    ro: "Slovacă",
    de: "Slowakisch",
    it: "Slovacco",
    sl: "Slovaščina"
  },

  sk2: {
    pl: "Słowacki",
    sk: "Slovenský",
    cs: "Slovák",
    en: "Slovak",
    hu: "Szlovák",
    ro: "Slovacă",
    de: "Slowakisch",
    it: "Slovacco",
    sl: "Slovaščina"
  },

  pt: {
    pl: "Portugalski",
    sk: "Portugalčina",
    cs: "Portugalština",
    en: "Portuguese",
    hu: "Portugál",
    ro: "Portugheză",
    de: "Portugiesisch",
    it: "Portoghese",
    sl: "Portugalščina"
  },

  hu: {
    pl: "Węgierski",
    cs: "Maďarský",
    sk: "Maďarský",
    hu: "Magyar",
    en: "Hungarian",
    ro: "Maghiară",
    de: "Ungarisch",
    it: "Ungherese",
    sl: "Madžarščina"
  },

  sv: {
    pl: "Szwedzki",
    cs: "Svédský",
    sk: "Švédsky",
    hu: "Svéd",
    en: "Swedish",
    ro: "Suedez",
    de: "Schwedisch",
    it: "Svedese",
    sl: "Švedščina"
  },

  no: {
    pl: "Norweski",
    cs: "Norský",
    sk: "Nórsky",
    hu: "Norvég",
    en: "Norwegian",
    ro: "Norvegian",
    de: "Norwegisch",
    it: "Norvegese",
    sl: "Norveščina"
  },

  el: {
    pl: "Grecki",
    cs: "Recký",
    sk: "Grécky",
    hu: "Görög",
    en: "Greek",
    ro: "Greacă",
    de: "Griechisch",
    it: "Greco",
    sl: "Grščina"
  }
};