import React, { useState } from "react";
import CountUp, { startAnimation } from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";

const Number = styled.h1`
  color: ${Colors.yellow};
  text-align: center;
  font-size: 60px;
  font-weight: lighter;
  font-weight: bold;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    font-size: 50px;
  }
`;

const Description = styled.h2`
  color: ${Colors.mainColor};
  max-width: 200px;
  font-weight: lighter;
  font-size: 30px;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 25px;
  }
`;

const Flex2 = styled.div`
  display: flex;
  flex-direction: column;
`;

const Counter = ({ number, description }) => {
  return (
    <Flex2>
      <Number>
        <CountUp end={number} redraw={true} duration={4}>
          {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
              <span ref={countUpRef} />
            </VisibilitySensor>
          )}
        </CountUp>
      </Number>
      <Description>{description}</Description>
    </Flex2>
  );
};

export default Counter;
