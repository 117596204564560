import { createContext, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const defaultContext = {
  isVisible: false,
};

export const ConsultantHintContext = createContext(defaultContext);

export const ConsultantHintContextWrapper = ({ children }) => {
  let [searchParams] = useSearchParams();

  const isVisible = useMemo(
    () => searchParams.get("consultantHint") === "show",
    []
  );

  return (
    <ConsultantHintContext.Provider value={{ isVisible }}>
      {children}
    </ConsultantHintContext.Provider>
  );
};
