import React from "react";
import Hero from "../../components/newProdictPage/Hero/Hero";
import LabelInfo from "../../components/newProdictPage/LabelInfo/LabelInfo";
import Languages from "../../components/newProdictPage/Languages/Languages";
import Learning from "../../components/newProdictPage/Learning/Learning";
import SuccessStory from "../../components/newProdictPage/successStory/SuccessStory";

const NewProductPage = ({ data }) => {
  return (
    <div>
      <Hero data={data.hero} />
      <LabelInfo data={data.labelInfo} />
      <Learning data={data.learning} />
      <Languages data={data.languages} />
      <SuccessStory data={data.success} />
    </div>
  );
};

export default NewProductPage;
