import React, { useContext } from "react";
import styled from "styled-components";
import { LanguageContext } from "../../../../common/contexts/LanguageContext";
import { Colors } from "../../../colors/Colors";
import blueCloud from "../../../images/boxes/dymek_granat.png";
import { Cloud } from "../hero/Hero";

const Flex = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
`;

const TextWrapper = styled.p`
  max-width: 400px;
  margin: 20px;
  text-align: left;
  color: ${Colors.mainColor};
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  gap: 20px;
`;

const PhotoWraper = styled.div`
  height: 200px;
  overflow: hidden;

  @media (max-width: 600px) {
    height: 150px;
  }
`;

const Tittle = styled.h1`
  color: white;
  margin-top: -85px;
  max-width: 200px;
  text-align: center;
`;

const H1 = styled.h1`
  font-weight: lighter;
  font-size: 30px;
  max-width: 200px;
  margin: 0px;
  padding: 10px 0 10px 30px;
  text-align: center;
  color: white;
`;

const Languages = ({ data }) => {
  return (
    <div
      style={{ marginBottom: "50px", scrollMarginTop: "60px" }}
      id="avaiable"
      name="avaiable"
    >
      <div style={{ width: "200px", margin: "30px" }}>
        <Cloud img={blueCloud}>
          <H1>{data.title}</H1>
        </Cloud>
      </div>

      <Flex>
        <div>
          <TextWrapper>{data.text}</TextWrapper>
          <TextWrapper>{data.text1}</TextWrapper>
          <TextWrapper>{data.text2}</TextWrapper>
        </div>
        <Box>
          {data.languages.map(({ image, lang }) => {
            return (
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PhotoWraper>
                  <img
                    src={image}
                    alt="logo"
                    style={{ width: "auto", height: "100%" }}
                  />
                </PhotoWraper>
                <h4
                  style={{
                    textAlign: "center",
                    color: Colors.mainColor,
                    fontSize: "2.5rem",
                    fontWeight: "lighter",
                  }}
                >
                  {lang}
                </h4>
              </div>
            );
          })}
          {data.polish ? (
            <div
              style={{
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PhotoWraper>
                <img
                  src={data.polish.image}
                  alt="logo"
                  style={{ width: "auto", height: "80%", marginTop: "20px" }}
                />
              </PhotoWraper>
              <h4
                style={{
                  textAlign: "center",
                  color: Colors.mainColor,
                  fontSize: "2.5rem",
                  fontWeight: "lighter",
                }}
              >
                {data.polish.lang}
              </h4>
            </div>
          ) : (
            ""
          )}
        </Box>
      </Flex>
    </div>
  );
};

export default Languages;
