import { faEye, faEyeSlash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import api from '../../../api/api';
import Store from '../../../../Store';

import HomepageButton from '../../Buttons/HomepageButton/HomepageButton';
import './ChangePasswordHomeModal.css';

function ChangePasswordHomeModal(props) {
    const navigate = useNavigate();
    const [inputPassword, setInputPassword] = useState('');
    const [inputPasswordShow, setInputPasswordShow] = useState(false);
    const [inputPasswordRepeat, setInputPasswordRepeat] = useState('');
    const [inputPasswordRepeatShow, setInputPasswordRepeatShow] =
      useState(false);
  
    const [isPasswordMatch, setIsPasswordMatch] = useState(true);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const email = params.get('email');
  
    const login = async () => {
      const res = await api.post("/auth/login", {
        email,
        password: inputPassword,
      });
  
      const token = res.data.token;
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      Store.setToken(token);
  
      navigate("/platform");
    };
  
    const handleChangePassword = async (ev) => {
      ev.preventDefault();
      try {
          if (isPasswordMatch) {
              await api.post('/auth/change-password', {
                  password: inputPassword,
                  token,
              }).then(login);
          }
      } catch (err) {
          props.setError(err.message);
      }
    };
  
    const handlePasswordBlur = () => {
      setIsPasswordMatch(
        inputPasswordRepeat !== '' && inputPassword !== ''
          ? inputPassword === inputPasswordRepeat
          : true
      );
    };
  
    return (
        <div
            className={
                'change-password-home-modal ' +
                (props.isModalOpen ? ' change-password-home-modal--active ' : ' ')
            }
        >
            <div
                className="change-password-home-modal__closing-div"
                onClick={() => props.setIsModalOpen(false)}
            ></div>
            <div className={'change-password-home-modal-box '}>
                <div>
                    <div className="change-password-home-modal__buttons-wrapper">
                        <button
                            type="button"
                            className="change-password-home-modal__info-button"
                        >
                            ?
                            <div className="change-password-home-modal__info-text-wrapper">
                                <p className="change-password-home-modal__info-text">
                                    {Store.getText('contactText')}
                                    <span className="change-password-home-modal__span-block">
                                        {Store.getText('contactEmail')}
                                    </span>
                                </p>
                            </div>
                        </button>
                        <button
                            type="button"
                            className="change-password-home-modal__closing-button"
                            onClick={() => props.setIsModalOpen(false)}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                    </div>
                    <form
                        className="change-password-home-modal__form"
                        onSubmit={handleChangePassword}
                        autoComplete="on"
                    >
                        <h2 className="change-password-home-modal__form-title">
                        {Store.getText('changePasswordHeader')}
                        </h2>
                        <p className="change-password-home-modal__form-slogan change-password-home-modal__form-slogan--login">
                        {Store.getText('changePasswordDescription')}
                        </p>
                        <div className="change-password-home-modal__main-form-content">

                            <div className="change-password-home-modal__input-wrapper">
                                <label className="change-password-home-modal__input-label">
                                {Store.getText('changePasswordPasswordLabel')}
                                </label>
                                <div className="change-password-home-modal__password-wrapper">
                                    <input
                                        className="change-password-home-modal__input"
                                        type={inputPasswordShow ? "text" : "password"}
                                        onChange={(ev) => setInputPassword(ev.target.value)}
                                        value={inputPassword}
                                        onBlur={handlePasswordBlur}
                                    ></input>
                                    <button
                                        className="change-password-home-modal__password-icon-button"
                                        type="button"
                                        title={
                                            inputPasswordShow
                                                ? Store.getText("hidePassword")
                                                : Store.getText("showPassword")
                                        }
                                        onClick={() => setInputPasswordShow(!inputPasswordShow)}
                                    >
                                        {inputPasswordShow ? (
                                            <FontAwesomeIcon icon={faEyeSlash} />
                                        ) : (
                                            <FontAwesomeIcon icon={faEye} />

                                        )}
                                    </button>
                                </div>
                            </div>
                            <div></div>
                            <div className="change-password-home-modal__input-wrapper">
                                <label className="change-password-home-modal__input-label">
                                {Store.getText('changePasswordRepeatPasswordLabel')}
                                </label>
                                <div className="change-password-home-modal__password-wrapper">
                                    <input
                                        className="change-password-home-modal__input"
                                        type={inputPasswordRepeatShow ? "text" : "password"}
                                        onChange={(ev) => setInputPasswordRepeat(ev.target.value)}
                                        onBlur={handlePasswordBlur}
                                        value={inputPasswordRepeat}
                                    ></input>
                                    <button
                                        className="change-password-home-modal__password-icon-button"
                                        type="button"
                                        title={
                                            inputPasswordRepeatShow
                                                ? Store.getText("hidePassword")
                                                : Store.getText("showPassword")
                                        }
                                        onClick={() =>
                                            setInputPasswordRepeatShow(!inputPasswordRepeatShow)
                                        }
                                    >
                                        {inputPasswordRepeatShow ? (
                                            <FontAwesomeIcon icon={faEyeSlash} />
                                        ) : (
                                            <FontAwesomeIcon icon={faEye} />

                                        )}
                                    </button>
                                </div>
                                {isPasswordMatch ? (
                                    ""
                                ) : (
                                    <p className="change-password-home-modal__alert">
                                        {Store.getText("passwordsNotMatchAlert")}
                                    </p>
                                )}
                            </div>
                        </div>
                        <HomepageButton type="submit">
                        {Store.getText('changePasswordButton')}
                        </HomepageButton>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ChangePasswordHomeModal;
