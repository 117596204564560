import React from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";

const Wrapper = styled.div`
  background-color: ${Colors.lighterMainColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;

  @media (max-width: 1000px) {
    padding: 40px 0;
  }
`;

const Title = styled.h1`
  color: ${Colors.mainColor};
  font-weight: bold;
  text-align: left;
  font-size: 60px;
  display: flex;
  justify-content: left;
  gap: 10px;
  margin-bottom: 40px;

  @media (max-width: 600px) {
    font-size: 40px;
    margin-bottom: 40px;
    margin-top: 0px;
    width: fit-content;
  }
`;

const ConfigWrapper = styled.div`
  display: flex;
  gap: 80px;

  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 40px;
    padding: 0 20px;
  }
`;

const Element = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1000px) {
    flex-direction: ${(prev) => (prev.reverse ? "row" : "row-reverse")};
    gap: 20px;
  }
`;

const Image = styled.div`
  width: 250px;
  margin-bottom: 40px;

  @media (max-width: 1000px) {
    width: 100px;
    margin-bottom: 0px;
  }
`;

const TitleElement = styled.h3`
  color: ${Colors.mainColor};
  text-align: center;
  font-size: 18px !important;
  font-weight: 900;
  max-width: 250px;

  @media (max-width: 1000px) {
    text-align: left;
  }
`;

const Descrption = styled.p`
  max-width: 250px;
  color: ${Colors.mainColor};
  text-align: center;
  font-size: 16px !important;

  @media (max-width: 1000px) {
    text-align: left;
  }
`;

const Why = ({ data }) => {
  return (
    <Wrapper>
      <Title>{data.title}</Title>
      <ConfigWrapper>
        {data.config.map((c, i) => (
          <Element key={i} reverse={i % 2 === 0}>
            <Image>
              <img
                src={c.image}
                alt={c.title}
                style={{ width: "100%", height: "auto" }}
              />
            </Image>
            <div>
              <TitleElement>{c.title}</TitleElement>
              <Descrption>{c.description}</Descrption>
            </div>
          </Element>
        ))}
      </ConfigWrapper>
    </Wrapper>
  );
};

export default Why;
