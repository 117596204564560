const SlowIcon = ({scale = 1}) => (
  <svg
    style={{ transform: `scale(${scale})` }}
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.24592 18.948C3.88872 19.8043 4.70706 20.5367 5.65343 21.0956C6.88767 21.8247 8.25573 22.2084 9.61712 22.2084C9.65502 22.2084 9.69298 22.2081 9.73082 22.2075C11.4515 22.1805 13.1181 21.5163 14.38 20.432H20.1196C20.4551 20.432 20.7568 20.2277 20.8813 19.9162L21.0766 19.4277C21.0816 19.4152 21.0862 19.4028 21.0906 19.3901C21.6509 17.7625 21.843 15.983 21.646 14.244C21.52 13.1314 21.2357 12.0509 20.8097 11.0478C21.1723 10.5593 21.6442 10.157 22.1884 9.87572L20.878 4.73099C20.7662 4.29202 21.0314 3.84544 21.4705 3.7336C21.9095 3.62171 22.356 3.88711 22.4679 4.32609L23.5337 8.51039L24.4939 5.26168C24.6223 4.82724 25.0785 4.57913 25.5131 4.70748C25.9476 4.83583 26.1956 5.2922 26.0672 5.72663L24.9396 9.54213C26.6925 9.96114 28 11.5399 28 13.4194C28 14.3472 27.2451 15.1021 26.3172 15.1021H25.9885V17.0614C25.9885 21.048 22.7451 24.2915 18.7585 24.2915H0.820312C0.367281 24.2915 0 23.9242 0 23.4711V23.1997C0 21.1713 1.37752 19.4595 3.24592 18.948Z"
      fill="black"
    />
    <path
      d="M3.97121 10.6582C5.23138 8.05445 8.07874 6.27082 11.0566 6.2199C13.9586 6.16981 16.897 7.75799 18.5409 10.2649C20.129 12.6866 20.5178 15.9475 19.5614 18.7916H15.7709C15.863 18.6375 15.9484 18.4794 16.0266 18.3178C16.5899 17.1548 16.7724 15.7852 16.5405 14.4612C16.3032 13.1064 15.6447 11.8903 14.6862 11.037C13.6584 10.1222 12.317 9.61824 11.0086 9.65543C9.59314 9.69535 8.25658 10.366 7.43326 11.4493C6.67595 12.446 6.41394 13.7128 6.73239 14.8382C7.08583 16.0872 8.19079 17.0594 9.41946 17.2024C10.2794 17.3022 11.0869 16.9823 11.5259 16.3667C11.9024 15.8388 11.9396 15.056 11.6145 14.5054C11.3373 14.0359 10.8441 13.7938 10.2972 13.8572C9.84722 13.9094 9.52473 14.3165 9.5769 14.7666C9.59768 14.9454 9.67446 15.1041 9.78772 15.2271C9.91569 15.3661 9.80336 15.5939 9.61556 15.5735C9.61343 15.5733 9.61125 15.573 9.60911 15.5728C9.03161 15.5056 8.48572 15.0088 8.3111 14.3915C8.13485 13.7687 8.29902 13.0217 8.73958 12.442C9.25753 11.7605 10.1447 11.3212 11.0549 11.2954C11.085 11.2946 11.1152 11.2942 11.1452 11.2942C12.0126 11.2942 12.9008 11.6441 13.5954 12.2625C15.0059 13.5182 15.4164 15.814 14.5501 17.6027C13.7071 19.3437 11.76 20.535 9.7052 20.5673C7.4874 20.5999 5.24226 19.2833 4.11389 17.2853C3.001 15.3149 2.94632 12.7755 3.97121 10.6582Z"
      fill="black"
    />
  </svg>
);

export default SlowIcon;
