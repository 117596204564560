import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ReturnButton from "../../Buttons/ReturnButton/ReturnButton";
import MainModal from "../MainModal/MainModal";
import "./GrammarModal.css";
import { NEW_LANG_MAP } from "./GrammarModal";
import NewGrammarContent from "./NewGrammarContent/NewGrammarContent";

function NewGrammarModal(props) {
  const [choosenTopic, setChoosenTopic] = useState(null);
  const data = NEW_LANG_MAP[props.lang.replace(/[0-9]/g, "")];

  return (
    <MainModal
      isModalOpen={props.isModalOpen}
      setIsModalOpen={props.setIsModalOpen}
      className="grammar-modal"
    >
      {choosenTopic ? (
        <ReturnButton
          className="grammar-modal__return-button"
          onClick={() => setChoosenTopic("")}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </ReturnButton>
      ) : (
        ""
      )}
      <div className="grammar-modal__content">
        <NewGrammarContent
          choosenTopic={choosenTopic}
          setChoosenTopic={setChoosenTopic}
          data={data}
        />
      </div>
    </MainModal>
  );
}

export default NewGrammarModal;
