import React from "react";
import styled from "styled-components";
import start from "./images/gwiazdki.png";
import icon from "./images/icon.svg";
import { Colors } from "../../../colors/Colors";

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
`;

const Label = styled.div`
  display: inline-flex;
  padding: 15px 100px;
  justify-content: space-evenly;
  align-items: center;
  gap: 80px;
  border-radius: 100px;
  background: ${Colors.mainColor};
  max-width: 1000px;

  @media (max-width: 1100px) {
    gap: 0;
    padding: 15px 64px;
  }

  @media (max-width: 800px) {
    flex-wrap: wrap;
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    gap: 20px;
    border-radius: 60px;
    padding: 15px 40px;
    margin-top: 40px;
    margin-bottom: -40px;
  }
`;

const LabelElement = styled.div`
  color: white;
  font-weight: bold;
  text-align: center;
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;

  @media (max-width: 600px) {
    width: 250px;
  }
`;

const ItemUp = styled.div`
  font-weight: bold;
  font-size: 24px;
`;
const ItemDown = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
`;

const ImageWrapper = styled.div`
  width: 150px;
  overflow: hidden;
  display: flex;
`;

const LabelInfo = ({ data }) => {
  return (
    <Wrapper>
      <Label>
        <LabelElement>
          <ItemUp>{data.number}</ItemUp>
          <ItemDown>{data.numberDescription}</ItemDown>
        </LabelElement>
        <LabelElement>
          <ImageWrapper>
            <img
              src={start}
              alt="Stars"
              style={{ width: "100%", height: "auto" }}
            ></img>
          </ImageWrapper>
          <ItemDown>{data.itemTwo}</ItemDown>
          <a href="#success-stories">
            <img
              src={icon}
              alt="Chevron down"
              style={{
                margin: "10px 0px 0px 0px",
              }}
            />
          </a>
        </LabelElement>
        <LabelElement>
          <ItemUp>{data.itemThree.top}</ItemUp>
          <ItemDown>{data.itemThree.bottom}</ItemDown>
        </LabelElement>
      </Label>
    </Wrapper>
  );
};

export default LabelInfo;
