import { useEffect, useState } from 'react';
import api from '../../../platform/api/api';
import Store from '../../../Store';
import TaskLessonModal from './TaskLessonModal/TaskLessonModal';
import './TaskLessons.css';
const queryString = require('query-string');
function TaskLessons({ setError }) {
    const [languagePairs, setLanguagePairs] = useState([]);
    const [lessons, setLessons] = useState([]);

    const [languagePairId, setLanguagePairId] = useState();
    const [level, setLevel] = useState(1);

    const [lessonModalOpen, setLessonModalOpen] = useState(false);
    const [lessonToEdit, setLessonToEdit] = useState(null);

    useEffect(() => {
        loadLanguagePairs();
    }, []);

    const loadLanguagePairs = async () => {
        const res1 = await api.get(`/language-pair?version=1`);
        const res2 = await api.get(`/language-pair?version=2`);
        const sorted = [...res1.data, ...res2.data].sort((a, b) => a.languageFrom.language.localeCompare(b.languageFrom.language));
        setLanguagePairs(sorted)
        setLanguagePairId(sorted[0].id);
    };

    useEffect(() => {
        if (languagePairId && level) {
            loadLessons(languagePairId, level);
        }
    }, [languagePairId, level]);

    useEffect(() => {
        setLevel(1);
    }, [languagePairId]);

    const loadLessons = async (languagePairId, level) => {
        const qsObj = {
            languagePairId,
            level,
            type: 'task',
        };

        const res = await api.get(`/lesson?${queryString.stringify(qsObj)}`);
        const sorted = res.data.sort((lessonA, lessonB) => {
            const numberDiff = lessonA.number - lessonB.number;
            if (numberDiff !== 0) return numberDiff;

            const subNumberDiff = lessonA.subNumber - lessonB.subNumber;
            return subNumberDiff;
        });
        setLessons(sorted);
    };

    const closeModal = () => {
        setLessonModalOpen(false);
        setLessonToEdit(null);
    };

    const deleteLesson = async (id) => {
        await api.delete(`/lesson/${id}`);

        loadLessons(languagePairId, level);
    };

    const afterUpdate = () => {
        closeModal();

        loadLessons(languagePairId, level);
    };

    return (
        <div className="task-lessons">
            {lessonModalOpen && (
                <TaskLessonModal
                    closeModal={closeModal}
                    pairId={languagePairId}
                    setError={setError}
                    level={level}
                    afterUpdate={afterUpdate}
                    lesson={lessonToEdit}
                />
            )}
            <div>
                <select
                    value={languagePairId}
                    onChange={(e) => setLanguagePairId(e.target.value)}
                >
                    {languagePairs.map((pair) => (
                        <option value={pair.id}>
                            {pair.languageFrom.language}-
                            {pair.languageTo.language}
                        </option>
                    ))}
                </select>
                <select
                    value={level}
                    onChange={(e) => setLevel(parseInt(e.target.value))}
                >
                    <option value={1}>Poziom 1</option>
                    <option value={2}>Poziom 2</option>
                    <option value={3}>Poziom 3</option>
                </select>
            </div>
            <div>
                <button onClick={() => setLessonModalOpen(true)}>Dodaj</button>
            </div>

            <table className="task-lessons__table">
                <thead>
                    <tr>
                        <th>Lesson number</th>
                        <th>Translation</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {lessons.map((lesson) => (
                        <tr>
                            <td>{lesson.number}</td>
                            <td>{lesson.name}</td>
                            <td>
                                <button
                                    onClick={() => {
                                        setLessonModalOpen(true);
                                        setLessonToEdit(lesson);
                                    }}
                                >
                                    Edit
                                </button>

                                <button
                                    onClick={() => deleteLesson(lesson.id)}
                                    className="task-lesson-modal-button"
                                >
                                    Usuń
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default TaskLessons;
