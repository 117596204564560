import "../GrammarModal.css";

function NewGrammarContent(props) {
  const mainData = props.data.find(({ type }) => type === "main");

  const topics = props.data.filter(({ type }) => type === "subPage");

  if (!props.choosenTopic)
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: mainData.page }} />
        <div className="grammar__topics-wrapper">
          {topics.map(({ name }) => (
            <button
              className="grammar__topic-button"
              onClick={() => props.setChoosenTopic(name)}
              key={name}
            >
              {name}
            </button>
          ))}
        </div>
      </div>
    );

  const chosenData = topics.find(({ name }) => name === props.choosenTopic);

  return <div dangerouslySetInnerHTML={{ __html: chosenData.page }} />;
}

export default NewGrammarContent;
