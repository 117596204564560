import { faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Store from "../../../../Store";
import getLevelColor from "../../../common/functions/getLevelColor";
import playSound from "../../../common/functions/playSound";
import ActivityFeedback from "../../ActivityFeedback/ActivityFeedback";
import ActivityButton from "../../Buttons/ActivityButton/ActivityButton";
import "./ExpressionListeningActivity.css";
import SlowIcon from "../../../common/assets/SlowIcon";

function ExpressionListeningActivity({
  activityData,
  makeAnswer,
  setIsValidAnswer,
}) {
  const [properAnswer, setProperAnswer] = useState({});
  const [possibleAnswers, setPossibleAnswers] = useState([]);

  const [currentAnswer, setCurrentAnswer] = useState();
  const [answerResult, setAnswerResult] = useState();

  const [isTranscriptionVisible, setIsTranscriptionVisible] = useState(false);

  useEffect(() => {
    const correct = {
      id: activityData.words.word.id,
      word: activityData.words.word.wordFrom.expression.text,
      wordTo: activityData.words.word.wordTo.expression.text,
    };
    setProperAnswer(correct);

    const answers = [
      correct,
      ...activityData.words.words.map((word) => {
        return {
          id: word.id,
          word: word.wordFrom.expression.text,
        };
      }),
    ];
    setPossibleAnswers(answers.sort((a, b) => 0.5 - Math.random()));
  }, []);

  useEffect(() => {
    if (activityData?.words?.word?.wordTo?.soundExpression) {
      playSound(activityData?.words?.word?.wordTo?.soundExpression?.base64);
    }
  }, []);

  const checkAnswer = () => {
    if (answerResult) return;

    const isCorrect = currentAnswer === properAnswer.id;
    setIsValidAnswer(isCorrect);
    setAnswerResult({ isCorrect });
  };

  return (
    <div className="expression-listening-activity">
      <div className="expression-listening-activity__main-content">
        <div className="expression-listening-activity__speaker-wrapper">
          <div
            className="expression-listening-activity__speaker-box"
            style={{ borderColor: getLevelColor(activityData.level) }}
          >
            <button
              className="expression-listening-activity__speaker-button"
              onClick={() =>
                playSound(activityData.words.word.wordTo.soundExpression.base64)
              }
              style={{ borderColor: getLevelColor(activityData.level) }}
            >
              <FontAwesomeIcon icon={faVolumeHigh} />
            </button>
            <button
              className="expression-listening-activity__speaker-button"
              onClick={() =>
                playSound(
                  activityData.words.word.wordTo.soundExpression.base64,
                  null,
                  0.7
                )
              }
              style={{ borderColor: getLevelColor(activityData.level) }}
            >
                         <SlowIcon scale={1.4}/>

            </button>
          </div>
        </div>
        <div className="expression-listening-activity__expressions-wrapper">
          {possibleAnswers.map((answer) => (
            <div
              className="expression-listening-activity__expression"
              onClick={() => setCurrentAnswer(answer.id)}
              key={answer.id}
              style={{
                borderColor: getLevelColor(activityData.level),
                backgroundColor:
                  currentAnswer == answer.id
                    ? getLevelColor(activityData.level)
                    : "transparent",
              }}
            >
              <p className="expression-listening-activity__expression-text">
                {answer.word}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="expression-listening-activity__transcription-wrapper">
        <span
          className="expression-listening-activity__transcription-trigger"
          onClick={() => setIsTranscriptionVisible((prev) => !prev)}
        >
          {Store.getText("transcriptionTrigger")}
        </span>
        <span
          className={`expression-listening-activity__transcription-text ${
            isTranscriptionVisible
              ? "expression-listening-activity__transcription-text--visible"
              : ""
          }`}
        >
          {properAnswer.wordTo}
        </span>
      </div>
      {currentAnswer && !answerResult && (
        <ActivityButton onClick={checkAnswer}>
          {Store.getText("check")}
        </ActivityButton>
      )}

      {answerResult && (
        <ActivityFeedback
          isCorrect={answerResult.isCorrect}
          makeAnswer={makeAnswer}
          properAnswer={properAnswer.word}
        ></ActivityFeedback>
      )}
    </div>
  );
}

export default ExpressionListeningActivity;
