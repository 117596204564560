import { useEffect, useState } from "react";
import api from "../../../platform/api/api";
import "./TaskChatGptQuery.css";
import { getLanguageName } from "./helpers/getLanguageName";
import MainButton from "../../../platform/components/Buttons/MainButton/MainButton";

function TaskChatGptQuery({ setError }) {
  const [languagePairs, setLanguagePairs] = useState([]);
  const [languagePairId, setLanguagePairId] = useState();

  const [query, setQuery] = useState("");

  useEffect(() => {
    loadLanguagePairs();
  }, []);

  const loadLanguagePairs = async () => {
    const res1 = await api.get(`/language-pair?version=1`);
    const res2 = await api.get(`/language-pair?version=2`);
    const sorted = [...res1.data, ...res2.data].sort((a, b) =>
      a.languageFrom.language.localeCompare(b.languageFrom.language)
    );
    setLanguagePairs(sorted);
    setLanguagePairId(sorted[0].id);
  };

  useEffect(() => {
    if (languagePairId) {
      loadQuery(languagePairId);
    }
  }, [languagePairId]);

  const getDefaultQuery = () => {
    const selectedLanguagePair = languagePairs.find(
      (pair) => pair.id === languagePairId
    );

    return `Language Mentor is an ${getLanguageName(
      selectedLanguagePair.languageTo.languageShort.substring(0, 2)
    )} language expert, focusing on analyzing and providing feedback on simple ${getLanguageName(
      selectedLanguagePair.languageTo.languageShort.substring(0, 2)
    )} sentences, suitable for learners at A1 to B1 proficiency levels. It communicates exclusively in ${getLanguageName(
      selectedLanguagePair.languageFrom.languageShort.substring(0, 2)
    )}, offering a unique learning experience for those improving their ${getLanguageName(
      selectedLanguagePair.languageTo.languageShort.substring(0, 2)
    )}. The GPT strikes a balance between formality and casualness, suitable for a wide user base. It provides concise, clear feedback, tailored to the learner's proficiency level, making it accessible and informative. Language Mentor will use straightforward and encouraging language, making it ideal for beginners and intermediate ${getLanguageName(
      selectedLanguagePair.languageTo.languageShort.substring(0, 2)
    )} learners.`;
  };

  const loadQuery = async (pairId) => {
    const res = await api.get(`/open-ai/query/pair/${pairId}`);
    if (res.data) return setQuery(res.data);
    setQuery({ query: getDefaultQuery() });
  };

  const onSave = () => {
    const url = query.id ? `/open-ai/query/${query.id}` : '/open-ai/query';

    api.post(url, {query: query.query, languagePairId}).then(() => loadQuery(languagePairId))
  }

  return (
    <div className="task-chat-gpt-query">
      <div>
        <select
          value={languagePairId}
          onChange={(e) => setLanguagePairId(parseInt(e.target.value))}
        >
          {languagePairs.map((pair) => (
            <option value={pair.id}>
              {pair.languageFrom.language}-{pair.languageTo.language}
            </option>
          ))}
        </select>
      </div>
      <textarea
      className="task-chat-gpt-query__textarea"
        value={query?.query}
        onChange={(e) =>
          setQuery((prev) => ({ ...prev, query: e.target.value }))
        }
      />
      <MainButton className="task-chat-gpt-query__button" onClick={onSave}>Zapisz</MainButton>
    </div>
  );
}

export default TaskChatGptQuery;
