import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Store from "../../../../Store";
import "./LessonNavbarDesktop.css";
import { isGrammarAvailable } from "../../../components/Modals/GrammarModal/helpers/isGrammarAvailable";
import { isAlphabetAvailable } from "../../../components/Modals/AlphabetModal/helpers/isAlphabetAvailable";

function LessonNavbarDesktop(props) {
  const params = useParams();

  return (
    <div className="lessons-page__level-bar-wrapper-desktop">
      <Link
        className={`lessons-page__level-bar-desktop lessons-page__level-bar-desktop--first ${
          params.level === "1" ? "lessons-page__level-bar-desktop--active" : ""
        }`}
        to={`/platform/lessons/${params.pairId}/1`}
      >
        <p className="lesssons-page__level-number-desktop">1</p>
        <h1 className="lessons-page__level-header-desktop">
          {Store.getText("level")} 1 - {Store.getText("levelOneText")}
        </h1>
      </Link>
      <Link
        className={`lessons-page__level-bar-desktop lessons-page__level-bar-desktop--second ${
          params.level === "2" ? "lessons-page__level-bar-desktop--active" : ""
        }`}
        to={`/platform/lessons/${params.pairId}/2`}
      >
        <p className="lesssons-page__level-number-desktop">2</p>

        <h1 className="lessons-page__level-header-desktop">
          {Store.getText("level")} 2 - {Store.getText("levelTwoText")}
        </h1>
      </Link>
      <Link
        className={`lessons-page__level-bar-desktop lessons-page__level-bar-desktop--third ${
          params.level === "3" ? "lessons-page__level-bar-desktop--active" : ""
        }`}
        to={`/platform/lessons/${params.pairId}/3`}
      >
        <p className="lesssons-page__level-number-desktop">3</p>

        <h1 className="lessons-page__level-header-desktop">
          {Store.getText("level")} 3 - {Store.getText("levelThreeText")}
        </h1>
      </Link>

      <div className="lessons-page__grammar-buttons-wrapper-desktop">
        {props.langPair &&
          isGrammarAvailable(props.langPair.replace(/[0-9]/g, "")) && (
            <button
              className="lessons-page__grammar-button-desktop"
              onClick={() => props.setIsGrammarModalOpen(true)}
            >
              G
            </button>
          )}
        {props.langPair &&
           isAlphabetAvailable(props.langPair.replace(/[0-9]/g, "")) && (
            <button
              className="lessons-page__grammar-button-desktop"
              onClick={() => props.setIsAlphabetModalOpen(true)}
            >
              Aa
            </button>
          )}
      </div>
    </div>
  );
}

export default LessonNavbarDesktop;
