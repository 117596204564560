import React, { useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";

import blueCloud from "../../../images/boxes/dymek_granat.png";
import { Cloud } from "../hero/Hero";
import Counter from "./Counter";

const Wrapper = styled.section`
  height: fit-content;
  display: grid;
  margin-bottom: 100px;

  @media (max-width: 600px) {
    margin-bottom: 30px;
    
  }
`;

const FLex = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 150px;
  flex-wrap: wrap;


  @media (max-width: 600px) {
    gap: 100px;
    
  }
`;

const Tittle = styled.h1`
  font-weight: lighter;
  font-size: 30px;
  max-width: 200px;
  margin: 0px;
  padding: 10px 0 10px 30px;
  text-align: center;
  color: white;
`;

const Numbers = ({ data }) => {
  return (
    <Wrapper >
      <div style={{ width: "200px", margin: "30px" ,scrollMarginTop: "60px",}} id="numbers" name="numbers">
        <Cloud img={blueCloud}>
          <Tittle>{data.title}</Tittle>
        </Cloud>
      </div>
      <FLex>
        {data.count.map(({ number, description }) => {
          return <Counter number={number} description={description} />;
        })}
      </FLex>
    </Wrapper>
  );
};

export default Numbers;
