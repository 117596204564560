import React, { useContext, useEffect, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router";
import { Colors } from "../../product/colors/Colors";
import Store from "../../Store";
import styled from "styled-components";

const Dropdown = styled.div`
  float: left;
  overflow: hidden;
  font-size: 16px;
  margin: 0;

  &:hover .dropdown-content {
    display: block;
  }
`;

const Content = styled.div`
  display: none;
  position: absolute;
  right: 20px;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  z-index: 1;
`;

const Element = styled.div`
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;

  &:hover {
    background-color: #ddd;
    border-radius: 15px;
    cursor: pointer;
  }
`;

export const Hamburger = ({ data }) => {
  const lang = Store.getUserLangShort();
  const navigate = useNavigate();

  const options = [
    {
      value: `/${lang}/course`,
      label: (
        <p
          className="Dropdown-items navAnimation"
          style={{
            color: Colors.mainColor,
            fontWeight: "600",
            width: "fitContent",
          }}
        >
          {data[0].title}
        </p>
      ),
    },
    {
      value: `/${lang}`,
      label: (
        <p
          className="Dropdown-items navAnimation"
          style={{
            color: Colors.mainColor,
            margin: "-5px",
            padding: "5px",
            borderRadius: "10px",
            fontWeight: "600",
            width: "fitContent",
          }}
        >
          {data[1].title}
        </p>
      ),
    },
    {
      value: `/${lang}/product`,
      label: (
        <p
          className="Dropdown-items navAnimation"
          style={{
            color: Colors.mainColor,
            fontWeight: "600",
            width: "fitContent",
          }}
        >
          {data[2].title}
        </p>
      ),
    },
    {
      value: `/${lang}/about`,
      label: (
        <p
          className="Dropdown-items navAnimation"
          style={{
            color: Colors.mainColor,
            fontWeight: "600",
            width: "fitContent",
          }}
        >
          {data[3].title}
        </p>
      ),
    },
  ];

  return (
    <Dropdown>
      <FontAwesomeIcon
        style={{ fontSize: "32px", color: Colors.mainColor, cursor: "pointer" }}
        icon={faBars}
      />
      <Content className="dropdown-content">
        {options.map((e) => (
          <Element id={e.label} onClick={() => navigate(e.value)}>
            {e.label}
          </Element>
        ))}
      </Content>
    </Dropdown>
  );
};
