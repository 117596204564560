import { useEffect, useState } from "react";
import api from "../../../../platform/api/api";
import { getIsCertificateEnabled } from "../../../../platform/components/Modals/CertificateModal/helpers/getIsCertificateEnabled/getIsCertificateEnabled";
import MainModal from "../../../../platform/components/Modals/MainModal/MainModal";

export const SendCertificateModal = ({
  userId,
  users,
  setIsModalOpen,
  isModalOpen,
  afterUpdate,
}) => {
  const [name, setName] = useState("");
  const [languagePairs, setLanguagePairs] = useState([]);
  const [languagePairId, setLanguagePairId] = useState();

  useEffect(() => {
    Promise.all([api.get(`/language-pair?version=1`)]).then(([resV1]) => {
      const sorted = [...resV1.data.sort((a, b) => a.id - b.id)]
        .filter((pair) =>
          getIsCertificateEnabled(pair.languageFrom.languageShort)
        )
        .sort((a, b) =>
          a.languageFrom.language.localeCompare(b.languageFrom.language)
        );
      setLanguagePairs(sorted);
      setLanguagePairId(sorted[0]?.id);
    });
  }, []);

  const onSendCert = async (e) => {
    e.preventDefault();

    console.log({
      name,
      languagePairId,
      userId,
    });
    await api.post(`/certificate/create-by-admin`, {
      name,
      languagePairId,
      userId,
    });
    setIsModalOpen(false);
    setName("");
    await afterUpdate();
  };

  const onDownloadCert = async (e) => {
    e.preventDefault();

    const response = await api.post(`/certificate/download-by-admin`, {
      name,
      languagePairId,
      userId,
    });
    setName("");
    window.open(response.data, "_blank")
  };


  return (
    <MainModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen}>
        <div style={{display: 'flex', flexDirection: 'column', gridGap: '10px'}}>
      {userId && <p>{users.find((user) => user.id === userId).email}</p>}
      <input
        placeholder="imię i nazwisko"
        value={name}
        onChange={(e) => setName(e.target.value)}
      ></input>
      <select
        value={languagePairId}
        onChange={(e) => setLanguagePairId(parseInt(e.target.value))}
      >
        {languagePairs.map((pair) => (
          <option value={pair.id}>
            {pair.languageFrom.language}-{pair.languageTo.language}
          </option>
        ))}
      </select>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <button
        type="button"
        className="user-table-button-std"
        disabled={!name}
        onClick={onSendCert}
      >
        Wyślij
      </button>

      <button type="button" className="user-table-button-red" disabled={!name} onClick={onDownloadCert}>
        Pobierz
      </button>
      </div>
      </div>
    </MainModal>
  );
};
