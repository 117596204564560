const LANGUAGE_NAMES = {
    sk: 'Slovak',
    cs: 'Czech',
    hu: 'Hungarian',
    hr: 'Croatian',
    lt: 'Lithuanian',
    ro: 'Romanian',
    de: 'German',
    en: 'English',
    es: 'Spanish',
    fr: 'French',
    it: 'Italian',
    nl: 'Dutch',
    pl: 'Polish',
    pt: 'Portuguese',
    ru: 'Russian',
}

export const getLanguageName = (short) => {
    return LANGUAGE_NAMES[short]
}