import './Sidebar.css';
import logo from '../../../common/assets/logo.png';
import YourActivityModal from '../Modals/YourActivityModal/YourActivityModal';
import { useEffect, useState } from 'react';
import ChangePasswordModal from '../Modals/ChangePasswordModal/ChangePasswordModal';
import { useNavigate, useParams } from 'react-router';
import Store from '../../../Store';
import api from '../../api/api';
import { Link, useSearchParams } from 'react-router-dom';
import getLanguageFlag from '../../common/functions/getLanguageFlag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import ConfirmationModal from '../Modals/ConfirmationModal/ConfirmationModal';
import TutorialModal from '../Modals/TutorialModal/TutorialModal';
import InboxItem from './InboxItem';
import CertificateModal from '../Modals/CertificateModal/CertificateModal';
import { getIsCertificateEnabled } from '../Modals/CertificateModal/helpers/getIsCertificateEnabled/getIsCertificateEnabled';

function Sidebar(props) {
    const [searchParams, setSearchParams] = useSearchParams();

    const [isYourActivityModalOpen, setIsYourActivityModalOpen] =
        useState(false);
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
        useState(false);
    const [isCertificateModalOpen, setIsCertificateModalOpen] = useState(false);
    const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);
    const [isAddKeyModalOpen, setIsAddKeyModalOpen] = useState(false);

    const [pairShort, setPairShort] = useState();
    const [pairToShort, setPairToShort] = useState();
    const [isSidebarOpen, setIsSidebarOpen] = useState(
        !props.isSidebarExpandable
    );
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

    const params = useParams();
    const navigate = useNavigate();

    const load = async () => {
        const pairId = props.pairId || params.pairId;
        if (pairId) {
            const resPairShort = await api.get(
                `/language-pair/${pairId}/pair-short`
            );
            setPairShort(resPairShort.data);
        }
    };

    function handleLogOut() {
        Store.removeToken();
        navigate('/'); // whichever component you want it to route to
    }

    useEffect(() => {
        if (searchParams.get('afterRegister')) {
            setIsTutorialModalOpen(true);
        }
    }, []);

    useEffect(() => {
        load();
    }, [props.pairId, params.pairId]);

    useEffect(() => {
        if (pairShort) setPairToShort(pairShort.split('-')[1]);
    }, [pairShort]);

    const isCertificateEnabled = getIsCertificateEnabled();

    return (
        <>
            <YourActivityModal
                isModalOpen={isYourActivityModalOpen}
                setIsModalOpen={setIsYourActivityModalOpen}
                pairId={params.pairId || props.pairId}
            ></YourActivityModal>
            <ChangePasswordModal
                isModalOpen={isChangePasswordModalOpen}
                setIsModalOpen={setIsChangePasswordModalOpen}
                setError={props.setError}
            ></ChangePasswordModal>
            <ConfirmationModal
                isModalOpen={isLogoutModalOpen}
                setIsModalOpen={setIsLogoutModalOpen}
                content={Store.getText('logoutMessage')}
                buttonText={Store.getText('logoutButtonText')}
                onConfirm={handleLogOut}
            ></ConfirmationModal>
            {/* <TutorialModal
                isModalOpen={isTutorialModalOpen}
                setIsModalOpen={setIsTutorialModalOpen}
            ></TutorialModal> */}
            {isCertificateModalOpen && (
                <CertificateModal
                    isModalOpen={isCertificateModalOpen}
                    setIsModalOpen={setIsCertificateModalOpen}
                    pairId={params.pairId || props.pairId}
                />
            )}

            <nav
                className={
                    'sidebar ' +
                    props.className +
                    (isSidebarOpen ? ' sidebar--open' : '') +
                    (props.darkBg ? ' sidebar--darkBg' : '')
                }
            >
                <div className="sidebar__menu-wrapper">
                    <div className="sidebar__user-div">
                        <p className="sidebar__user-name">
                            {Store.getUsername()}
                        </p>
                        {pairToShort && (
                            <img
                                className="sidebar__course-flag-img"
                                src={getLanguageFlag(pairToShort)}
                            />
                        )}
                    </div>
                    <ul className="sidebar__menu-list">
                        <li
                            className="sidebar__menu-list-element"
                            onClick={() => setIsYourActivityModalOpen(true)}
                        >
                            {Store.getText('yourActivity')}
                        </li>
                        {/* <li
                            className="sidebar__menu-list-element"
                            onClick={() => setIsTutorialModalOpen(true)}
                        >
                            {Store.getText('tutorial')}
                        </li> */}

                        <li
                            className="sidebar__menu-list-element"
                            onClick={() => setIsChangePasswordModalOpen(true)}
                        >
                            {Store.getText('changePassword')}
                        </li>
                        {Store.isAdmin() && (
                            <Link to="/platform/admin">
                                <li className="sidebar__menu-list-element">
                                    Admin
                                </li>
                            </Link>
                        )}

                        <Link to="/platform/mail-box">
                            <li className="sidebar__menu-list-element">
                                {Store.getText('inbox')}
                            </li>
                        </Link>
                        <li className="sidebar__menu-list-element sidebar__menu-list-element--help">
                            {Store.getText('help')}

                            <div className="sidebar__info-div">
                                <p className="sidebar__info-text">
                                    {Store.getText('contactText')}
                                    <span className="sidebar__span-block">
                                        {Store.getText('contactEmail')}
                                    </span>
                                </p>
                            </div>
                        </li>
                        <li
                            className="sidebar__menu-list-element sidebar__menu-list-element--help"
                            onClick={() =>
                                isCertificateEnabled &&
                                setIsCertificateModalOpen(true)
                            }
                        >
                            {Store.getText('certButtonText')}
                            {!isCertificateEnabled && (
                                <div className="sidebar__info-div">
                                    <p className="sidebar__info-text">
                                        {Store.getText('certText')}
                                        <span className="sidebar__span-block">
                                            {Store.getText('certEmail')}
                                        </span>
                                    </p>
                                </div>
                            )}
                        </li>
                        <li
                            className="sidebar__menu-list-element"
                            onClick={() => {
                                setIsLogoutModalOpen(true);
                            }}
                        >
                            {Store.getText('logOut')}
                        </li>
                    </ul>
                </div>
                <div className="sidebar__logo-wrapper">
                    <img src={logo} className="sidebar__logo" />
                </div>
                <div
                    className="sidebar__opening-div"
                    onClick={() => {
                        setIsSidebarOpen(!isSidebarOpen);
                    }}
                >
                    <FontAwesomeIcon icon={faBars} />
                </div>
            </nav>
        </>
    );
}

export default Sidebar;
