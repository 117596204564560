import React, { useContext, useEffect, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router";
import { Colors } from "../../product/colors/Colors";
import Store from "../../Store";
import Dropdown from "react-dropdown";

export const Hamburger = ({ data }) => {
  const lang = Store.getUserLangShort();
  const navigate = useNavigate();
  const dropdown = useRef();

  function handleSelect({ value }) {
    if (value.length > 2) {
      navigate(`/${lang}${value.toLowerCase()}`);
    } else navigate(`/${lang}`);
    dropdown.props.placeholder = (
      <FontAwesomeIcon
        style={{ fontSize: "32px", color: "white" }}
        icon={faBars}
      />
    );
  }

  const options = [
    {
      value: data[0].path,
      label: (
        <p
          className="Dropdown-items navAnimation"
          style={{
            color: Colors.mainColor,
            fontWeight: "600",
            width: "fitContent",
          }}
        >
          {data[0].title}
        </p>
      ),
    },
    {
      value: data[1].path,
      label: (
        <p
          className="Dropdown-items navAnimation"
          style={{
            color: Colors.mainColor,
            fontWeight: "600",
            width: "fitContent",
          }}
        >
          {data[1].title}
        </p>
      ),
    },
    {
      value: data[2].path,
      label: (
        <p
          className="Dropdown-items navAnimation"
          style={{
            color: Colors.mainColor,
            fontWeight: "600",
            width: "fitContent",
          }}
        >
          {data[2].title}
        </p>
      ),
    },
    {
      value: data[3].path,
      label: (
        <p
          className="Dropdown-items navAnimation"
          style={{
            color: Colors.mainColor,
            fontWeight: "600",
            width: "fitContent",
          }}
        >
          {data[3].title}
        </p>
      ),
    },
  ];

  return (
    <div className="Hambuerger">
      <Dropdown
        ref={dropdown}
        options={options}
        onChange={handleSelect}
        placeholder={
          <FontAwesomeIcon
            style={{ fontSize: "32px", color: '#000' }}
            icon={faBars}
          />
        }
      ></Dropdown>
    </div>
  );
};
