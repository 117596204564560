export const lessonDictionary = {
    pl: {
        level1: {
            1: 'Powitania i Podstawy',
            2: 'Liczby',
            3: 'Kolory',
            4: 'Miesiące i Pory roku',
            5: 'Ubrania',
            6: 'Jedzenie 1',
            7: 'Jedzenie 2',
            8: 'Jedzenie 3',
            9: 'Dni tygodnia',
            10: 'Kierunki',
            11: 'Przymiotniki 1',
            12: 'Przymiotniki 2',
            13: 'Przymiotniki 3',
            14: 'Osoby',
            15: 'Określenia czasu',
            16: 'Czasowniki 1',
            17: 'Czasowniki 2',
            18: 'Czasowniki 3',
            19: 'Życie codzienne 1',
            20: 'Życie codzienne 2',
            21: 'Narodowości 1',
            22: 'Narodowości 2',
            23: 'Narodowości 3',
            24: 'Podróże 1',
            25: 'Podróże 2',
            26: 'Biuro/Praca 1',
            27: 'Biuro/Praca 2',
            4: 'Zawody',
            29: 'Restauracja',
            30: 'Czasowniki 4',
            31: 'Czasowniki 5',
            32: 'Czasowniki 6',
            33: 'Zwierzęta',
            34: 'Części ciała i zmysły 1',
            35: 'Części ciała i zmysły 2',
            36: 'Dodatkowa lekcja 1',
            37: 'Dodatkowa lekcja 2',
            38: 'Dodatkowa lekcja 3',
        },
        level2: {
            22: 'Bank',
            24: 'Biuro',
            31: 'Biznes',
            3: 'Cechy ogólne',
            33: 'Cechy charakteru',
            36: 'Cechy fizyczne',
            34: 'Cechy negatywne',
            10: 'Cechy pozytywne',
            29: 'Ciało i zdrowie człowieka',
            9: 'Czas',
            19: 'Czasowniki',
            25: 'Dla żądnych wiedzy',
            11: 'Dom rodzinny',
            30: 'Droga',
            2: 'Emocje i stany',
            28: 'Komputer',
            32: 'Liczby',
            21: 'Miasto',
            6: 'Między nami',
            15: 'Na słodko i słono',
            17: 'Natura',
            18: 'Państwo',
            26: 'Pogoda',
            12: 'Poważna rozmowa',
            14: 'Powieść kryminalna',
            7: 'Praca',
            20: 'Rozmowa kwalifikacyjna',
            23: 'Rzeczy i narzędzia',
            38: 'Słowny mix',
            5: 'Sport',
            16: 'Swobodna rozmowa',
            1: 'Trudności',
            13: 'Ubranie',
            37: 'Umiejętności',
            8: 'Wakacje',
            39: 'Warto znać',
            35: 'Wiara',
            27: 'Życie',

            4: 'Zawód',
        },
        level3: {
            2: 'Biurokracja',
            8: 'Biznes',
            6: 'Cechy negatywne',
            25: 'Cechy neutralne',
            1: 'Cechy pozytywne',
            15: 'Czas',
            23: 'Czasowniki',
            28: 'Dla ambitnych',
            20: 'Duża firma',
            4: 'Elokwencja',
            10: 'Kościół',
            29: 'Mix słów',
            5: 'Miły kolega',
            18: 'Niemiły sąsiad',
            21: 'Praca',
            8: 'Prawo',
            30: 'Przydatne słówka',
            22: 'Przymiotniki',
            19: 'Serdeczna znajoma',
            27: 'Spotkanie w pracy',
            28: 'Słówka dla chętnych',
            16: 'Tacy jesteśmy',
            3: 'Trudne rozmowy',
            14: 'W domu',
            12: 'Wakacje',
            7: 'Więzienie',
            11: 'Zdrowie',
            13: 'Znudzony pracownik',
            24: 'Złośliwa sąsiadka',
            26: 'Życie codzienne',
        },
        default: 'Lekcja',
    },
    cs: {
        level1: {
            1: 'Pozdravy a základy',
            2: 'Čísla',
            3: 'Barvy',
            4: 'Měsíce a roční období',
            5: 'Oblečení',
            6: 'Potraviny 1',
            7: 'Potraviny 2',
            8: 'Potraviny 3',
            9: 'Dny týdne',
            10: 'Instrukce',
            11: 'Přídavná 1',
            12: 'Přídavná 2',
            13: 'Přídavná 3',
            14: 'Osoby',
            15: 'Stanovení času',
            16: 'Slovesa 1',
            17: 'Slovesa 2',
            18: 'Slovesa 3',
            19: 'Každodenní život 1',
            20: 'Každodenní život 2',
            21: 'Národnosti 1',
            22: 'Národnosti 2',
            23: 'Národnosti 3',
            24: 'Cestování 1',
            25: 'Cestování 2',
            26: 'Kancelář/práce 1',
            27: 'Kancelář/práce 2',
            28: 'Profese',
            29: 'Restaurace',
            30: 'Slovesa 4',
            31: 'Slovesa 5',
            32: 'Slovesa 6',
            33: 'Zvířata',
            34: 'Části těla a smysly 1',
            35: 'Části těla a smysly 2',
            36: 'Doplňující lekce 1',
            37: 'Doplňující lekce 2',
            38: 'Doplňující lekce 3',
        },
        level2: {
            22: 'Banka',
            24: 'Kancelář',
            31: 'Obchodní',
            3: 'Obecné vlastnosti',
            33: 'Charakteristika',
            36: 'Fyzické vlastnosti',
            34: 'Negativní vlastnosti',
            10: 'Pozitivní vlastnosti',
            29: 'Lidské tělo a zdraví',
            9: 'Čas',
            19: 'Slovesa',
            25: 'Pro ty, kteří touží po vědomostech',
            11: 'Rodinný dům',
            30: 'Způsob',
            2: 'Emoce a stavy',
            28: 'Počítač',
            32: 'Čísla',
            21: 'Město',
            6: 'Mezi námi',
            15: 'Sladké a slané',
            17: 'Příroda',
            18: 'Země',
            26: 'Počasí',
            12: 'Vážná konverzace',
            14: 'Kriminální román',
            7: 'Práce',
            20: 'Pracovní pohovor',
            23: 'Věci a nástroje',
            38: 'Směs slov',
            5: 'Sport',
            16: 'Příležitostná konverzace',
            1: 'Obtíže',
            13: 'Oblečení',
            37: 'Dovednosti',
            8: 'Dovolená',
            39: 'Stojí za to vědět',
            35: 'Faith',
            27: 'Život',

            4: 'Povoláním',
        },
        level3: {
            2: 'Byrokracie',
            9: 'Obchodní',
            6: 'Negativní vlastnosti',
            25: 'Neutrální vlastnosti',
            1: 'Pozitivní vlastnosti',
            15: 'Čas',
            23: 'Slovesa',
            28: 'Pro ambiciózní',
            20: 'Velká společnost',
            4: 'Eloquence',
            10: 'Kostel',
            29: 'Směs slov',
            5: 'Milý kolega',
            18: 'Nepříjemný soused',
            21: 'Práce',
            8: 'Právo',
            30: 'Užitečná slova',
            22: 'Přídavná jména',
            19: 'Přátelská známost',
            27: 'Setkání v práci',
            28: 'Slova, která se naučíte',
            16: 'Takto se chováme',
            3: 'Obtížné rozhovory',
            14: 'Doma',
            12: 'Dovolená',
            7: 'Vězení',
            11: 'Zdraví',
            13: 'Znuděný zaměstnanec',
            24: 'Zlomyslný soused',
            26: 'Každodenní život',
        },
        default: 'Lekce',
    },
    sk: {
        level1: {
            1: 'Pozdravy a základy',
            2: 'Čísla',
            3: 'Farby',
            4: 'Mesiace a ročné obdobia',
            5: 'Oblečenie',
            6: 'Jedlo 1',
            7: 'Jedlo 2',
            8: 'Jedlo 3',
            9: 'Dni v týždni',
            10: 'Pokyny',
            11: 'Prídavné mená 1',
            12: 'Prídavné mená 2',
            13: 'Prídavné mená 3',
            14: 'Osoby',
            15: 'Časové podmienky',
            16: 'Slovesá 1',
            17: 'Slovesá 2',
            18: 'Slovesá 3',
            19: 'Každodenný život 1',
            20: 'Každodenný život 2',
            21: 'Národnosti 1',
            22: 'Národnosti 2',
            23: 'Národnosti 3',
            24: 'Cestuje 1',
            25: 'Cestuje 2',
            26: 'Kancelária / Práca 1',
            27: 'Kancelária / Práca 2',
            28: 'Súťaže',
            29: 'Reštaurácia',
            30: 'Slovesá 4',
            31: 'Slovesá 5',
            32: 'Slovesá 6',
            33: 'Zvieratá',
            34: 'Časti tela a zmysly 1',
            35: 'Časti tela a zmysly 2',
            36: 'Extra lekcia 1',
            37: 'Extra lekcia 2',
            38: 'Extra lekcia 3',
        },
        level2: {
            22: 'Banka',
            24: 'Kancelária',
            31: 'Obchod',
            3: 'Všeobecné vlastnosti',
            33: 'Charakteristika',
            36: 'Fyzické vlastnosti',
            34: 'Negatívne vlastnosti',
            10: 'Pozitívne vlastnosti',
            29: 'Ľudské telo a zdravie',
            9: 'Čas',
            19: 'Slovesá',
            25: 'Pre tých, ktorí túžia po vedomostiach',
            11: 'Rodinný dom',
            30: 'Spôsob',
            2: 'Emócie a stavy',
            28: 'Počítač',
            32: 'Čísla',
            21: 'Mesto',
            6: 'Medzi nami',
            15: 'Sladké a slané',
            17: 'Príroda',
            18: 'Krajina',
            26: 'Počasie',
            12: 'Vážna konverzácia',
            14: 'Kriminálny román',
            7: 'Práca',
            20: 'Pracovný pohovor',
            23: 'Veci a nástroje',
            38: 'Zmes slov',
            5: 'Šport',
            16: 'Príležitostná konverzácia',
            1: 'Ťažkosti',
            13: 'Oblečenie',
            37: 'Zručnosti',
            8: 'Dovolenka',
            39: 'Stojí za to vedieť',
            35: 'Faith',
            27: 'Život',

            4: 'Povolaním',
        },
        level3: {
            2: 'Byrokracia',
            9: 'Obchod',
            6: 'Negatívne vlastnosti',
            25: 'Neutrálne vlastnosti',
            1: 'Pozitívne vlastnosti',
            15: 'Čas',
            23: 'Slovesá',
            28: 'Pre ambicióznych',
            20: 'Veľká spoločnosť',
            4: 'Výrečnosť',
            10: 'Kostol',
            29: 'Zmes slov',
            5: 'Milý kolega',
            18: 'Nepríjemný sused',
            21: 'Práca',
            8: 'Právo',
            30: 'Užitočné slová',
            22: 'Prídavné mená',
            19: 'Priateľská známosť',
            27: 'Stretnutie v práci',
            28: 'Slová, ktoré sa treba naučiť',
            16: 'Takto sme',
            3: 'Ťažké rozhovory',
            14: 'Doma',
            12: 'Dovolenka',
            7: 'Väznica',
            11: 'Zdravie',
            13: 'Znudený zamestnanec',
            24: 'Zlomyseľný sused',
            26: 'Každodenný život',
        },
        default: 'Lekcia',
    },
    en: {
        level1: {
            1: 'Greetings & Basics',
            2: 'Numbers',
            3: 'Colors',
            4: 'Months and Seasons',
            5: 'Clothes',
            6: 'Food 1',
            7: 'Food 2',
            8: 'Food 3',
            9: 'Days of the week',
            10: 'Days of the week',
            11: 'Adjectives 1',
            12: 'Adjectives 2',
            13: 'Adjectives 3',
            14: 'Persons',
            15: 'Time',
            16: 'Verbs 1',
            17: 'Verbs 2',
            18: 'Verbs 3',
            19: 'Daily life 1',
            20: 'Daily life 2',
            21: 'Nationalities 1',
            22: 'Nationalities 2',
            23: 'Nationalities 3',
            24: 'Travels 1',
            25: 'Travels 2',
            26: 'Office/work 1',
            27: 'Office/work 2',
            28: 'Occupations',
            29: 'Restaurant',
            30: 'Verbs 4',
            31: 'Verbs 5',
            32: 'Verbs 6',
            33: 'Animals',
            34: 'Body parts and senses 1',
            35: 'Body parts and senses 2',
            36: 'Additional lesson 1',
            37: 'Additional lesson 2',
            38: 'Additional lesson 3',
        },
        level2: {
            22: 'Bank',
            24: 'Office',
            31: 'Business',
            3: 'General features',
            33: 'Character attributes',
            36: 'Physical attributes',
            34: 'Negative attributes',
            10: 'Positive attributes',
            29: 'Human body and health',
            9: 'Time',
            19: 'Verbs',
            25: 'For those with a thirst for knowledge',
            11: 'Family home',
            30: 'Way',
            2: 'Emotions and states',
            28: 'Computer',
            32: 'Numbers',
            21: 'City',
            6: 'Between us',
            15: 'Sweet and salty',
            17: 'Nature',
            18: 'Country',
            26: 'Weather',
            12: 'Serious conversation',
            14: 'Crime novel',
            7: 'Work',
            20: 'Job interview',
            23: 'Things and tools',
            38: 'Word mix',
            5: 'Sports',
            16: 'Casual conversation',
            1: 'Difficulties',
            13: 'Clothing',
            37: 'Skills',
            8: 'Vacation',
            39: 'Worth knowing',
            35: 'Faith',
            27: 'Life',

            4: 'Profession',
        },
        level3: {
            2: 'Bureaucracy',
            9: 'Business',
            6: 'Negative features',
            25: 'Neutral features',
            1: 'Positive features',
            15: 'Time',
            23: 'Verbs',
            28: 'For ambitious',
            20: 'Large company',
            4: 'Eloquence',
            10: 'Church',
            29: 'Word mix',
            5: 'Nice colleague',
            18: 'Unpleasant neighbor',
            21: 'Work',
            8: 'Law',
            30: 'Useful words',
            22: 'Adjectives',
            19: 'Cordial acquaintance',
            27: 'Meeting at work',
            28: 'Words for the eager',
            16: 'This is how we are',
            3: 'Difficult conversations',
            14: 'At home',
            12: 'Vacation',
            7: 'Prison',
            11: 'Health',
            13: 'Bored employee',
            24: 'Malicious neighbor',
            26: 'Daily life',
        },
        default: 'Lekcia',
    },
    hu: {
        level1: {
            1: 'Üdvözlet és alapok',
            2: 'Számok',
            3: 'Színek',
            4: 'Hónapok és évszakok',
            5: 'Ruházat',
            6: 'Evés 1',
            7: 'Étel 2',
            8: 'Étel 3',
            9: 'A hét napjai',
            10: 'Útvonalak',
            11: 'Melléknevek 1',
            12: 'Melléknevek 2',
            13: 'Melléknevek 3',
            14: 'Személyek',
            15: 'Idő',
            16: 'Igék 1',
            17: 'Igék 2',
            18: 'Igék 3',
            19: 'Mindennapi élet 1',
            20: 'Mindennapi élet 2',
            21: 'Nemzetiségek 1',
            22: 'Nemzetiségek 2',
            23: 'Nemzetiségek 3',
            24: 'Utazások 1',
            25: 'Utazások 2',
            26: 'Iroda/munka 1',
            27: 'Iroda/munka 2',
            28: 'Foglalkozások',
            29: 'Étterem',
            30: 'Igék 4',
            31: 'Igék 5',
            32: 'Igék 6',
            33: 'Állatok',
            34: 'Testrészek és érzékszervek 1',
            35: 'Testrészek és érzékszervek 2',
            36: 'Kiegészítő lecke 1',
            37: 'Kiegészítő lecke 2',
            38: 'Kiegészítő lecke 3',
        },
        level2: {
            22: 'Bank',
            24: 'Iroda',
            31: 'Üzlet',
            3: 'Általános Jellemzők',
            33: 'Karakterjegyek',
            36: 'Fizikai Tulajdonságok',
            34: 'Negatív Jellemzők',
            10: 'Pozitív Jellemzők',
            29: 'Test és Egészség',
            9: 'Idő',
            19: 'Igek',
            25: 'Tudásvágyóknak',
            11: 'Családi Otthon',
            30: 'Út',
            2: 'Érzelmek és Állapotok',
            28: 'Számítógép',
            32: 'Számok',
            21: 'Város',
            6: 'Köztünk',
            15: 'Édes és Sós',
            17: 'Természet',
            18: 'Ország',
            26: 'Időjárás',
            12: 'Komoly Beszélgetés',
            14: 'Krimi Regény',
            7: 'Munka',
            20: 'Interjú',
            23: 'Tárgyak és Eszközök',
            38: 'Szókeverék',
            5: 'Sport',
            16: 'Szabad Beszélgetés',
            1: 'Nehézségek',
            13: 'Ruha',
            37: 'Képességek',
            8: 'Nyaralás',
            39: 'Fontos Tudnivalók',
            35: 'Hit',
            27: 'Élet',
            4: 'Szakma',
        },
        level3: {
            2: 'Bürokrácia',
            8: 'Üzlet',
            6: 'Negatív Jellemzők',
            25: 'Semleges Jellemzők',
            1: 'Pozitív Jellemzők',
            15: 'Idő',
            23: 'Igek',
            28: 'Ambiciózusaknak',
            20: 'Nagy Vállalat',
            4: 'Ékesszólás',
            10: 'Egyház',
            29: 'Szókeverék',
            5: 'Kedves Kolléga',
            18: 'Kellemetlen Szomszéd',
            21: 'Munka',
            8: 'Jog',
            30: 'Hasznos Kifejezések',
            22: 'Melléknevek',
            19: 'Kedves Ismerős',
            27: 'Munkahelyi Találkozó',
            28: 'Kifejezések Érdeklődőknek',
            16: 'Ilyenek Vagyunk',
            3: 'Nehéz Beszélgetések',
            14: 'Otthon',
            12: 'Vakáció',
            7: 'Börtön',
            11: 'Egészség',
            13: 'Unott Dolgozó',
            24: 'Rosszindulatú Szomszédasszony',
            26: 'Napi Élet',
        },
        default: 'Lecke',
    },
    ro: {
        level1: {
            1: 'Salutări i noiuni de bază',
            2: 'Numere',
            3: 'Culori',
            4: 'Luni i sezoane',
            5: 'Haine',
            6: 'Alimente 1',
            7: 'Alimente 2',
            8: 'Alimente 3',
            9: 'Zilele săptămânii',
            10: 'Zilele săptămânii',
            11: 'Adjective 1',
            12: 'Adjective 2',
            13: 'Adjective 3',
            14: 'Persoane',
            15: 'Timp',
            16: 'Verbe 1',
            17: 'Verbe 2',
            18: 'Verbe 3',
            19: 'Viaa de zi cu zi 1',
            20: 'Viaa de zi cu zi 2',
            21: 'Naionalităi 1',
            22: 'Naionalităi 2',
            23: 'Naionalităi 3',
            24: 'Călătorii 1',
            25: 'Călătorii 2',
            26: 'Birou/muncă 1',
            27: 'Birou/muncă 2',
            28: 'Ocupaii',
            29: 'Restaurant',
            30: 'Verbe 4',
            31: 'Verbe 5',
            32: 'Verbe 6',
            33: 'Animale',
            34: 'Pări ale corpului i simuri 1',
            35: 'Pări ale corpului i simuri 2',
            36: 'Lecie suplimentară 1',
            37: 'Lecie suplimentară 2',
            38: 'Lecie suplimentară 3',
        },
        level2: {
            1: 'Dificultăi',
            2: 'Emoii i stări',
            3: 'Caracteristici generale',
            4: 'Profesie',
            5: 'Sporturi',
            6: 'Între noi',
            7: 'Muncă',
            8: 'Vacană',
            9: 'Timp',
            10: 'Atribute pozitive',
            11: 'Casa familiei',
            12: 'Conversaie serioasă',
            13: 'Îmbrăcăminte',
            14: 'Roman poliist',
            15: 'Dulce i sărat',
            16: 'Conversaie ocazională',
            17: 'Natură',
            18: 'Țară',
            19: 'Verbe',
            20: 'Interviu de angajare',
            21: 'Oraș',
            22: 'Bancă',
            23: 'Lucruri i scule',
            24: 'Birou',
            25: 'Pentru cei cu sete de cunoatere',
            26: 'Vreme',
            27: 'Viaă',
            28: 'Calculator',
            29: 'Corpul uman i sănătatea',
            30: 'Cale',
            31: 'Afaceri',
            32: 'Numere',
            33: 'Atribute de caracter',
            34: 'Atribute negative',
            35: 'Credină',
            36: 'Atribute fizice',
            37: 'Competene',
            38: 'Amestec de cuvinte',
            39: 'Merită tiut',
        },
        level3: {
            1: 'Caracteristici pozitive',
            2: 'Birocraie',
            3: 'Conversaii dificile',
            4: 'Elocvenă',
            5: 'Un coleg drăgu',
            6: 'Caracteristici negative',
            7: 'Închisoare',
            8: 'Lege',
            9: 'Afaceri',
            10: 'Biserică',
            11: 'Sănătate',
            12: 'Vacană',
            13: 'Angajat plictisit',
            14: 'Acasă',
            15: 'Timp',
            16: 'Aa suntem noi',
            18: 'Vecin neplăcut',
            19: 'Cunotină cordială',
            20: 'Companie mare',
            21: 'Muncă',
            22: 'Adjective',
            23: 'Verbe',
            24: 'Vecin rău intenionat',
            25: 'Caracteristici neutre',
            26: 'Viaa de zi cu zi',
            27: 'Întâlnire la locul de muncă',
            28: 'Cuvinte pentru cei dornici',
            29: 'Amestec de cuvinte',
            30: 'Cuvinte utile',
        },
        default: 'Lecţie',
    },
};
