import { faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import Store from "../../../../Store";
import getLevelColor from "../../../common/functions/getLevelColor";
import playSound from "../../../common/functions/playSound";
import ActivityButton from "../../Buttons/ActivityButton/ActivityButton";
import RateWordPair from "../../RateWordPair/RateWordPair";
import "./ExpressionLearningActivity.css";
import SlowIcon from "../../../common/assets/SlowIcon";

function ExpressionLearningActivity({ activityData, makeAnswer }) {
  useEffect(() => {
    if (activityData.words.word.wordTo.soundExpression) {
      playSound(activityData.words.word.wordTo?.soundExpression?.base64, () =>
        playSound(activityData.words.word.soundSentenceTo?.base64)
      );
    }
  }, []);
  return (
    <div className="expression-learning-activity">
      <div className="expression-learning-activity__expression-wrapper">
        <div className="expression-learning-activity__expression-div">
          <button
            className="expression-learning-activity__expression-speaker-button"
            onClick={() =>
              playSound(activityData.words.word.wordTo.soundExpression.base64)
            }
          >
            <FontAwesomeIcon
              icon={faVolumeHigh}
              className="expression-learning-activity__expression-speaker-icon"
            />
          </button>
          <button
            className="expression-learning-activity__expression-speaker-button"
            onClick={() =>
              playSound(
                activityData.words.word.wordTo.soundExpression.base64,
                null,
                0.7
              )
            }
          >
            <SlowIcon scale={1.3} />
          </button>
          <div className="expression-learning-activity__expression">
            <p className="expression-learning-activity__expression-text">
              {activityData.words.word.wordTo.expression.text}
            </p>

            <p className="expression-learning-activity__expression-translation">
              {activityData.words.word.wordFrom.expression.text}
            </p>
          </div>
        </div>
        <div
          className="expression-learning-activity__image-wrapper"
          style={{
            backgroundImage: `url(${activityData?.words?.word?.wordLink?.image?.base64})`,
          }}
        ></div>
      </div>
      <div className="expression-learning-activity__sentence-wrapper">
        <p className="expression-learning-activity__sentence-desc">
          {Store.getText("exampleSentence")}
        </p>
        <div
          className="expression-learning-activity__sentence-div"
          style={{ borderColor: getLevelColor(activityData.level) }}
        >
          <div className="expression-learning-activity__sentence">
            <button
              className="expression-learning-activity__sentence-speaker-button"
              onClick={() =>
                playSound(activityData.words.word.soundSentenceTo.base64)
              }
            >
              <FontAwesomeIcon
                icon={faVolumeHigh}
                className="expression-learning-activity__sentence-speaker-icon"
              />
            </button>
            <button
              className="expression-learning-activity__sentence-speaker-button"
              onClick={() =>
                playSound(
                  activityData.words.word.soundSentenceTo.base64,
                  null,
                  0.7
                )
              }
            >
              <SlowIcon scale={0.9} />
            </button>
            <p className="expression-learning-activity__sentence-text">
              {activityData.words.word.sentenceTo}
            </p>
          </div>
          <p className="expression-learning-activity__sentence-translation">
            {activityData.words.word.sentenceFrom}
          </p>
        </div>
      </div>
      <ActivityButton onClick={() => makeAnswer(true)}>
        {Store.getText("continueTasks")}
      </ActivityButton>
      <RateWordPair
        existingRate={activityData.words.word?.rates[0]}
        wordPairId={activityData.words.word.id}
      />
    </div>
  );
}

export default ExpressionLearningActivity;
