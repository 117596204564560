import React from "react";
import Hero from "../components/aboutPage/hero/Hero";
import Why from "../components/aboutPage/why/Why";
import Mission from "../components/aboutPage/Mission/Mission";

const AboutPage = ({ data }) => {
  return (
    <>
      <Hero data={data.hero} />
      <Why data={data.why} />
      <Mission data={data.mission} />
    </>
  );
};

export default AboutPage;
