import {
    useLayoutEffect,
    useState,
} from 'react';

const usePropState = (propValue) => {
    const [value, setValue] = useState(propValue);
    useLayoutEffect(() => setValue(propValue), [propValue]);
    return [value, setValue];
};

export default usePropState;
