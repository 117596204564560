import React, { createContext, Dispatch, useState } from "react";
import { useLocation } from "react-router-dom";
import Store from "../../Store";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const location = useLocation().pathname;
  let langFromUrl = location.split("/")[1];

  if (langFromUrl === "platform") {
    langFromUrl = null;
  }

  const [lang, setLang] = useState(langFromUrl);

  return (
    <LanguageContext.Provider
      value={{
        lang,
        setLang,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
